import React from 'react';
import styled from 'styled-components';
import Uploader from '../standalones/ccBox/Uploader';
import { ReactComponent as UploadInfo } from '../../assets/icons/csv-uploader.svg';
import { ReactComponent as CheckedUploadInfo } from '../../assets/icons/checked-csv-uploader.svg';
import { ReactComponent as UploadTemp } from '../../assets/icons/image-uploader.svg';

const headerHeight = 70;
const rootMargin = 40;

const BoxPage: React.FC = () => {
  return (
    <StyledUploadPage>
      <StyledUploaderWrapper>
        <Uploader
          accept=".csv"
          title="Upload Individual Information"
          description="(CSV)"
          id="file_input1"
          apiPath="/upload/csv"
          defaultIcon={UploadInfo}
          checkedIcon={CheckedUploadInfo}
          successMsg="Individual information has been uploaded"
          failureMsg="Failed to upload individual information"
        />

        <Uploader
          accept="image/jpeg,image/png"
          title="Upload your certificate design"
          description="(JPEG, PNG)"
          id="file_input2"
          apiPath="/upload/image"
          defaultIcon={UploadTemp}
          checkedIcon={CheckedUploadInfo}
          successMsg="The template has been uploaded"
          failureMsg="Failed to upload the template"
        />

        <Uploader
          accept="application/zip"
          title="form.label.upload_recipient_images"
          description="(ZIP)"
          id="file_input3"
          apiPath="/upload/recipient-images"
          defaultIcon={UploadTemp}
          checkedIcon={CheckedUploadInfo}
          successMsg="Recipient images upload success"
          failureMsg="Failed to upload recipient images"
        />
      </StyledUploaderWrapper>
    </StyledUploadPage>
  );
};

export default BoxPage;

const StyledUploadPage = styled.div`
  min-height: calc(100vh - ${headerHeight}px - ${rootMargin}px * 2);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${rootMargin}px 0;
`;

const StyledUploaderWrapper = styled.div`
  background-color: #f6faf9;
  width: fit-content;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: #97b7de;
  text-align: center;
`;

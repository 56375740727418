import React, { FC } from 'react';

import { ReactComponent as SendIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as PreviewIcon } from '../../../assets/icons/eye.svg';
import { Label } from '../../atoms/label/Label';
import { IconButton } from '../../atoms/button/IconButton';
import { useQueryContext } from '../../../context/QueryContext';
import { useTranslation } from 'react-i18next';
import { formatDateFromSeconds } from '../../../utils/formatDate';
import { useModalContext } from '../../../context/ModalContext';
import { Td, Tr } from '../../atoms/table';
import { TextWithDots } from '../../atoms/utils';
import { Certificate } from '../../../generated/types';

type PropsType = {
  cert: Certificate;
  issuerId: string;
};

const TableRow: FC<PropsType> = (props: PropsType) => {
  const {
    id,
    holderName,
    consoleID,
    holderMailAddress,
    labels,
    issueAt,
    deliveryAt,
    expireAt,
  } = props.cert;
  const { toggleSingleMailPreview } = useModalContext();
  const { setSendEmailInfo } = useQueryContext();
  const [t] = useTranslation();

  const formattedIssueAt = formatDateFromSeconds(issueAt, 'yyyy/MM/dd');
  const formattedDeliveryAt = formatDateFromSeconds(deliveryAt, 'yyyy/MM/dd');
  const formattedExpireAt = formatDateFromSeconds(expireAt, 'yyyy/MM/dd');

  const emailSenderHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    toggleSingleMailPreview(true);
    setSendEmailInfo({
      issuerId: props.issuerId,
      certIds: [id],
    });
  };

  return (
    <Tr height="36px">
      {/* <StyledTdCenter>
        <RoundCheckBox
          onChange={(e) => updateSelectedCerts(e, certId)}
          checked={selectedCerts.includes(certId)}
        />
      </StyledTdCenter> */}
      <Td position="center">
        <IconButton
          height="32px"
          width="32px"
          shadow="NONE"
          border="1px solid #CCCCCC83"
          onClick={emailSenderHandler}
        >
          <SendIcon height="20px" width="24px" />
        </IconButton>
      </Td>
      <Td position="center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_VIEWER_URL}/viewer/issuer/${props.issuerId}/cert/${props.cert.id}`}
        >
          <IconButton
            height="32px"
            width="32px"
            shadow="NONE"
            border="0.75px solid #CCCCCC83"
          >
            <PreviewIcon width="24px" />
          </IconButton>
        </a>
      </Td>
      {/* TODO: Remove comment out when we implement each function */}
      {/* <StyledTdCenter>
        <IconButton
          height={'30px'}
          width={'30px'}
          shadow={true}
          onClick={() => {
            // do something
          }}
        >
          <Stop />
        </IconButton>
      </StyledTdCenter> */}
      {/* <StyledTdLeft>{certId}</StyledTdLeft> */}
      <Td paddingLeft="25px">{holderName}</Td>
      <Td paddingRight="12px">
        <TextWithDots>{consoleID}</TextWithDots>
      </Td>
      <Td>{holderMailAddress}</Td>
      <Td padding="7px 0 0 0">{<Label name={labels[0]} />}</Td>
      <Td>{formattedIssueAt}</Td>
      <Td>{formattedDeliveryAt || `${t('Unsent')}`}</Td>
      <Td>{formattedExpireAt || 'Unknown'}</Td>
    </Tr>
  );
};

export default TableRow;

import React from 'react';
import TableHeader from '../../molecules/table/TableHeader';
import TableRow from '../../molecules/table/TableRow';
import styled from 'styled-components';
import { useIssuerContext } from '../../../context/IssuerContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from '../../atoms/loading/Spinner';
import { Flex } from '../../atoms/layouts';
import { Certificate } from '../../../generated/types';

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr:nth-child(even) td {
    background: #f2f2f2;
  }

  tr:nth-child(odd) td {
    background: white;
  }
`;

type StyledInfiniteScrollProps = {
  height: string;
  width: string;
};

const StyledInfiniteScroll = styled.div<StyledInfiniteScrollProps>`
  overflow-x: scroll;
  justify-content: center;
  max-height: 512px;
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #76a0cc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

type Props = {
  certs: Certificate[];
  dataLength: number;
  next: () => void;
  hasNext: boolean;
};

const UnsentTable: React.FC<Props> = ({
  certs,
  next,
  hasNext,
  dataLength,
}: Props) => {
  const { issuer } = useIssuerContext();
  return (
    <StyledInfiniteScroll
      id="infinityScrollWrapper"
      height={'fit-content'}
      width={'100%'}
    >
      <InfiniteScroll
        dataLength={dataLength}
        next={next}
        hasMore={hasNext}
        scrollableTarget="infinityScrollWrapper"
        loader={
          <Flex height="80px" justifyContent="center">
            <Spinner />
          </Flex>
        }
      >
        <StyledTable>
          <TableHeader />
          <tbody>
            {certs.map((cert: Certificate, index) => (
              <TableRow key={index} cert={cert} issuerId={issuer.id} />
            ))}
          </tbody>
        </StyledTable>
      </InfiniteScroll>
    </StyledInfiniteScroll>
  );
};

export default UnsentTable;

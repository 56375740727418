import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../../../atoms/box';
import { Text } from '../../../atoms/text/Text';

export const FixedMainBody: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Box>
      <Text>{t('FixedMailBodyText1')}</Text>
      <Text>https://cloudcerts.io/viewer/issuer/sampleXXX</Text>
      <Text color="#ED1C24" size="sm">
        {t('FixedMailBodyText2')}
      </Text>
    </Box>
  );
};

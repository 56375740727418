import { Heading } from '../../../atoms/heading/Heading';
import { Flex } from '../../../atoms/layouts';
import { LabeledFieldRow } from '../../../molecules/labeled-field/LabeledFieldRow';
import { Box } from '../../../atoms/box';
import { useTranslation } from 'react-i18next';

/**
 * 新規登録ユーザー確認画面の「個人情報の取り扱いについて」部分
 * @returns
 */
export const PersonalInformationConfirmation: React.VFC = () => {
  const [t] = useTranslation();

  return (
    <Flex
      bgColor="white"
      width="840px"
      marginTop="0"
      marginBottom="56px"
      padding="25px 96px 42px 25px"
      flexDirection="column"
    >
      <Box marginBottom="32px">
        <Heading as="h2" size="lg" fontWeight="bold">
          {t('form.personal_information.title')}
        </Heading>
      </Box>
      <Flex gap="28px" flexDirection="column" alignItems="flex-end">
        <LabeledFieldRow
          label={t('form.personal_information.confirmation')}
          rightWidth="430px"
        >
          {t('form.personal_information.agree')}
        </LabeledFieldRow>
      </Flex>
    </Flex>
  );
};

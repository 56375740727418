import React from 'react';
import { Box } from '../../../atoms/box';
import { ReactComponent as MalComplatedIcon } from '../../../../assets/icons/mail-complated.svg';
import { ReactComponent as ErrorMarkIcon } from '../../../../assets/icons/error-mark.svg';
import { Center, Flex } from '../../../atoms/layouts';
import { Text } from '../../../atoms/text/Text';
import Icon from '../../../atoms/icon/Icon';
import { Button } from '../../../atoms/button/Button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = SuccessProps & {
  success: boolean;
};

type SuccessProps = {
  onClose: () => void;
  email: string;
};

const StyledA = styled.a`
  text-decoration: none;
`;

export const ResultBody: React.FC<Props> = ({ success, ...rest }: Props) => {
  return (
    <Center>
      <Flex
        height="80%"
        width="50%"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
      >
        {success ? <SuccessResult {...rest} /> : <ErrorResult />}
      </Flex>
    </Center>
  );
};

const SuccessResult: React.FC<SuccessProps> = ({
  email,
  onClose,
}: SuccessProps) => {
  const [t, i18n] = useTranslation();

  return (
    <>
      <Icon height="240px">
        <MalComplatedIcon />
      </Icon>
      <Box>
        {i18n.language === 'en' ? (
          <Text>
            {t('SendMailResultText1')}
            {email}.
          </Text>
        ) : (
          <Text>
            {email}
            {t('SendMailResultText1')}
          </Text>
        )}
        <Text>{t('SendMailResultText2')}</Text>
      </Box>
      <Button
        shadow="LEVEL2"
        bgColor="#76A0CC"
        borderRadius="4px"
        onClick={onClose}
      >
        {t('Close')}
      </Button>
    </>
  );
};

const ErrorResult: React.FC = () => {
  const [t] = useTranslation();

  return (
    <>
      <Text>{t('SendMailResultErrorText1')}</Text>
      <ErrorMarkIcon />
      <StyledA href="mailto:cloudcerts-support@cyber-l.co.jp">
        <Button
          padding="5px 10px"
          width="230px"
          bgColor="#FF411F"
          borderRadius="8px"
          shadow="LEVEL1"
        >
          {t('SendMailResultErrorText2')}
        </Button>
      </StyledA>
    </>
  );
};

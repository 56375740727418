import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enJson from '../data/locales/en.json';
import jaJson from '../data/locales/ja.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    lng: window.localStorage.getItem('lang') || 'ja',
    resources: {
      en: { translation: enJson },
      ja: { translation: jaJson },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

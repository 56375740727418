import React, { ComponentProps, useState } from 'react';
import { Modal } from '../../../molecules/modal/Modal';
import { TextInput } from '../../../atoms/text-input/TextInput';
import styled from 'styled-components';
import { Button } from '../../../atoms/button/Button';
import { useAuthContext } from '../../../../context/AuthContext';
import { log } from '../../../../utils/logger';
import { ErrMsg } from '../../../standalones/login/LoginForm';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  CODE_MIN_LENGTH,
  MIN_PW_LENGTH,
  UPPER_LOWER_NUMBER_CASE,
} from '../../../constant';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../../context/ToastContext';

type Props = Omit<ComponentProps<typeof Modal>, 'children'> & {
  email: string;
};

type ResetPasswordValues = {
  code: string;
  newPassword: string;
  confPassword: string;
};

export const ResetPasswordModal: React.VFC<Props> = ({
  email,
  ...modalProps
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordValues>();
  const password = watch('newPassword');

  const [awsErrMsg, setAwsErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { resetPassword } = useAuthContext();

  const toast = useToast();

  const onSubmit = async (data: ResetPasswordValues) => {
    try {
      setLoading(true);
      await resetPassword(email, data.code, data.newPassword);
      modalProps.onClose?.();
      history.push('/login');
      toast({
        status: 'success',
        text: t('feedback.reset_password'),
      });
    } catch (err) {
      log('error', err);
      setAwsErrMsg(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...modalProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>{t('form.description.reset_password', { email })}</p>

        <Box>
          <div>
            <label>{t('form.label.code')}</label>
            <TextInput
              variant="filled"
              {...register('code', {
                required: 'form.error.required',
                min: {
                  value: CODE_MIN_LENGTH,
                  message: 'form.error.least',
                },
              })}
            />
            {errors.code?.message && (
              <ErrMsg>
                {t(errors.code.message, { number: CODE_MIN_LENGTH })}
              </ErrMsg>
            )}
          </div>

          <div>
            <label>{t('form.label.new_password')}</label>
            <TextInput
              variant="filled"
              type="password"
              {...register('newPassword', {
                required: 'form.error.required',
                pattern: {
                  value: new RegExp(UPPER_LOWER_NUMBER_CASE),
                  message: 'form.error.password',
                },
              })}
            />
            {errors.newPassword?.message && (
              <ErrMsg>
                {t(errors.newPassword?.message, { number: MIN_PW_LENGTH })}
              </ErrMsg>
            )}
          </div>

          <div>
            <label>{t('form.label.conf_password')}</label>
            <TextInput
              variant="filled"
              type="password"
              {...register('confPassword', {
                required: 'form.error.required',
                validate: (value) => value === password || 'form.error.match',
              })}
            />
            {errors.confPassword?.message && (
              <ErrMsg>{t(errors.confPassword?.message)}</ErrMsg>
            )}
          </div>

          <ErrMsg>{awsErrMsg}</ErrMsg>

          <ButtonWrapper>
            <Button width="100%" type="submit" loading={loading}>
              {t('form.button.change_password')}
            </Button>
          </ButtonWrapper>
        </Box>
      </form>
    </Modal>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

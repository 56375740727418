import { Heading } from '../../../atoms/heading/Heading';
import { Flex } from '../../../atoms/layouts';
import { TextInput } from '../../../atoms/text-input/TextInput';
import { LabeledFieldRow } from '../../../molecules/labeled-field/LabeledFieldRow';
import { Text } from '../../../atoms/text/Text';
import { formValidation, palette } from '../../../constant';
import { FormState, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useRef } from 'react';
import { RegistrationValues } from '../../../../context/RegistrationContext';
import { useTranslation } from 'react-i18next';

const textInputWidth = '430px';
const requiredErrMsg = 'form.error.required';

type Props = {
  register: UseFormRegister<RegistrationValues>;
  formState: FormState<RegistrationValues>;
  watch: UseFormWatch<RegistrationValues>;
};

export const AdminForm: React.VFC<Props> = ({ register, formState, watch }) => {
  const password = useRef({});
  password.current = watch('staffPassword', '');
  const [t] = useTranslation();

  return (
    <Flex
      bgColor="white"
      width="840px"
      marginTop="68px"
      marginBottom="56px"
      padding="25px 96px 42px 25px"
      flexDirection="column"
    >
      <Flex alignItems="center" gap="17px" marginBottom="32px">
        <Heading as="h2" size="lg" fontWeight="bold">
          {t('common.admin')}
        </Heading>
        <Text size="sm" color={palette.red.base}>
          ※{t('form.tip.all_required')}
        </Text>
      </Flex>

      <Flex gap="28px" flexDirection="column" alignItems="flex-end">
        <LabeledFieldRow
          label={t('form.label.admin_name')}
          tip={t('form.tip.admin_name')}
          rightWidth={textInputWidth}
          error={formState.errors.staffName?.message}
        >
          <TextInput
            {...register('staffName', {
              required: `${t(requiredErrMsg)}`,
            })}
          />
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.admin_email')}
          rightWidth={textInputWidth}
          tip={t('form.tip.admin_email')}
          error={formState.errors.staffEmail?.message}
        >
          <TextInput
            {...register('staffEmail', {
              required: `${t(requiredErrMsg)}`,
              pattern: {
                value: formValidation.regex.email,
                message: t('form.error.email'),
              },
            })}
          />
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.admin_password')}
          tip={t('form.tip.password')}
          rightWidth={textInputWidth}
          error={formState.errors.staffPassword?.message}
        >
          <TextInput
            type="password"
            {...register('staffPassword', {
              required: `${t(requiredErrMsg)}`,
              pattern: {
                value: formValidation.regex.password,
                message: t('form.error.password'),
              },
            })}
          />
        </LabeledFieldRow>

        <LabeledFieldRow
          label={`(${t('form.label.for_confirmation')})`}
          rightWidth={textInputWidth}
          error={formState.errors.confStaffPassword?.message}
        >
          <TextInput
            type="password"
            {...register('confStaffPassword', {
              required: `${t(requiredErrMsg)}`,
              validate: (value) =>
                value === password.current || `${t('form.error.match')}`,
            })}
          />
        </LabeledFieldRow>
      </Flex>
    </Flex>
  );
};

import React from 'react';

export type SendEmailInfo = {
  issuerId: string;
  certIds: string[];
};

type QueryContextType = {
  sendEmailInfo: SendEmailInfo;
  setSendEmailInfo: React.Dispatch<SendEmailInfo>;
};

export const QueryContext = React.createContext<QueryContextType>({
  sendEmailInfo: { issuerId: '', certIds: [] },
  setSendEmailInfo: () => {
    // do nothing
  },
});

export const useQueryContext = (): QueryContextType =>
  React.useContext(QueryContext);

type PropsType = {
  children: React.ReactChild;
};

const QueryContextProvider: React.FC<PropsType> = (props: PropsType) => {
  const [sendEmailInfo, setSendEmailInfo] = React.useState<SendEmailInfo>({
    issuerId: '',
    certIds: [],
  });

  return (
    <QueryContext.Provider
      value={{
        sendEmailInfo,
        setSendEmailInfo,
      }}
    >
      {props.children}
    </QueryContext.Provider>
  );
};

export default QueryContextProvider;

import React from 'react';
import styled from 'styled-components';
import { BasicStyle, FlexItemProps, FlexItemStyle } from '../../StyleTypes';
import { Box } from '../box';

type Props = React.ComponentProps<typeof Box> &
  FlexItemProps & {
    children: React.ReactNode;
  };

const StyledFlexItem = styled.div<Props>`
  ${BasicStyle}
  ${FlexItemStyle}
`;

export const FlexItem: React.FC<Props> = ({ children, ...props }: Props) => {
  return <StyledFlexItem {...props}>{children}</StyledFlexItem>;
};

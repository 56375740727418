import React from 'react';
import styled from 'styled-components';

type VPosition = 'left' | 'center' | 'right';
type HPosition = 'start' | 'center' | 'end';

type Props = {
  children: React.ReactNode;
  margin?: string;
  marginTop?: string;
  marginLeft?: string;
  vPosition?: VPosition;
  hPosition?: HPosition;
  height?: string;
  gridTemplateColumns?: string;
};

const StyledOneRow = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns = '40px 1fr' }) =>
    gridTemplateColumns};
  justify-items: ${({ hPosition = 'left' }) => hPosition};
  align-items: ${({ vPosition = 'center' }) => vPosition};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  height: ${({ height }) => height};
  margin-left: ${({ marginLeft }) => marginLeft};
`;

export const OneRow: React.FC<Props> = ({ children, ...props }: Props) => {
  return <StyledOneRow {...props}>{children}</StyledOneRow>;
};

import React from 'react';
import styled from 'styled-components';
import { palette } from '../../constant';
import {
  BasicStyle,
  BasicStyleProps,
  TextAlignProps,
  TextAlignStyle,
} from '../../StyleTypes';

type Props = BasicStyleProps &
  TextAlignProps & {
    children?: React.ReactNode;
    scrollable?: boolean;
    maxHeight?: string;
    dangerouslySetInnerHTML?: { __html: string };
  };

const StyledBox = styled.div<Props>`
  ${BasicStyle}
  ${TextAlignStyle}
`;

const ScrollBox = styled(StyledBox)`
  overflow-x: scroll;
  justify-content: center;

  max-height: ${({ maxHeight = '70vh' }) => maxHeight};
  width: ${({ width = '95%' }) => width};
  height: ${({ height = '70vh' }) => height};
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${palette.blue.light};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const Box: React.FC<Props> = ({
  children,
  scrollable,
  ...rest
}: Props) => {
  if (scrollable) {
    return <ScrollBox {...rest}>{children}</ScrollBox>;
  }

  return <StyledBox {...rest}>{children}</StyledBox>;
};

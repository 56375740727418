import React from 'react';
import * as H from 'history';
import LoginForm from './LoginForm';
import {
  useAuthContext,
} from '../../../context/AuthContext';
import { log } from '../../../utils/logger';

type LoginInfo = {
  email: string;
  password: string;
};

type Props = {
  history: H.History;
};

const LoginManager: React.FC<Props> = (props: Props) => {
  const { authenticate } = useAuthContext();

  const [errMsg, setErrMsg] = React.useState<string>('');
  const [inputValues, setInputValues] = React.useState<LoginInfo>({
    email: '',
    password: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name: key, value } = e.target;

    setInputValues({
      ...inputValues,
      [key]: value,
    });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const { email, password } = inputValues;
      await authenticate(email, password);
      props.history.push('/');
    } catch (error) {
      log('error', error.message);
      setErrMsg('Your inputs is incorrect. Please enter again');
    }
  };

  return (
    <LoginForm
      errMsg={errMsg}
      history={props.history}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  );
};

export default LoginManager;

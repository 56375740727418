import React from 'react';
import { Box } from '../../../atoms/box';
import { Flex, Grid, GridItem } from '../../../atoms/layouts';
import { Text } from '../../../atoms/text/Text';
import { TextInput } from '../../../atoms/text-input/TextInput';
import { TextArea } from '../../../atoms/textarea/TextArea';
import { FixedMainBody } from './FixedMainBody';
import { useTranslation } from 'react-i18next';

type Props = {
  mainBody: string;
  subject: string;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  setMainBody: React.Dispatch<React.SetStateAction<string>>;
};

export const EditMailBody: React.FC<Props> = ({
  mainBody,
  subject,
  setSubject,
  setMainBody,
}: Props) => {
  const [t] = useTranslation();

  return (
    <Flex alignItems="center" width="100%" height="100%" flexDirection="column">
      <Grid
        width="80%"
        height="100%"
        templateColumns="0.2fr 1fr 0.2fr"
        alignItems="center"
      >
        <GridItem justifySelf="end" marginRight="10px">
          <Text>{t('Subject')}</Text>
        </GridItem>
        <TextInput
          placeholder={t('SampleMailSubject')}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </Grid>

      <Grid
        width="80%"
        templateColumns="0.2fr 1fr 0.2fr"
        alignItems="center"
        height="80%"
      >
        <GridItem justifySelf="end" alignSelf="start" marginRight="10px">
          {t('MainText')}
        </GridItem>
        <Box
          border="1px solid #666666"
          borderRadius="4px"
          width="100%"
          height="100%"
          padding="10px"
          scrollable
        >
          <Flex bgColor="#edf3f9" alignItems="center" flexDirection="column">
            <Text
              bgColor="#dbe5f0"
              height="30px"
              lineHeight="30px"
              paddingLeft="5px"
              width="100%"
            >
              ① {t('EditableArea')}
            </Text>
            <TextArea
              borderRadius="4px"
              margin="10px 0"
              width="90%"
              height="130px"
              placeholder={t('SampleMailBody')}
              padding="13px"
              value={mainBody}
              onChange={(e) => setMainBody(e.target.value)}
            />
          </Flex>
          <Flex bgColor="#f8f6f6" alignItems="center" flexDirection="column">
            <Text
              bgColor="#E6E6E6"
              height="30px"
              lineHeight="30px"
              paddingLeft="5px"
              width="100%"
            >
              ② {t('CertificateInformationArea')}
            </Text>
            <Box padding="10px">
              <FixedMainBody />
            </Box>
          </Flex>
        </Box>
      </Grid>
    </Flex>
  );
};

import styled from 'styled-components';
import { palette } from '../../constant';
import { Box } from '../box';

type Props = {
  borderColor?: string;
  borderWeight?: string;
};

export const SpeechBubble = styled(Box)<Props>`
  position: relative;
  background: ${palette.white};
  border: 1px solid ${palette.blue.heavy};
  color: #1465ff;
  font-size: 16px;
  display: grid;
  align-items: center;

  :after,
  :before {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 19%;
  }
  :after {
    border-color: rgba(255, 255, 255, 0);
    border-top-width: 5px;
    border-bottom-width: 5px;
    border-left-width: 21px;
    border-right-width: 21px;
    margin-top: -5px;
    border-right-color: ${palette.white};
  }
  :before {
    border-color: rgba(0, 110, 184, 0);
    border-top-width: 6px;
    border-bottom-width: 6px;
    border-left-width: 25px;
    border-right-width: 25px;
    margin-top: -6px;
    margin-right: 1px;
    border-right-color: #006eb8;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { BasicStyle, GridProps, GridStyle } from '../../StyleTypes';
import { Box } from '../box';

type Props = React.ComponentProps<typeof Box> &
  GridProps & {
    children: React.ReactNode;
  };

const StyledGrid = styled.div<Props>`
  ${BasicStyle}
  ${GridStyle}

  display: grid;
`;

export const Grid: React.FC<Props> = ({ children, ...props }: Props) => {
  return <StyledGrid {...props}>{children}</StyledGrid>;
};

import { FC, ReactChild } from 'react';

import AuthContext from './AuthContext';
import WindowContext from './WindowContext';
import SearchCertsContext from './SearchCertsContext';
import QueryContext from './QueryContext';
import ModalContext from './ModalContext';
import SelectedCertsContext from './SelectedCertsContext';
import AccordionContext from './AccordionContext';
import RegistrationContext from './RegistrationContext';
import { ToastContextProvider } from './ToastContext';

type Props = {
  children: ReactChild;
};

const Context: FC<Props> = (props: Props) => (
  <AuthContext>
    <WindowContext>
      <SearchCertsContext>
        <AccordionContext>
          <ModalContext>
            <SelectedCertsContext>
              <RegistrationContext>
                <ToastContextProvider>
                  <QueryContext>{props.children}</QueryContext>
                </ToastContextProvider>
              </RegistrationContext>
            </SelectedCertsContext>
          </ModalContext>
        </AccordionContext>
      </SearchCertsContext>
    </WindowContext>
  </AuthContext>
);

export default Context;

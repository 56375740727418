import React, { FC } from 'react';

import { ApolloProvider } from '@apollo/client';
import ApolloClientApp from './client';
import { useAuthContext } from '../context/AuthContext';
import { Center } from '../styles/mixins/Center';
import { Spinner } from '../components/atoms/loading/Spinner';

type Props = {
  children: React.ReactChild;
};

const Apollo: FC<Props> = (props: Props) => {
  const { user, pending, authenticated } = useAuthContext();

  if (authenticated && pending) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <ApolloProvider client={ApolloClientApp(user)}>
      {props.children}
    </ApolloProvider>
  );
};

export default Apollo;

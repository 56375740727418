import React from 'react';
import ListModal, { Items } from '../molecules/modal/ListModal';
import styled from 'styled-components';
import { useAuthContext } from '../../context/AuthContext';

const ConfigWrapper = styled.div`
  border: 0.3px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
`;

type Props = {
  handleCloseModal: () => void;
};

const ConfigModal: React.FC<Props> = ({ handleCloseModal }: Props) => {
  const [activeModal, setActiveModal] = React.useState<
    'settings' | 'logout' | null
  >(null);
  const { authenticated, logout } = useAuthContext();

  const rootConfig: Items = [
    {
      name: 'Move to Settings',
      open: activeModal === 'settings',
      root: true,
      link: true,
      to: '/settings',
      onMouse: () => setActiveModal('settings'),
    },
    {
      name: 'Logout',
      root: true,
      show: authenticated,
      onClick: logout,
      onMouse: () => setActiveModal('logout'),
    },
  ];

  return (
    <ConfigWrapper onClick={handleCloseModal}>
      <ListModal items={rootConfig} onMouseLeave={() => setActiveModal(null)} />
    </ConfigWrapper>
  );
};

export default ConfigModal;

import { css } from 'styled-components';

export type MarginProps = {
  margin?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
};

export type PaddingProps = {
  padding?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
};

export type BorderProps = {
  border?: string;
  borderBottom?: string;
  borderTop?: string;
  borderLeft?: string;
  borderRight?: string;
  borderRadius?: string;
};

export type SizeProps = {
  minHeight?: string;
  maxHeight?: string
  height?: string;
  minWidth?: string
  maxWidth?: string
  width?: string;
};

export type ColorProps = {
  color?: string;
  bgColor?: string;
};

export type CursorProps = {
  cursor?:
    | 'pointer'
    | 'wait'
    | 'help'
    | 'auto'
    | 'progress'
    | 'text'
    | 'grab'
    | 'grabbing'
    | 'default';
};

export type DisableProps = {
  disabled?: boolean;
  disabledColor?: string;
};

export type ButtonTypeProps = {
  type?: 'button' | 'submit' | 'reset';
};

export type DisplayProps = {
  display?: 'flex' | 'grid' | 'inline' | 'block' | 'inline-block';
};

export type TextAlignProps = {
  textAlign?: 'center' | 'right' | 'left' | 'justify';
};

export type FlexStyleProps = {
  justifyContent?: 'start' | 'center' | 'space-between' | 'space-around';
  alignItems?: "center" | "start" | "end" | "stretch" | "flex-start" | "flex-end";
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  gap?: string;
};

export type FlexPositionProperty =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'stretch';

export type FlexItemProps = {
  alignSelf?: FlexPositionProperty;
  justifySelf?: FlexPositionProperty;
};

export type GridPositionProperty = 'stretch' | 'center' | 'start' | 'end';

export type GridProps = {
  templateColumns?: string;
  templateRows?: string;
  justifyItems?: GridPositionProperty;
  alignItems?: GridPositionProperty;
  placeItems?: GridPositionProperty;
};

export type GridItemProps = {
  alignSelf?: GridPositionProperty;
  justifySelf?: GridPositionProperty;
};

const typography = {
  "3xl": "2.441rem",
  "2xl": "1.953rem",
  xl: "1.563rem",
  lg: "1.25rem",
  md: "1rem",
  sm: "0.8rem"
} as const

export type FontSize = keyof typeof typography;

export type FontProps = {
  fontWeight?: string;
  size?: FontSize;
  lineHeight?: string;
};

export type InputSystemProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: 'password' | 'text' | 'file' | 'submit' | 'reset' | 'button';
  variant?: 'outline' | 'filled';
  value?: string;
  name?: string;
};

export type BasicStyleProps = MarginProps &
  BorderProps &
  SizeProps &
  ColorProps &
  CursorProps &
  PaddingProps;

export const BorderStyle = css<BorderProps>`
  border: ${({ border }) => border};
  border-bottom: ${({ borderBottom }) => borderBottom};
  border-top: ${({ borderTop }) => borderTop};
  border-right: ${({ borderRight }) => borderRight};
  border-left: ${({ borderLeft }) => borderLeft};
  border-radius: ${({ borderRadius }) => borderRadius};
`;

export const MarginStyle = css<MarginProps>`
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
`;

export const PaddingStyle = css<PaddingProps>`
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
`;

export const TextAlignStyle = css<TextAlignProps>`
  text-align: ${({ textAlign }) => textAlign};
`;

export const SizeStyle = css<SizeProps>`
  max-height: ${({ maxHeight }) => maxHeight};
  min-height: ${({ minHeight }) => minHeight};
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  width: ${({ width }) => width};
`;

export const ColorStyle = css<ColorProps>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
`;

export const CursorStyle = css<CursorProps>`
  cursor: ${({ cursor }) => cursor};
`;

export const DisplayStyle = css<DisplayProps>`
  display: ${({ display }) => display};
`;

export const FlexStyle = css<FlexStyleProps>`
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: ${({  gap }) => gap}
`;

export const FlexItemStyle = css<FlexItemProps>`
  justify-self: ${({ justifySelf }) => justifySelf};
  align-self: ${({ alignSelf }) => alignSelf};
`;

export const GridStyle = css<GridProps>`
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  grid-template-rows: ${({ templateRows }) => templateRows};
  justify-items: ${({ justifyItems }) => justifyItems};
  align-items: ${({ alignItems }) => alignItems};
  place-items: ${({ placeItems }) => placeItems};
`;

export const GridItemStyle = css<GridItemProps>`
  justify-self: ${({ justifySelf }) => justifySelf};
  align-self: ${({ alignSelf }) => alignSelf};
`;

export const FontStyle = css<FontProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ size = "md" }) => typography[size]};
  line-height: ${({ lineHeight }) => lineHeight};
`;

export const BasicStyle = css<BasicStyleProps>`
  ${SizeStyle}
  ${ColorStyle}
  ${MarginStyle}
  ${PaddingStyle}
  ${BorderStyle}
  ${CursorStyle}
`;

import React from 'react';
import styled from 'styled-components';
import { palette } from '../../constant';

type Props = {
  position?: 'left' | 'center';
  children: React.ReactNode;
  padding?: string;
  width?: string;
  height?: string;
  h?: string;
  w?: string;
};

const StyledTh = styled.th<Props>`
  font-size: 14px;
  font-weight: normal;
  top: 0;
  z-index: 1;
  background-color: ${palette.paleBlue.base};
  color: ${palette.gray.dark};

  padding: ${({ padding = '0 5px' }) => padding};
  width: ${({ width, w }) => width || w};
  height: ${({ height, h }) => height || h};
`;

const StyledCenterTh = styled(StyledTh)`
  text-align: center;
`;

const StyledLeftTh = styled(StyledTh)`
  text-align: left;
`;

export const Th: React.FC<Props> = ({ position, children, ...rest }: Props) => {
  if (position === 'center') {
    return <StyledCenterTh {...rest}>{children}</StyledCenterTh>;
  }

  return <StyledLeftTh {...rest}>{children}</StyledLeftTh>;
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../../atoms/button/Button';
import { TextWithDots } from '../../atoms/utils';

const CancelButton = styled(Button)`
  :hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

const StyledUploadControlBar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
`;

type Props = {
  handleCancel: () => void;
  handleUpload: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<void>;
  file: File | undefined;
  loading?: boolean;
};

const FileUploadForm: React.FC<Props> = (props: Props) => {
  const [t] = useTranslation();

  return (
    <form>
      <TextWithDots margin="0 0 10px 0">
        {props.file && t(props.file?.name)}
      </TextWithDots>

      <StyledUploadControlBar>
        <Button
          onClick={props.handleUpload}
          width="120px"
          height="35px"
          borderRadius="4px"
          shadow="LEVEL1"
          loading={props.loading}
        >
          {t('Upload')}
        </Button>

        <CancelButton
          onClick={props.handleCancel}
          width="100px"
          height="35px"
          borderRadius="4px"
          bgColor="WHITE"
          color="#477BB0"
        >
          {t('Cancel')}
        </CancelButton>
      </StyledUploadControlBar>
    </form>
  );
};

export default FileUploadForm;

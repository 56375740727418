import React from 'react';
import styled from 'styled-components';
import { IconButton } from '../../atoms/button/IconButton';

const IconAndNamePareStyle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
`;

const StyledRoundIcon = styled(IconButton)`
  cursor: default;
`;

type PropTypes = {
  text: string;
  path: string;
};

const IconAndNamePare: React.FC<PropTypes> = (props: PropTypes) => {
  return (
    <IconAndNamePareStyle>
      <StyledRoundIcon
        height={'22px'}
        width={'22px'}
        marginRight={'10px'}
        shadow="NONE"
        onClick={() => {
          // do something
        }}
      >
        <img src={props.path} height={15} width={15} alt="icon" />
      </StyledRoundIcon>
      {props.text}
    </IconAndNamePareStyle>
  );
};

export default IconAndNamePare;

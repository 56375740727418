import styled from 'styled-components';
import { Flex } from '../../atoms/layouts';
import { Text } from '../../atoms/text/Text';
import { ReactComponent as QuestionCircle } from '../../../assets/icons/question-circle.svg';
import ReactTooltip from 'react-tooltip';
import { useRef } from 'react';

type Props = {
  label: string;
  tip?: string;
  tipLink?: string;
  leftWidth?: string;
  rightWidth?: string;
  error?: string;
};

export const LabeledFieldRow: React.FC<Props> = ({
  children,
  leftWidth,
  rightWidth,
  tip,
  tipLink,
  label,
  error,
}) => {
  const leftRef = useRef<HTMLDivElement>(null);
  const leftDivWidth = leftRef.current?.clientWidth || 0;

  return (
    <div>
      <Flex alignItems="center" gap="20px" flexDirection="row">
        <Left width={leftWidth} ref={leftRef}>
          <Label>{label}</Label>
          {tip && (
            <div>
              {tipLink ? (
                <QuestionLink href={tipLink} target="_blank">
                  <QuestionCircle data-tip={tip} />
                </QuestionLink>
              ) : (
                <Question>
                  <QuestionCircle data-tip={tip} />
                </Question>
              )}
              <ReactTooltip place="bottom" />
            </div>
          )}
        </Left>

        <Right width={rightWidth}>{children}</Right>
      </Flex>
      {error && (
        <Text
          color="red"
          size="sm"
          marginLeft={`${leftDivWidth + 20}px`}
          margin="0"
        >
          {error}
        </Text>
      )}
    </div>
  );
};

const Label = styled.label`
  white-space: nowrap;
`;

const Left = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
`;

const Right = styled.div<{ width?: string }>`
  flex: 1;
  width: ${({ width }) => width};
`;

const Question = styled.span`
  align-items: center;
  display: flex;
  margin-left: 9px;
`;

const QuestionLink = styled.a`
  align-items: center;
  display: flex;
  margin-left: 9px;
`;

import React from 'react';
import ResetPasswordByEmail from './ResetPasswordByEmail';
import ResetPasswordWithVerificationCode from './ResetPasswordWithVerificationCode';

const isAWS = process.env.REACT_APP_AUTH_ADAPTER_TYPE === 'AWS';

const Page: React.VFC = () =>
  isAWS ? <ResetPasswordWithVerificationCode /> : <ResetPasswordByEmail />;

export default Page;

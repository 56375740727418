import React from 'react';
import { useModalContext } from '../../../context/ModalContext';
import { ProcessChainHeader } from '../../molecules/process-chain-modal/Header';
import { ProcessChainModal } from '../../molecules/process-chain-modal/Modal';
import { ReactComponent as TestMailIcon } from '../../../assets/icons/test-mail.svg';
import { ReactComponent as SendMassIcon } from '../../../assets/icons/send-mass-email.svg';
import { EditMailBody } from './bodies/EditMail';
import { PreviewMailBody } from './bodies/PreviewMail';
import { ResultBody } from './bodies/Result';
import { useMailModal } from '../../../core/presenter/useEmailModal';
import { EditMailFooter } from './footers/EditMail';
import { PreviewMailFooter } from './footers/PreviewMail';
import { PreviewTestMailFooter } from './footers/PreviewTestMail';
import { PreviewTestMailBody } from './bodies/PreviewTestMail';
import { useIssuerContext } from '../../../context/IssuerContext';
import { useAccordionContext } from '../../../context/AccordionContext';
import { SentStatus } from '../../../apollo/constants';
import { useCertsCountQuery } from '../../../generated/types';
import { EmailModalProps } from './modalProps';

export const TestPreviewModal: React.FC<EmailModalProps> = ({
  usedGroupCerts,
  usedSendEmail,
}: EmailModalProps) => {
  const { testMailPreview, toggleTestMailPreview } = useModalContext();
  const {
    chainState,
    currentIndex,
    mutations,
    onNext,
    onBack,
    onDoubleBack,
    onClose,
  } = useMailModal(
    [
      { text: 'InputText' },
      {
        text: 'ConfirmationSendTestMail',
      },
      { text: 'SendMassMail' },
      { text: 'Completed' },
    ],
    toggleTestMailPreview,
    usedSendEmail,
    usedGroupCerts,
  );
  const { staff } = useIssuerContext();

  const { getOpenedGroup } = useAccordionContext();
  const openedGroup = getOpenedGroup();
  const { issuer } = useIssuerContext();

  const { data } = useCertsCountQuery({
    variables: {
      issuerId: issuer.id,
      page: 1,
      groupId: openedGroup,
      sentStatus: SentStatus.UNSENT_LIST,
    },
  });

  const editTestMailState = {
    header: (
      <ProcessChainHeader
        title="EditTestMail"
        icon={<TestMailIcon />}
        processStateList={chainState}
      />
    ),
    body: <EditMailBody {...usedSendEmail.state} {...usedSendEmail.setter} />,
    footer: (
      <EditMailFooter
        onClose={() => toggleTestMailPreview(false)}
        onNext={onNext}
      />
    ),
  };

  const confirmTestMailState = {
    header: (
      <ProcessChainHeader
        title="ConfirmTestMail"
        icon={<TestMailIcon />}
        processStateList={chainState}
      />
    ),
    body: (
      <PreviewTestMailBody {...usedSendEmail.state} {...usedSendEmail.setter} />
    ),
    footer: (
      <PreviewTestMailFooter
        onBack={onBack}
        onSendTestMail={mutations.onSendTestEmailAndNext}
        email={staff.email}
      />
    ),
  };

  const confirmMassEmailState = {
    header: (
      <ProcessChainHeader
        title="ConfirmMassMail"
        icon={<SendMassIcon />}
        processStateList={chainState}
      />
    ),
    body: data && (
      <PreviewMailBody
        {...usedSendEmail.state}
        {...usedSendEmail.setter}
        numberOfCerts={data.getCertsList.count}
      />
    ),
    footer: (
      <PreviewMailFooter
        onBack={onDoubleBack}
        onSendMail={mutations.onSendGroupEmail}
        onSendTestMail={mutations.onSendTestEmailAndStay}
        email={staff.email}
      />
    ),
  };

  const resultState = {
    header: (
      <ProcessChainHeader
        title="CompleteSendToMassMail"
        icon={<SendMassIcon />}
        processStateList={chainState}
      />
    ),
    body: (
      <ResultBody
        key={3}
        onClose={onClose}
        email={staff.email}
        success={chainState[3].status !== 'failure'}
      />
    ),
  };

  return (
    <ProcessChainModal
      notificationStatus={usedSendEmail.testMailStatus}
      isOpen={testMailPreview}
      onClose={onClose}
      currentIndex={currentIndex}
      chainState={[
        editTestMailState,
        confirmTestMailState,
        confirmMassEmailState,
        resultState,
      ]}
      email={staff.email}
    />
  );
};

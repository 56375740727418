import React from 'react';
import { useModalContext } from '../../../context/ModalContext';
import { ProcessChainHeader } from '../../molecules/process-chain-modal/Header';
import { ProcessChainModal } from '../../molecules/process-chain-modal/Modal';
import { ReactComponent as SendMassIcon } from '../../../assets/icons/send-mass-email.svg';
import { EditMailBody } from './bodies/EditMail';
import { PreviewMailBody } from './bodies/PreviewMail';
import { ResultBody } from './bodies/Result';
import { useMailModal } from '../../../core/presenter/useEmailModal';
import { EditMailFooter } from './footers/EditMail';
import { PreviewMailFooter } from './footers/PreviewMail';
import { useIssuerContext } from '../../../context/IssuerContext';
import { useAccordionContext } from '../../../context/AccordionContext';
import { SentStatus } from '../../../apollo/constants';
import { useCertsCountQuery } from '../../../generated/types';
import { EmailModalProps } from './modalProps';

export const PreviewModal: React.FC<EmailModalProps> = ({
  usedGroupCerts,
  usedSendEmail,
}: EmailModalProps) => {
  const { mailPreview, toggleMailPreview } = useModalContext();
  const {
    chainState,
    currentIndex,
    mutations,
    onNext,
    onBack,
    onClose,
  } = useMailModal(
    [
      { text: 'InputText' },
      { text: 'ConfirmationSendMail' },
      { text: 'Completed' },
    ],
    toggleMailPreview,
    usedSendEmail,
    usedGroupCerts,
  );
  const { staff } = useIssuerContext();

  const { getOpenedGroup } = useAccordionContext();
  const openedGroup = getOpenedGroup();
  const { issuer } = useIssuerContext();

  const { data } = useCertsCountQuery({
    variables: {
      issuerId: issuer.id,
      page: 1,
      groupId: openedGroup,
      sentStatus: SentStatus.UNSENT_LIST,
    },
  });

  const editMassEmailState = {
    header: (
      <ProcessChainHeader
        title="EditMassMail"
        icon={<SendMassIcon />}
        processStateList={chainState}
      />
    ),
    body: <EditMailBody {...usedSendEmail.setter} {...usedSendEmail.state} />,
    footer: (
      <EditMailFooter
        onClose={() => toggleMailPreview(false)}
        onNext={onNext}
      />
    ),
  };

  const confirmMassEmailState = {
    header: (
      <ProcessChainHeader
        title="ConfirmMassMail"
        icon={<SendMassIcon />}
        processStateList={chainState}
      />
    ),
    body: data && (
      <PreviewMailBody
        {...usedSendEmail.setter}
        {...usedSendEmail.state}
        numberOfCerts={data.getCertsList.count}
      />
    ),
    footer: (
      <PreviewMailFooter
        onBack={onBack}
        onSendMail={mutations.onSendGroupEmail}
        onSendTestMail={mutations.onSendTestEmailAndStay}
        email={staff.email}
      />
    ),
  };

  const resultState = {
    header: (
      <ProcessChainHeader
        title="CompleteSendToMassMail"
        icon={<SendMassIcon />}
        processStateList={chainState}
      />
    ),
    body: (
      <ResultBody
        onClose={onClose}
        email={staff.email}
        success={chainState[2].status !== 'failure'}
      />
    ),
  };

  return (
    <ProcessChainModal
      notificationStatus={usedSendEmail.testMailStatus}
      isOpen={mailPreview}
      onClose={onClose}
      currentIndex={currentIndex}
      chainState={[editMassEmailState, confirmMassEmailState, resultState]}
      email={staff.email}
    />
  );
};

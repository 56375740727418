import { ReactNode } from 'react';
import { VFC } from 'react';
import styled from 'styled-components';
import { boxShadow } from '../../constant';
import { StatusIcon } from '../status-icon/StatusIcon';

type StyleProps = {
  status: 'success' | 'error';
};

const Base = styled.div<StyleProps>`
  width: 100%;
  display: flex;
  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${({ status = 'error' }) =>
    status === 'error' ? 'rgb(254, 215, 215)' : 'rgb(198, 246, 213)'};
  ${boxShadow.LEVEL1}
`;

type Props = {
  children: ReactNode;
  status: 'success' | 'error';
};

export const Alert: VFC<Props> = ({ children, status }) => {
  return (
    <Base role="alert" status={status}>
      <StatusIcon status={status} />
      {children}
    </Base>
  );
};

export const AlertWrapper = styled.div`
  position: fixed;
  top: 10px;
  right: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

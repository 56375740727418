import React from 'react';
import { LoginUser } from '../../core/domain/LoginUser';

export type Auth = boolean;

export type AuthContextType = {
  pending: boolean;
  authenticated: boolean;
  user: LoginUser | null;
  authenticate: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  changePassword(oldPassword: string, newPassword: string): Promise<void>;
  sendResetEmail(email: string): Promise<void>;
  resetPassword(email: string, code: string, newPassword: string): Promise<void>;
  getCurrentUser(): Promise<LoginUser>;
};

export const AuthContext = React.createContext<AuthContextType>({
  pending: true,
  authenticated: false,
  user: null,
  authenticate: () => {
    throw new Error('You probably forgot to put <AuthContext>.');
  },
  logout: () => {
    throw new Error('You probably forgot to put <AuthContext>.');
  },
  changePassword: () => {
    throw new Error('You probably forgot to put <AuthContext>.');
  },
  sendResetEmail: () => {
    throw new Error('You probably forgot to put <AuthContext>.');
  },
  resetPassword: () => {
    throw new Error('You probably forgot to put <AuthContext>.');
  },
  getCurrentUser: () => {
    throw new Error('You probably forgot to put <AuthContext>.');
  },
});

export type PropsType = {
  children: React.ReactChild;
};

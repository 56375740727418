import React from 'react';
import { Flex } from '../../../atoms/layouts';
import { PreviewMail, PreviewMailProps } from '../../previewMail/previewMail';
import { Text } from '../../../atoms/text/Text';
import { useTranslation } from 'react-i18next';
import { palette } from '../../../constant';

type Props = PreviewMailProps & {
  numberOfCerts: number;
};

export const PreviewMailBody: React.FC<Props> = (props: Props) => {
  const [t] = useTranslation();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Flex alignItems="center" justifyContent="center">
        <Text>{t('PreviewMailTitleText1')}</Text>
        <Text color={palette.blue.heavy} size="lg" margin="0 10px">
          {`${props.numberOfCerts} ${t('Addresses')}`}
        </Text>
        <Text>{t('PreviewMailTitleText2')}</Text>
      </Flex>
      <PreviewMail {...props} />
    </Flex>
  );
};

import { ChangeEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../context/AuthContext";
import { useIssuerContext } from "../../../context/IssuerContext";
import { log } from "../../../utils/logger";

type MessageType = 'ready' | 'error' | 'loading' | 'success';

type Status = {
  type: MessageType;
  text: string;
};

type Props = {
  apiPath: string
  successMsg: string
  failureMsg: string
}

type UseCCBoxUploaderReturns = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onCancel: () => void;
  onUpload: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  file: File | undefined;
  status: Status;
}

export const useCCBoxUploader = ({ apiPath, failureMsg, successMsg }: Props): UseCCBoxUploaderReturns => {
  const { user } = useAuthContext();
  const { issuer } = useIssuerContext();
  const [file, setFile] = useState<File>();
  const [status, setStatus] = useState<Status>({ type: 'ready', text: '' });
  const [, i18n] = useTranslation();

  const onUpload = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setStatus({ type: 'loading', text: 'Uploading' });

    if (!file) {
      setStatus({ type: 'error', text: 'No file is selected' });
      return;
    }

    const { subject, textBody } = buildMailTemplate(i18n.language, issuer.id);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('issuerId', issuer.id);
    formData.append('subject', subject);
    formData.append('textBody', textBody);
    formData.append('language', i18n.language);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}${apiPath}`,
        {
          headers: {
            Authorization: user?.token || '',
          },
          method: 'POST',
          body: formData,
          mode: "cors",
        },
      );

      if (response.status === 200) {
        setStatus({ type: 'success', text: successMsg });
      } else {
        setStatus({ type: 'error', text: failureMsg });
      }

      log('info', 'upload/image', response);
    } catch (error) {
      setStatus({ type: 'error', text: failureMsg });
      log('error', 'upload/image', error);
    }
  };
  
  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) return;

    const file = event.target.files[0];
    setFile(file);
  };

  const onCancel = () => {
    setFile(undefined);
  };

  return {
    onChange,
    onCancel,
    onUpload,
    file,
    status
  }
}

const buildMailTemplate = (lang: string, issuerId: string) => {
  let subject = '';
  let textBody = '';

  if (lang === 'en') {
    subject = `The uploading file in CC Box for issuerId ${issuerId} were finished`;
    textBody = `Don't reply on that message`;
  } else {
    subject = `CCBoxで発行者ID ${issuerId} のファイルのアップロードが終了しました。`;
    textBody = `このメッセージには返信しないでください`;
  }

  return {
    subject,
    textBody,
  };
};
import React, { FC } from 'react';
import { IconButton } from '../../atoms/button/IconButton';
import { ReactComponent as Send } from '../../../assets/icons/send-mail.svg';
import { Center } from '../../../styles/mixins/Center';
import { useQueryContext } from '../../../context/QueryContext';
import { useIssuerContext } from '../../../context/IssuerContext';
import { useModalContext } from '../../../context/ModalContext';
import { useSelectedCerts } from '../../../context/SelectedCertsContext';

const TableOperationIcons: FC = () => {
  const size: string = '26px';
  const marginRight = '8px';

  const { setSendEmailInfo } = useQueryContext();
  const { toggleMailModal, setMessageModal } = useModalContext();
  const { selectedCerts } = useSelectedCerts();
  const { issuer } = useIssuerContext();

  const handleSetMultiSendingEmail = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    const isCertsSelected = selectedCerts.length > 0;

    if (isCertsSelected) {
      toggleMailModal(true);
    } else {
      setMessageModal({
        open: true,
        message: 'Please Select Certs',
      });
    }
    setSendEmailInfo({
      issuerId: issuer.id,
      certIds: selectedCerts,
    });
  };

  return (
    <Center V={'V'}>
      {/* TODO: Remove comment out when we implement each function */}
      {/* <RoundIconButton
        height={size}
        width={size}
        marginRight={marginRight}
        onClick={() => {
          // do something
        }}
      >
        <Archive />
      </RoundIconButton>
      <RoundIconButton
        height={size}
        width={size}
        marginRight={marginRight}
        onClick={() => {
          // do something
        }}
      >
        <Favorite />
      </RoundIconButton> */}
      <IconButton
        height={size}
        width={size}
        marginRight={marginRight}
        onClick={handleSetMultiSendingEmail}
        border="none"
      >
        <Send />
      </IconButton>
      {/* <RoundIconButton
        height={size}
        width={size}
        marginRight={marginRight}
        onClick={() => {
          // do something
        }}
      >
        <Stop />
      </RoundIconButton>
      <RoundIconButton
        height={size}
        width={size}
        marginRight={marginRight}
        onClick={() => {
          // do something
        }}
      >
        <Tag />
      </RoundIconButton>
      <RoundIconButton
        height={size}
        width={size}
        marginRight={marginRight}
        onClick={() => {
          // do something
        }}
      >
        <Menu />
      </RoundIconButton> */}
    </Center>
  );
};

export default TableOperationIcons;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Certificate = {
  __typename?: 'Certificate';
  id: Scalars['ID'];
  holderName: Scalars['String'];
  consoleID: Scalars['String'];
  holderMailAddress: Scalars['String'];
  labels: Array<Scalars['String']>;
  issueAt: Scalars['Int'];
  deliveryAt: Scalars['Int'];
  expireAt: Scalars['Int'];
  isRevoked: Scalars['Boolean'];
};

export type EmailByGroupInput = {
  issuerId: Scalars['ID'];
  groupId: Scalars['ID'];
  subject: Scalars['String'];
  textBody: Scalars['String'];
  language: Scalars['String'];
};

export type EmailInput = {
  issuerId: Scalars['ID'];
  certIds: Array<Scalars['ID']>;
  subject: Scalars['String'];
  textBody: Scalars['String'];
  language: Scalars['String'];
};

export type GetCertsListResponse = {
  __typename?: 'GetCertsListResponse';
  certificates: Array<Certificate>;
  pageList: Array<Maybe<Page>>;
  pageCount: Scalars['Int'];
  count: Scalars['Int'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Issuer = {
  __typename?: 'Issuer';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  sendEmail: OkResponse;
  sendEmailByGroup: OkResponse;
  sendTestEmail: OkResponse;
  registerIssuer: OkResponse;
  sendVerifyEmail: OkResponse;
};


export type MutationSendEmailArgs = {
  input: EmailInput;
};


export type MutationSendEmailByGroupArgs = {
  input: EmailByGroupInput;
};


export type MutationSendTestEmailArgs = {
  input: TestEmailInput;
};


export type MutationRegisterIssuerArgs = {
  input: RegisterIssuerInput;
};


export type MutationSendVerifyEmailArgs = {
  email: Scalars['String'];
};

export type OkResponse = {
  __typename?: 'OkResponse';
  ok: Scalars['Boolean'];
};

export type Page = {
  __typename?: 'Page';
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  issuer: Issuer;
  getCertsList: GetCertsListResponse;
  getStaffInfo: GetStaffInfoResponse;
  getGroups: GetGroupsResponse;
};


export type QueryGetCertsListArgs = {
  issuerId: Scalars['String'];
  page: Scalars['Int'];
  groupId?: Maybe<Scalars['String']>;
  sentStatus?: Maybe<Scalars['Int']>;
  holderName?: Maybe<Scalars['String']>;
  consoleID?: Maybe<Scalars['String']>;
  holderMailAddress?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  issueAtFrom?: Maybe<Scalars['Int']>;
  issueAtTo?: Maybe<Scalars['Int']>;
  deliveryAtFrom?: Maybe<Scalars['Int']>;
  deliveryAtTo?: Maybe<Scalars['Int']>;
};


export type QueryGetGroupsArgs = {
  issuerId: Scalars['String'];
};

export type RegisterIssuerInput = {
  invitationCode: Scalars['String'];
  issuerName: Scalars['String'];
  issuerEmail?: Maybe<Scalars['String']>;
  staffName: Scalars['String'];
  staffEmail: Scalars['String'];
  staffPassword: Scalars['String'];
  gasPrice?: Maybe<Scalars['Int']>;
  publicKey?: Maybe<Scalars['String']>;
  issuerLogoFile: Scalars['Upload'];
  organizationUrl: Scalars['String'];
  issuerJsonUrl: Scalars['String'];
};

export type TestEmailInput = {
  issuerId: Scalars['ID'];
  subject: Scalars['String'];
  textBody: Scalars['String'];
  language: Scalars['String'];
};


export type GetGroupsResponse = {
  __typename?: 'getGroupsResponse';
  groups: Array<Group>;
};

export type GetStaffInfoResponse = {
  __typename?: 'getStaffInfoResponse';
  userId: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  issuers: Array<Issuer>;
};

export type RegisterIssuerMutationVariables = Exact<{
  invitationCode: Scalars['String'];
  issuerName: Scalars['String'];
  issuerEmail?: Maybe<Scalars['String']>;
  staffName: Scalars['String'];
  staffEmail: Scalars['String'];
  staffPassword: Scalars['String'];
  gasPrice?: Maybe<Scalars['Int']>;
  publicKey?: Maybe<Scalars['String']>;
  issuerLogoFile: Scalars['Upload'];
  organizationUrl: Scalars['String'];
  issuerJsonUrl: Scalars['String'];
}>;


export type RegisterIssuerMutation = (
  { __typename?: 'Mutation' }
  & { registerIssuer: (
    { __typename?: 'OkResponse' }
    & Pick<OkResponse, 'ok'>
  ) }
);

export type SendEmailMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  certIds: Array<Scalars['ID']> | Scalars['ID'];
  subject: Scalars['String'];
  textBody: Scalars['String'];
  language: Scalars['String'];
}>;


export type SendEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendEmail: (
    { __typename?: 'OkResponse' }
    & Pick<OkResponse, 'ok'>
  ) }
);

export type SendEmailByGroupMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  groupId: Scalars['ID'];
  subject: Scalars['String'];
  textBody: Scalars['String'];
  language: Scalars['String'];
}>;


export type SendEmailByGroupMutation = (
  { __typename?: 'Mutation' }
  & { sendEmailByGroup: (
    { __typename?: 'OkResponse' }
    & Pick<OkResponse, 'ok'>
  ) }
);

export type SendTestEmailMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  subject: Scalars['String'];
  textBody: Scalars['String'];
  language: Scalars['String'];
}>;


export type SendTestEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendTestEmail: (
    { __typename?: 'OkResponse' }
    & Pick<OkResponse, 'ok'>
  ) }
);

export type SendVerifyEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendVerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendVerifyEmail: (
    { __typename?: 'OkResponse' }
    & Pick<OkResponse, 'ok'>
  ) }
);

export type CertificationsQueryVariables = Exact<{
  issuerId: Scalars['String'];
  page: Scalars['Int'];
  groupId?: Maybe<Scalars['String']>;
  sentStatus?: Maybe<Scalars['Int']>;
  skip: Scalars['Boolean'];
  holderName?: Maybe<Scalars['String']>;
  consoleID?: Maybe<Scalars['String']>;
  holderMailAddress?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  issueAtFrom?: Maybe<Scalars['Int']>;
  issueAtTo?: Maybe<Scalars['Int']>;
  deliveryAtFrom?: Maybe<Scalars['Int']>;
  deliveryAtTo?: Maybe<Scalars['Int']>;
}>;


export type CertificationsQuery = (
  { __typename?: 'Query' }
  & { getCertsList?: Maybe<(
    { __typename?: 'GetCertsListResponse' }
    & Pick<GetCertsListResponse, 'pageCount' | 'count'>
    & { certificates: Array<(
      { __typename?: 'Certificate' }
      & Pick<Certificate, 'id' | 'holderName' | 'consoleID' | 'holderMailAddress' | 'labels' | 'issueAt' | 'deliveryAt' | 'expireAt' | 'isRevoked'>
    )>, pageList: Array<Maybe<(
      { __typename?: 'Page' }
      & Pick<Page, 'title'>
    )>> }
  )> }
);

export type CertsCountQueryVariables = Exact<{
  issuerId: Scalars['String'];
  page: Scalars['Int'];
  groupId?: Maybe<Scalars['String']>;
  sentStatus?: Maybe<Scalars['Int']>;
}>;


export type CertsCountQuery = (
  { __typename?: 'Query' }
  & { getCertsList: (
    { __typename?: 'GetCertsListResponse' }
    & Pick<GetCertsListResponse, 'count'>
  ) }
);

export type GroupsQueryVariables = Exact<{
  issuerId: Scalars['String'];
}>;


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { getGroups: (
    { __typename?: 'getGroupsResponse' }
    & { groups: Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )> }
  ) }
);

export type StaffQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffQuery = (
  { __typename?: 'Query' }
  & { getStaffInfo: (
    { __typename?: 'getStaffInfoResponse' }
    & Pick<GetStaffInfoResponse, 'userId' | 'userName' | 'email'>
    & { issuers: Array<(
      { __typename?: 'Issuer' }
      & Pick<Issuer, 'id' | 'name'>
    )> }
  ) }
);


export const RegisterIssuerDocument = gql`
    mutation RegisterIssuer($invitationCode: String!, $issuerName: String!, $issuerEmail: String, $staffName: String!, $staffEmail: String!, $staffPassword: String!, $gasPrice: Int, $publicKey: String, $issuerLogoFile: Upload!, $organizationUrl: String!, $issuerJsonUrl: String!) {
  registerIssuer(
    input: {invitationCode: $invitationCode, issuerName: $issuerName, issuerEmail: $issuerEmail, staffName: $staffName, staffEmail: $staffEmail, staffPassword: $staffPassword, gasPrice: $gasPrice, publicKey: $publicKey, issuerLogoFile: $issuerLogoFile, organizationUrl: $organizationUrl, issuerJsonUrl: $issuerJsonUrl}
  ) {
    ok
  }
}
    `;
export type RegisterIssuerMutationFn = Apollo.MutationFunction<RegisterIssuerMutation, RegisterIssuerMutationVariables>;

/**
 * __useRegisterIssuerMutation__
 *
 * To run a mutation, you first call `useRegisterIssuerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterIssuerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerIssuerMutation, { data, loading, error }] = useRegisterIssuerMutation({
 *   variables: {
 *      invitationCode: // value for 'invitationCode'
 *      issuerName: // value for 'issuerName'
 *      issuerEmail: // value for 'issuerEmail'
 *      staffName: // value for 'staffName'
 *      staffEmail: // value for 'staffEmail'
 *      staffPassword: // value for 'staffPassword'
 *      gasPrice: // value for 'gasPrice'
 *      publicKey: // value for 'publicKey'
 *      issuerLogoFile: // value for 'issuerLogoFile'
 *      organizationUrl: // value for 'organizationUrl'
 *      issuerJsonUrl: // value for 'issuerJsonUrl'
 *   },
 * });
 */
export function useRegisterIssuerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterIssuerMutation, RegisterIssuerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterIssuerMutation, RegisterIssuerMutationVariables>(RegisterIssuerDocument, options);
      }
export type RegisterIssuerMutationHookResult = ReturnType<typeof useRegisterIssuerMutation>;
export type RegisterIssuerMutationResult = Apollo.MutationResult<RegisterIssuerMutation>;
export type RegisterIssuerMutationOptions = Apollo.BaseMutationOptions<RegisterIssuerMutation, RegisterIssuerMutationVariables>;
export const SendEmailDocument = gql`
    mutation SendEmail($issuerId: ID!, $certIds: [ID!]!, $subject: String!, $textBody: String!, $language: String!) {
  sendEmail(
    input: {issuerId: $issuerId, certIds: $certIds, subject: $subject, textBody: $textBody, language: $language}
  ) {
    ok
  }
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      certIds: // value for 'certIds'
 *      subject: // value for 'subject'
 *      textBody: // value for 'textBody'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, options);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const SendEmailByGroupDocument = gql`
    mutation SendEmailByGroup($issuerId: ID!, $groupId: ID!, $subject: String!, $textBody: String!, $language: String!) {
  sendEmailByGroup(
    input: {issuerId: $issuerId, groupId: $groupId, subject: $subject, textBody: $textBody, language: $language}
  ) {
    ok
  }
}
    `;
export type SendEmailByGroupMutationFn = Apollo.MutationFunction<SendEmailByGroupMutation, SendEmailByGroupMutationVariables>;

/**
 * __useSendEmailByGroupMutation__
 *
 * To run a mutation, you first call `useSendEmailByGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailByGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailByGroupMutation, { data, loading, error }] = useSendEmailByGroupMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      groupId: // value for 'groupId'
 *      subject: // value for 'subject'
 *      textBody: // value for 'textBody'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSendEmailByGroupMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailByGroupMutation, SendEmailByGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailByGroupMutation, SendEmailByGroupMutationVariables>(SendEmailByGroupDocument, options);
      }
export type SendEmailByGroupMutationHookResult = ReturnType<typeof useSendEmailByGroupMutation>;
export type SendEmailByGroupMutationResult = Apollo.MutationResult<SendEmailByGroupMutation>;
export type SendEmailByGroupMutationOptions = Apollo.BaseMutationOptions<SendEmailByGroupMutation, SendEmailByGroupMutationVariables>;
export const SendTestEmailDocument = gql`
    mutation SendTestEmail($issuerId: ID!, $subject: String!, $textBody: String!, $language: String!) {
  sendTestEmail(
    input: {issuerId: $issuerId, subject: $subject, textBody: $textBody, language: $language}
  ) {
    ok
  }
}
    `;
export type SendTestEmailMutationFn = Apollo.MutationFunction<SendTestEmailMutation, SendTestEmailMutationVariables>;

/**
 * __useSendTestEmailMutation__
 *
 * To run a mutation, you first call `useSendTestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTestEmailMutation, { data, loading, error }] = useSendTestEmailMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      subject: // value for 'subject'
 *      textBody: // value for 'textBody'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSendTestEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendTestEmailMutation, SendTestEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTestEmailMutation, SendTestEmailMutationVariables>(SendTestEmailDocument, options);
      }
export type SendTestEmailMutationHookResult = ReturnType<typeof useSendTestEmailMutation>;
export type SendTestEmailMutationResult = Apollo.MutationResult<SendTestEmailMutation>;
export type SendTestEmailMutationOptions = Apollo.BaseMutationOptions<SendTestEmailMutation, SendTestEmailMutationVariables>;
export const SendVerifyEmailDocument = gql`
    mutation SendVerifyEmail($email: String!) {
  sendVerifyEmail(email: $email) {
    ok
  }
}
    `;
export type SendVerifyEmailMutationFn = Apollo.MutationFunction<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>;

/**
 * __useSendVerifyEmailMutation__
 *
 * To run a mutation, you first call `useSendVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerifyEmailMutation, { data, loading, error }] = useSendVerifyEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>(SendVerifyEmailDocument, options);
      }
export type SendVerifyEmailMutationHookResult = ReturnType<typeof useSendVerifyEmailMutation>;
export type SendVerifyEmailMutationResult = Apollo.MutationResult<SendVerifyEmailMutation>;
export type SendVerifyEmailMutationOptions = Apollo.BaseMutationOptions<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>;
export const CertificationsDocument = gql`
    query Certifications($issuerId: String!, $page: Int!, $groupId: String, $sentStatus: Int, $skip: Boolean!, $holderName: String, $consoleID: String, $holderMailAddress: String, $label: String, $issueAtFrom: Int, $issueAtTo: Int, $deliveryAtFrom: Int, $deliveryAtTo: Int) {
  getCertsList(
    issuerId: $issuerId
    page: $page
    groupId: $groupId
    sentStatus: $sentStatus
    holderName: $holderName
    consoleID: $consoleID
    holderMailAddress: $holderMailAddress
    label: $label
    issueAtFrom: $issueAtFrom
    issueAtTo: $issueAtTo
    deliveryAtFrom: $deliveryAtFrom
    deliveryAtTo: $deliveryAtTo
  ) @skip(if: $skip) {
    certificates {
      id
      holderName
      consoleID
      holderMailAddress
      labels
      issueAt
      deliveryAt
      expireAt
      isRevoked
    }
    pageList {
      title
    }
    pageCount
    count
  }
}
    `;

/**
 * __useCertificationsQuery__
 *
 * To run a query within a React component, call `useCertificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificationsQuery({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      page: // value for 'page'
 *      groupId: // value for 'groupId'
 *      sentStatus: // value for 'sentStatus'
 *      skip: // value for 'skip'
 *      holderName: // value for 'holderName'
 *      consoleID: // value for 'consoleID'
 *      holderMailAddress: // value for 'holderMailAddress'
 *      label: // value for 'label'
 *      issueAtFrom: // value for 'issueAtFrom'
 *      issueAtTo: // value for 'issueAtTo'
 *      deliveryAtFrom: // value for 'deliveryAtFrom'
 *      deliveryAtTo: // value for 'deliveryAtTo'
 *   },
 * });
 */
export function useCertificationsQuery(baseOptions: Apollo.QueryHookOptions<CertificationsQuery, CertificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CertificationsQuery, CertificationsQueryVariables>(CertificationsDocument, options);
      }
export function useCertificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertificationsQuery, CertificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CertificationsQuery, CertificationsQueryVariables>(CertificationsDocument, options);
        }
export type CertificationsQueryHookResult = ReturnType<typeof useCertificationsQuery>;
export type CertificationsLazyQueryHookResult = ReturnType<typeof useCertificationsLazyQuery>;
export type CertificationsQueryResult = Apollo.QueryResult<CertificationsQuery, CertificationsQueryVariables>;
export const CertsCountDocument = gql`
    query CertsCount($issuerId: String!, $page: Int!, $groupId: String, $sentStatus: Int) {
  getCertsList(
    issuerId: $issuerId
    page: $page
    groupId: $groupId
    sentStatus: $sentStatus
  ) {
    count
  }
}
    `;

/**
 * __useCertsCountQuery__
 *
 * To run a query within a React component, call `useCertsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertsCountQuery({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      page: // value for 'page'
 *      groupId: // value for 'groupId'
 *      sentStatus: // value for 'sentStatus'
 *   },
 * });
 */
export function useCertsCountQuery(baseOptions: Apollo.QueryHookOptions<CertsCountQuery, CertsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CertsCountQuery, CertsCountQueryVariables>(CertsCountDocument, options);
      }
export function useCertsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertsCountQuery, CertsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CertsCountQuery, CertsCountQueryVariables>(CertsCountDocument, options);
        }
export type CertsCountQueryHookResult = ReturnType<typeof useCertsCountQuery>;
export type CertsCountLazyQueryHookResult = ReturnType<typeof useCertsCountLazyQuery>;
export type CertsCountQueryResult = Apollo.QueryResult<CertsCountQuery, CertsCountQueryVariables>;
export const GroupsDocument = gql`
    query Groups($issuerId: String!) {
  getGroups(issuerId: $issuerId) {
    groups {
      id
      name
    }
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const StaffDocument = gql`
    query Staff {
  getStaffInfo {
    userId
    userName
    email
    issuers {
      id
      name
    }
  }
}
    `;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffQuery(baseOptions?: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
      }
export function useStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffQueryResult = Apollo.QueryResult<StaffQuery, StaffQueryVariables>;
import React from 'react';
import { Grid } from '../../atoms/layouts/Grid';
import { ProcessTextChain } from '../process-text/ProcessTextChain';
import { GridItem } from '../../atoms/layouts/GridItem';
import { Heading } from '../../atoms/heading/Heading';
import { ProcessState } from '../process-text/ProcessText';
import { FlexItem } from '../../atoms/layouts/FlexItem';
import { useTranslation } from 'react-i18next';
import { palette } from '../../constant';

type Props = {
  processStateList: ProcessState[];
  icon: React.ReactNode;
  title: string;
};

export const ProcessChainHeader: React.FC<Props> = (props: Props) => {
  const [t] = useTranslation();

  return (
    <FlexItem>
      <Grid
        bgColor={palette.gray.light}
        height="60px"
        templateColumns="100px 280px 1fr"
        alignItems="center"
        paddingLeft="20px"
        paddingRight="20px"
      >
        {props.icon}
        <Heading as="h3" fontWeight="200">
          {t(props.title)}
        </Heading>
        <GridItem justifySelf="end">
          <ProcessTextChain processStateList={props.processStateList} />
        </GridItem>
      </Grid>
    </FlexItem>
  );
};

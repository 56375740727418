import React from 'react';
import { OneRow } from '../../molecules/grid';
import { ReactComponent as SmallCheckIcon } from '../../../assets/icons/small-check.svg';
import { Text } from '../../atoms/text/Text';
import { IsValid } from '../../../hooks/useInputValidation';
import { Translate } from '../../atoms/utils';
import { SpeechBubble } from '../../atoms/wrapper/SpeechBubble';
import { MIN_PW_LENGTH } from '../../constant';

type Props = {
  validations: IsValid;
  sameText: boolean;
  setAllValid: React.Dispatch<React.SetStateAction<boolean>>;
};

const OneRowAttrs: Omit<React.ComponentProps<typeof OneRow>, 'children'> = {
  gridTemplateColumns: '20px 1fr',
  margin: '6px 0 6px 10px',
};

const ParagraphAttrs: Omit<React.ComponentProps<typeof Text>, 'color'> = {
  fontSize: '13px',
  margin: '0',
};

const CORRECT_MESSAGE_COLOR = '#477BB0';
const ERROR_MESSAGE_COLOR = '#c1c1c1';

const getColor = (valid: boolean | undefined) =>
  valid ? CORRECT_MESSAGE_COLOR : ERROR_MESSAGE_COLOR;

export const ValidationPopUp: React.FC<Props> = (props: Props) => {
  const { min, regex } = props.validations;

  React.useEffect(() => {
    props.setAllValid(Boolean(min && regex && props.sameText));
  }, [min, regex, props]);

  return (
    <SpeechBubble borderColor="#477BB0">
      <div>
        <OneRow {...OneRowAttrs}>
          <SmallCheckIcon stroke={getColor(min)} />
          <Text {...ParagraphAttrs} color={getColor(min)} size="sm">
            <Translate>{`At least ${MIN_PW_LENGTH} characters`}</Translate>
          </Text>
        </OneRow>
        <OneRow {...OneRowAttrs}>
          <SmallCheckIcon stroke={getColor(regex)} />
          <Text {...ParagraphAttrs} color={getColor(regex)} size="sm">
            <Translate>
              least lowercase and uppercase letter and one number
            </Translate>
          </Text>
        </OneRow>
        <OneRow {...OneRowAttrs}>
          <SmallCheckIcon stroke={getColor(props.sameText && min && regex)} />
          <Text
            {...ParagraphAttrs}
            color={getColor(props.sameText && min)}
            size="sm"
          >
            <Translate>Password match</Translate>
          </Text>
        </OneRow>
      </div>
    </SpeechBubble>
  );
};

import { onError } from '@apollo/client/link/error';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { log } from '../utils/logger';
import { LoginUser } from '../core/domain/LoginUser';
import { createUploadLink } from 'apollo-upload-client'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions)
        switch (err.extensions.code) {
          case '401':
          case '403':
            break;
        }
      log('error', err.message);
    }
  }

  if (networkError) log('error', networkError);
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
  fetch: fetch,
});

const authLink = (user: LoginUser | null, JWT: string | undefined): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    if (user) {
      operation.setContext({
        headers: {
          authorization: `${JWT}`,
        },
      });
    }
    return forward(operation);
  });
};

const uploadLink = createUploadLink({ uri: `${process.env.REACT_APP_BACKEND_URL}/graphql` })

//The client will update when user credentials refresh
const ApolloClientApp = (user: LoginUser | null) => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    assumeImmutableResults: true,
    // TODO: the issue: https://github.com/apollographql/apollo-client/issues/6011
    link: ApolloLink.from([authLink(user, user?.token), errorLink, uploadLink as unknown as ApolloLink, httpLink])
  });
};

export default ApolloClientApp;

import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RoundCheck } from '../../../assets/icons/round-check.svg';
import { ReactComponent as RoundCross } from '../../../assets/icons/round-cross.svg';
import FileUploadForm from './FileUploadForm';
import FileSelectForm from './FileSelectForm';
import { palette } from '../../constant';
import { useCCBoxUploader } from './useCCBoxUploader';

type Props = {
  accept?: string;
  title: string;
  description: string;
  id: string;
  apiPath: string;
  defaultIcon: React.FunctionComponent;
  checkedIcon: React.FunctionComponent;
  successMsg: string;
  failureMsg: string;
};

const AcceptedMessageTypes: { [key: string]: string } = {
  error: 'error',
  success: 'success',
};

const Uploader: FC<Props> = (props: Props) => {
  const { file, status, onChange, onCancel, onUpload } = useCCBoxUploader({
    apiPath: props.apiPath,
    successMsg: props.successMsg,
    failureMsg: props.failureMsg,
  });
  const [t] = useTranslation();

  if (status.type === AcceptedMessageTypes[status.type]) {
    return (
      <UploaderMessage
        message={t(status.text)}
        success={Boolean(status.type === 'success')}
      />
    );
  }

  return (
    <Container>
      <h3>{t(props.title)}</h3>
      <p>{t(props.description)}</p>
      <RoundContainer>
        {file ? <props.checkedIcon /> : <props.defaultIcon />}
      </RoundContainer>

      {file ? (
        <FileUploadForm
          file={file}
          loading={status.type === 'loading'}
          handleCancel={onCancel}
          handleUpload={onUpload}
        />
      ) : (
        <FileSelectForm
          accept={props.accept}
          icon={props.defaultIcon}
          onChange={onChange}
        />
      )}
    </Container>
  );
};

type UploaderMessageProps = {
  message: string;
  success: boolean;
};

const UploaderMessage: FC<UploaderMessageProps> = (
  props: UploaderMessageProps,
) => {
  return (
    <Container>
      <h3>{props.message}</h3>
      <FailedRoundContainerWrapper>
        <RoundContainer>
          {props.success ? <RoundCheck /> : <RoundCross />}
        </RoundContainer>
      </FailedRoundContainerWrapper>
    </Container>
  );
};

export default Uploader;

const Container = styled.div`
  background-color: white;
  width: 330px;
  height: 308px;
  border-radius: 20px;
  border: 1px solid #97b7de;
  margin: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RoundContainer = styled.div`
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 1px solid ${palette.gray.light};
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FailedRoundContainerWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

import React from 'react';
import styled from 'styled-components';
import {
  BasicStyle,
  BasicStyleProps,
  FontProps,
  FontStyle,
} from '../../StyleTypes';

type Props = BasicStyleProps &
  FontProps & {
    children: React.ReactNode;
    as?: 'h1' | 'h2' | 'h3' | 'h4';
  };

const StyledH1 = styled.h1<Props>`
  font-weight: normal;
  ${BasicStyle}
  ${FontStyle}
`;

const StyledH2 = styled.h2<Props>`
  font-weight: normal;
  ${BasicStyle}
  ${FontStyle}
`;

const StyledH3 = styled.h3<Props>`
  font-weight: normal;
  ${BasicStyle}
  ${FontStyle}
`;

const StyledH4 = styled.h4<Props>`
  font-weight: normal;
  ${BasicStyle}
  ${FontStyle}
`;

export const Heading: React.FC<Props> = ({
  as = 'h1',
  children,
  ...props
}: Props) => {
  switch (as) {
    case 'h2':
      return <StyledH2 {...props}>{children}</StyledH2>;
    case 'h3':
      return <StyledH3 {...props}>{children}</StyledH3>;
    case 'h4':
      return <StyledH4 {...props}>{children}</StyledH4>;
    default:
      return <StyledH1 {...props}>{children}</StyledH1>;
  }
};

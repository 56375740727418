import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as Function } from '../../../assets/icons/function.svg';
import { Button } from '../../atoms/button/Button';
import { useAuthContext } from '../../../context/AuthContext';
import ConfigModal from '../ConfigModal';
import { useModalContext } from '../../../context/ModalContext';
import { IssuerAndStaff } from './IssuerAndStaff';
// import SearchForm from '../SearchForm';
import { ReactComponent as CCLogo } from '../../../assets/icons/CC_logo_blue.svg';

export const HEADER_HEIGHT = '60px';

const HeaderWrapper = styled.div`
  position: relative;
`;

const StyledAuthHeader = styled.div`
  height: ${HEADER_HEIGHT};
  width: 100%;
  color: white;
  background-color: #80a1c9;
  display: flex;
  justify-content: flex-end;
  // display: grid;
  // grid-template-columns: 18% ${HEADER_HEIGHT};
  // grid-template-rows: 100%;
  // align-items: center;
`;

const StyledGuestHeader = styled.div`
  height: ${HEADER_HEIGHT};
  width: 100%;
  color: white;
  background-color: #80a1c9;
  display: grid;
  grid-template-columns: 1fr ${HEADER_HEIGHT};
  grid-template-rows: 100%;
  align-items: center;
`;

const StyledLogo = styled.a`
  margin-left: 20px;
`;

const ConfigWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: ${HEADER_HEIGHT};
  right: 10px;
  color: black;
`;

const Header: FC = () => {
  const { authenticated } = useAuthContext();
  const { settingsModal, toggleSettingModal } = useModalContext();

  if (!authenticated) {
    return (
      <HeaderWrapper>
        <StyledGuestHeader>
          <StyledLogo href="/">
            <CCLogo width="160px" fill="white" />
          </StyledLogo>

          <Button
            height={HEADER_HEIGHT}
            width={HEADER_HEIGHT}
            borderRadius={'0px'}
            onClick={() => toggleSettingModal(!settingsModal)}
          >
            <Function />
          </Button>
        </StyledGuestHeader>

        {settingsModal && (
          <ConfigWrapper>
            <ConfigModal handleCloseModal={() => toggleSettingModal(false)} />
          </ConfigWrapper>
        )}
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper>
      <StyledAuthHeader>
        <IssuerAndStaff />

        <Button
          height={HEADER_HEIGHT}
          width={HEADER_HEIGHT}
          borderRadius={'0px'}
          onClick={() => toggleSettingModal(!settingsModal)}
        >
          <Function />
        </Button>
      </StyledAuthHeader>

      {settingsModal && (
        <ConfigWrapper>
          <ConfigModal handleCloseModal={() => toggleSettingModal(false)} />
        </ConfigWrapper>
      )}
    </HeaderWrapper>
  );
};

export default Header;

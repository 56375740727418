import { DateTime } from "luxon";

export function formatDateFromSeconds(
  timestamp: number,
  format: string,
  zone: string = "Asia/Tokyo",
): string | null {
  if (timestamp <= 0) return null;

  const date = DateTime.fromSeconds(timestamp, {
    zone,
  });

  return date.toFormat(format);
}

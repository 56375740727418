import React from 'react';
import SendResetEmailForm from './sendResetEmailForm/SendResetEmailForm';
import { ResetPasswordModal } from './resetPasswordModal/ResetPasswordModal';
import { useSendResetEmailForm } from './sendResetEmailForm/useSendResetEmailForm';
import { useModal } from '../../../hooks/useModal';

const ResetPasswordWithVerificationCode: React.VFC = () => {
  const {
    handleChange,
    handleSubmit,
    success,
    errMsg: sendErrMsg,
    inputValues,
  } = useSendResetEmailForm();
  const { onOpen, onClose, isOpen } = useModal();

  if (success && isOpen) {
    return (
      <ResetPasswordModal
        isOpen={isOpen}
        onClose={onClose}
        email={inputValues.email}
      />
    );
  }

  return (
    <>
      <SendResetEmailForm
        errMsg={sendErrMsg}
        handleSubmit={(e) => handleSubmit(e, onOpen)}
        handleChange={handleChange}
      />
    </>
  );
};

export default ResetPasswordWithVerificationCode;

import { useEffect, useState } from 'react';
/* Libraries */
import { log } from '../../utils/logger';
/* Contexts */
import { useAccordionContext } from '../../context/AccordionContext';
import { useIssuerContext } from '../../context/IssuerContext';
/* Types */
import {
  AccordionGroups,
  initialGroupsData,
} from '../../context/contextType/AccordionContextType';
import { useGroupsQuery } from '../../generated/types';
/* Functions */
import { generateAccordionData } from '../../utils/generateAccordionData';
import { UseGroupCerts, useGroupCerts } from '../usecase/useGroupCerts';
import { UseSendEmail, useSendEmail } from '../usecase/useSendEmail';

export type UseUnsentList = {
  groups: AccordionGroups;
  groupsLoading: boolean;
  usedGroupData: UseGroupCerts;
  usedSendEmail: UseSendEmail;
  refetch: () => Promise<void>;
  onClickGroup: (groupID: string) => void;
};

export const useUnsentList = (): UseUnsentList => {
  const [, setAccordionData] = useState<AccordionGroups>(initialGroupsData);
  const {
    groups,
    setGroups,
    changeGroupStatus,
    getOpenedGroup,
  } = useAccordionContext();
  const { issuer } = useIssuerContext();
  const { loading: groupsLoading, data, error } = useGroupsQuery({
    variables: {
      issuerId: issuer.id,
    },
  });

  const usedGroupData = useGroupCerts(getOpenedGroup());
  const usedSendEmail = useSendEmail(getOpenedGroup());

  log('info', 'groups', groups);

  if (error) log('error', 'useUnsentList', error);

  useEffect(() => {
    if (!groupsLoading && data) {
      const createdGroupsData: AccordionGroups = generateAccordionData(
        data.getGroups.groups,
      );

      setAccordionData(createdGroupsData);
      setGroups(createdGroupsData);
    }
    return () => {
      log('info', 'Groups data was setted');
    };
  }, [data, groupsLoading, setGroups]);

  // Hides the group which the group mail was sent from the unsent list when the certificates are empty
  useEffect(() => {
    if (usedGroupData.certs && !usedGroupData.certs.length) {
      const newGroups = groups.filter(
        (group) => group.groupID !== getOpenedGroup(),
      );
      setGroups(newGroups);
    }
  }, [usedGroupData.certs]);

  const onSendGroupEmail = async () => {
    await usedSendEmail.mutations.onSendGroupEmail();
  };

  const onClickGroup = (groupID: string) => {
    changeGroupStatus(groupID);
  };

  return {
    groups,
    groupsLoading,
    usedGroupData,
    usedSendEmail: {
      ...usedSendEmail,
      mutations: {
        ...usedSendEmail.mutations,
        onSendGroupEmail,
      },
    },
    refetch: usedGroupData.refetch,
    onClickGroup,
  };
};

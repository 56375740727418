import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../atoms/button/Button';
import Icon from '../../../atoms/icon/Icon';
import { ReactComponent as ArrowCloseIcon } from '../../../../assets/icons/arrow-closed.svg';
import { ReactComponent as ArrowOpenIcon } from '../../../../assets/icons/arrow-open.svg';
import { ReactComponent as FolderOpenIcon } from '../../../../assets/icons/folder.svg';
import { ReactComponent as SendAllEmailIcon } from '../../../../assets/icons/mail-all.svg';
import { ReactComponent as FolderCloseIcon } from '../../../../assets/icons/folder-closed.svg';
import { useTranslation } from 'react-i18next';
import { useAccordionContext } from '../../../../context/AccordionContext';
import { useModalContext } from '../../../../context/ModalContext';
import { palette } from '../../../constant';

type Props = {
  displayStatus: boolean;
  groupID: string;
  groupName: string;
  even: boolean;
};

type StyledAccordionHeaderProps = {
  bgColor: string;
};

type StyledIconProps = {
  width: string;
  height: string;
  margin: string;
};

type IconProps = {
  open: boolean;
};

const StyledAccordionHeader = styled.div<StyledAccordionHeaderProps>`
  width: 100%;
  display: grid;
  grid-template-rows: 36px;
  grid-template-columns: max-content 1fr;
  grid-gap: 0 70px;
  align-items: center;
  background-color: ${({ bgColor = palette.white }) => bgColor};
`;

const StyledControlBar = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const StyledButtonsGroup = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, max-content) minmax(100px, max-content);
  grid-gap: 0 14px;
`;

const StyledIcon = styled(Icon)<StyledIconProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
`;

const StyledFolderIcon = styled(Icon)`
  width: 21px;
  height: 13px;
  margin-right: 4px;
`;

type TextProps = {
  textColor: string;
};

const StyledText = styled.p<TextProps>`
  font-size: 22px;
  user-select: none;
  color: ${({ textColor = palette.white }) => textColor};
`;

const buttonProps = {
  width: 'auto',
  height: '23px',
  bgColor: palette.white,
  borderRadius: '4px',
  padding: '0 10px',
};

const ArrowIcon: React.FC<IconProps> = (props: IconProps) => (
  <StyledIcon width={'13px'} height={'11px'} margin={'10px 15px -4px 21px'}>
    {props?.open ? <ArrowOpenIcon /> : <ArrowCloseIcon />}
  </StyledIcon>
);

const FolderIcon: React.FC<IconProps> = (props: IconProps) => (
  <StyledIcon width={'17px'} height={'14px'} margin={'8px 15px -4px 0px'}>
    {props?.open ? <FolderOpenIcon /> : <FolderCloseIcon />}
  </StyledIcon>
);

const AccordionHeader: React.FC<Props> = (props: Props) => {
  const { changeGroupStatus } = useAccordionContext();
  const { toggleMailPreview, toggleTestMailPreview } = useModalContext();
  const [t] = useTranslation();

  const CLOSE = true;
  const openColor = palette.blue.heavy;
  const textColor = props.displayStatus ? palette.white : palette.blue.heavy;
  const defaultColor = props.even ? palette.white : '#F2F2F2';

  return (
    <StyledAccordionHeader
      bgColor={props.displayStatus ? openColor : defaultColor}
    >
      <StyledControlBar onClick={() => changeGroupStatus(props.groupID)}>
        <ArrowIcon open={props.displayStatus} />
        <FolderIcon open={props.displayStatus} />
        <StyledText textColor={textColor}>{props.groupName}</StyledText>
      </StyledControlBar>
      {props.displayStatus && (
        <StyledButtonsGroup>
          <Button
            {...buttonProps}
            onClick={() => toggleMailPreview(CLOSE)}
            color={palette.gray.dark}
          >
            <StyledFolderIcon>
              <SendAllEmailIcon />
            </StyledFolderIcon>
            {t('Send Everyone')}
          </Button>
          <Button
            {...buttonProps}
            onClick={() => toggleTestMailPreview(CLOSE)}
            color={palette.blue.heavy}
          >
            {t('Test Email')}
          </Button>
        </StyledButtonsGroup>
      )}
    </StyledAccordionHeader>
  );
};

export default AccordionHeader;

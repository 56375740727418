import { AuthAdapter } from "./AuthAdapter.interface"
import { CognitoAuthAdapter } from "./CognitoAuthAdapter";
import { FirebaseAuthAdapter } from "./FirebaseAuthAdapter";

let adapter: AuthAdapter;

export const authAdapterFactory = (authAdapterType?: string): AuthAdapter => {
  if (adapter) {
    return adapter;
  }

  if (authAdapterType === "AWS") {
    adapter = new CognitoAuthAdapter();
  } else {
    adapter = new FirebaseAuthAdapter();
  }
  return adapter;
}
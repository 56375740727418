import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CCLogo } from '../../../assets/icons/CC_logo_blue.svg';
import { ReactComponent as UnsentIcon } from '../../../assets/icons/unsent.svg';
// import { ReactComponent as CCUnsentIcon } from '../../../assets/icons/cc-unsent-list.svg';
import { ReactComponent as SentListIcon } from '../../../assets/icons/sent-list.svg';
import { Center } from '../../atoms/layouts/Center';
import { SidebarList } from '../../molecules/sidebar/SidebarList';
import { Link } from '../../atoms/link/StyledLink';
import { HEADER_HEIGHT } from '../header/Header';
import { palette } from '../../constant';

const StyledSidebar = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: ${HEADER_HEIGHT} 1fr;
  justify-items: center;
  border-right: 1px solid #e5e5e5;
`;

const SidebarItems = [
  { name: 'Unsent list', to: '/', icon: UnsentIcon },
  // {
  //   name: 'CertsStore Unsent list',
  //   to: '/foo',
  //   icon: CCUnsentIcon,
  // },
  { name: 'Sent list', to: '/sent-list', icon: SentListIcon },
];

export const Sidebar: React.FC = () => {
  return (
    <StyledSidebar>
      <Center borderBottom="#76a0cc 0.73px solid" width="90%">
        <Link to="/">
          <CCLogo width="220px" fill={palette.blue.heavy} />
        </Link>
      </Center>

      <SidebarList items={SidebarItems} marginTop="40px" />
    </StyledSidebar>
  );
};

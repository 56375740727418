import { QueryResult } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIssuerContext } from '../../context/IssuerContext';
import {
  Certificate,
  CertificationsQuery,
  useCertificationsQuery,
} from '../../generated/types';
import { log } from '../../utils/logger';

export type UseGroupCerts = Pick<
  QueryResult<CertificationsQuery>,
  'loading' | 'error'
> & {
  certs: Certificate[] | undefined;
  hasNext: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  fetchNext: () => Promise<void>;
  refetch: () => Promise<void>;
};

const LIMIT_ON_FETCH_ONCE = 20;

// Fetch certificates for the groupID
export const useGroupCerts = (groupID: string): UseGroupCerts => {
  const UNSET_LIST_CERTIFICATES = 2;
  const { issuer } = useIssuerContext();
  const [page, setPage] = useState(1);
  const [certs, setCerts] = useState<Certificate[] | undefined>(undefined);
  const [hasNext, setHasNext] = useState<boolean>(true);

  const getVariables = (page: number) => ({
    issuerId: issuer.id,
    page: page,
    groupId: groupID,
    sentStatus: UNSET_LIST_CERTIFICATES,
    skip: !groupID,
  });

  const { loading, error, data, fetchMore, refetch } = useCertificationsQuery({
    variables: getVariables(1),
  });

  if (error) log('error', 'useGroupCerts', error);

  // resetting state when groupID was changed
  useEffect(() => {
    refetchCerts();
  }, [groupID]);

  useEffect(() => {
    if (!loading && data && data.getCertsList) {
      setCerts(data.getCertsList?.certificates);
    }
  }, [data]);

  const fetchNext = async () => {
    const newPage = page + 1;
    if (data?.getCertsList && certs) {
      const numberAfterFetch = certs.length + LIMIT_ON_FETCH_ONCE;
      setHasNext(numberAfterFetch < data.getCertsList.count);
    }

    const { data: newDate } = await fetchMore({
      variables: getVariables(newPage),
    });

    // TODO: remove this useEffect. Use Apollo InMemoryCache instead.
    // https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
    if (certs && newDate.getCertsList) {
      setCerts([...certs, ...newDate.getCertsList.certificates]);
    }
    setPage(newPage);
  };

  const refetchCerts = async () => {
    setCerts(undefined);
    const { data } = await refetch({
      ...getVariables(1),
    });

    if (data.getCertsList) {
      setHasNext(data.getCertsList.pageList.length > page);
    }
    setPage(1);
    setCerts(data.getCertsList?.certificates);
  };

  return {
    certs,
    loading,
    error,
    hasNext,
    page,
    setPage,
    fetchNext,
    refetch: refetchCerts,
  };
};

import { css } from 'styled-components';

export const palette = {
  white: '#ffffff',
  black: '#000000',
  textBlack40: "rgba(73, 73, 73, 0.4)",
  blue: {
    light: "#76A0CC",
    base: "#5078a9",
    heavy: "#487BB1",
    heavyTransparent: "#487BB180",
  },
  paleBlue: {
    light: "#EDF2F7",
    base: "#d2e0ee",
  },
  gray: {
    light: "#f0f0f0",
    base: "#808080",
    heavy: "#A0A0A0",
    dark: "#494949",
  },
  orange: {
    base: "#FF411F",
  },
  red: {
    base: "#EB4F4F"
  }
};
export type Palette = keyof typeof palette;

export const boxShadow = {
  LEVEL1: css`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
  `,
  LEVEL2: css`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  `,
  LEVEL3: css`
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  `,
  NONE: '',
};
export type BoxShadow = keyof typeof boxShadow;

export const roundShadow = {
  LEVEL1: css`
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08), 0 0 8px rgba(0, 0, 0, 0.12);
  `,
  NONE: '',
};
export type RoundShadow = keyof typeof roundShadow;

export const formValidation = {
  regex: {
    email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
    issuerJsonUrl: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*issuer.json$)/,
    password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
  }
}

export const CODE_MIN_LENGTH = 6;
export const UPPER_LOWER_NUMBER_CASE = '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])';
export const MIN_PW_LENGTH = 8;
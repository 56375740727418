import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FileInputWithLabel } from '../../molecules/file-input/FileInputWithLabel';

const Wrapper = styled.div`
  margin-top: 10px;
`;

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  accept?: string;
  icon: React.FunctionComponent;
};

const FileSelectForm: React.FC<Props> = ({ ...rest }: Props) => {
  const [t] = useTranslation();

  return (
    <Wrapper>
      <FileInputWithLabel {...rest}>{t('Select file')}</FileInputWithLabel>
    </Wrapper>
  );
};

export default FileSelectForm;

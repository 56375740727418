import React from 'react';
import Modal from 'react-modal';
import { Flex } from '../../atoms/layouts';
import { palette } from '../../constant';
import {
  ErrorNotification,
  SuccessNotification,
} from '../../standalones/mailModal/notifications/Notification';
import { ProcessChainBody } from './Body';
import { ProcessChainFooter } from './Footer';

type ChainState = {
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
};

type Props = {
  isOpen: boolean;
  chainState: ChainState[];
  style?: ReactModal.Styles;
  onClose: () => void;
  currentIndex: number;
  notificationStatus?: 'success' | 'failure';
  email: string;
};

export const ProcessChainModal: React.FC<Props> = ({
  isOpen,
  chainState,
  onClose,
  style,
  currentIndex,
  notificationStatus,
  email,
}: Props) => {
  const customStyle: ReactModal.Styles = {
    content: {
      height: '90%',
      width: '80%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: `1px solid ${palette.blue.heavy}`,
      borderRadius: '0px',
      padding: 0,
      overflow: 'unset',
    },
    overlay: {
      background: 'rgba(255, 255, 255, 0.4)',
    },
    ...style,
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={customStyle}
        onRequestClose={onClose}
        ariaHideApp={false}
      >
        {notificationStatus === 'success' && (
          <SuccessNotification email={email} />
        )}
        {notificationStatus === 'failure' && (
          <ErrorNotification onClick={onClose} email={email} />
        )}
        <Flex
          justifyContent="center"
          flexDirection="column"
          height="100%"
          width="100%"
        >
          {chainState[currentIndex].header}
          <ProcessChainBody>{chainState[currentIndex].body}</ProcessChainBody>
          <ProcessChainFooter>
            {chainState[currentIndex].footer}
          </ProcessChainFooter>
        </Flex>
      </Modal>
    </>
  );
};

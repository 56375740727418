import React, { FC } from 'react';
import { ReactComponent as Before } from '../../../assets/icons/vector-before.svg';
import { ReactComponent as After } from '../../../assets/icons/vector-next.svg';
import { IconButton } from '../../atoms/button/IconButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { PageAction, useWindowContext } from '../../../context/WindowContext';
import { calcMaxPage, getPagenationNumber } from '../../../utils/pagination';

const StyledDiv = styled.div`
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

type Props = {
  certsNumber: number;
};

const TablePagination: FC<Props> = ({ certsNumber }: Props) => {
  const size: string = '20px';
  const [t] = useTranslation();
  const { page, setPage } = useWindowContext();
  const setNewPage = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: PageAction,
  ) => {
    e.preventDefault();
    setPage(type);
  };

  const maxPage = calcMaxPage(certsNumber);
  const { start, end } = getPagenationNumber(page, certsNumber);

  return (
    <StyledDiv>
      {`${start} - ${end} / ${certsNumber}`}
      {t('Certs')}
      <IconButton
        height={size}
        width={size}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          if (!(page === 1)) {
            setNewPage(event, { type: 'DECREMENT' });
          }
        }}
        border="none"
        bgColor="transparent"
      >
        <Before stroke={page <= 1 ? '#999999' : '#222'} />
      </IconButton>
      <IconButton
        height={size}
        width={size}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          if (!(page === maxPage)) setNewPage(event, { type: 'INCREMENT' });
        }}
        border="none"
        bgColor="transparent"
      >
        <After stroke={page >= maxPage ? '#999999' : '#222'} />
      </IconButton>
    </StyledDiv>
  );
};

export default TablePagination;

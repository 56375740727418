import React from 'react';
import { Flex } from '../../atoms/layouts';

type Props = {
  children: React.ReactNode;
};

export const ProcessChainBody: React.FC<Props> = ({ children }: Props) => {
  return (
    <Flex
      height="calc(100% - 60px - 100px)"
      width="100%"
      justifyContent="center"
    >
      {children}
    </Flex>
  );
};

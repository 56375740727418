import React from 'react';
import { log } from '../utils/logger';

type SelectedList = {
  selectedAll: boolean;
  setSelectedAll: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCerts: string[];
  setSelectedCerts: React.Dispatch<string[]>;
  removeSelectedCert: (list: string[], id: string) => string[];
  updateSelectedCerts: (
    e: React.ChangeEvent<HTMLInputElement>,
    certId: string,
  ) => void;
  removeSelectAllCerts: () => void;
  changeAllCerts: () => void;
  certsList: string[];
  setCertsList: React.Dispatch<React.SetStateAction<string[]>>;
};

const SelectedCertsContext = React.createContext<SelectedList>({
  selectedAll: false,
  setSelectedAll: () => {
    // Initial value
  },
  selectedCerts: [],
  setSelectedCerts: () => {
    // Initial value
  },
  removeSelectedCert: () => [],
  updateSelectedCerts: () => {
    // Initial value
  },
  removeSelectAllCerts: () => {
    // Initial value
  },
  changeAllCerts: () => {
    // Initial value
  },
  certsList: [],
  setCertsList: () => {
    // Initial value
  },
});

export const useSelectedCerts = (): SelectedList =>
  React.useContext(SelectedCertsContext);

type Props = {
  children: React.ReactChild;
};

const SelectedCertsProvider: React.FC<Props> = (props: Props) => {
  const [selectedAll, setSelectedAll] = React.useState<boolean>(false);
  const [selectedCerts, setSelectedCerts] = React.useState<string[]>([]);
  const [certsList, setCertsList] = React.useState<string[]>([]);

  const removeSelectedCert = (list: string[], id: string) =>
    list.filter((certId) => certId !== id);

  const removeSelectAllCerts = () => {
    setSelectedCerts([]);
    setSelectedAll(false);
  };

  const updateSelectedCerts = (
    e: React.ChangeEvent<HTMLInputElement>,
    certId: string,
  ): void => {
    const exist = selectedCerts.includes(certId);
    let updated: string[];

    if (exist) {
      updated = removeSelectedCert(selectedCerts, certId);
    } else {
      updated = [...selectedCerts, certId];
    }
    setSelectedCerts([...updated]);
  };

  const changeAllCerts = () => {
    if (selectedAll) {
      removeSelectAllCerts();
    } else {
      setSelectedCerts(certsList);
      setSelectedAll(true);
    }
  };

  React.useEffect(() => {
    if (certsList.length && selectedCerts.length === certsList.length) {
      setSelectedAll(true);
    } else {
      setSelectedAll(false);
    }
    log('info', 'selectedCerts', selectedCerts);
  }, [selectedCerts, certsList.length]);

  return (
    <SelectedCertsContext.Provider
      value={{
        selectedAll,
        setSelectedAll,
        selectedCerts,
        setSelectedCerts,
        removeSelectedCert,
        removeSelectAllCerts,
        updateSelectedCerts,
        changeAllCerts,
        certsList,
        setCertsList,
      }}
    >
      {props.children}
    </SelectedCertsContext.Provider>
  );
};

export default SelectedCertsProvider;

import { FC, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../atoms/text-input/TextInput';
import { LabeledFieldRow } from '../../molecules/labeled-field/LabeledFieldRow';
import { Button } from '../../atoms/button/Button';
import { useSearchCertsContext } from '../../../context/SearchCertsContext';
import type { GraphqlParamsType } from '../../../context/SearchCertsContext';
import type { SearchParamsType } from '../../../context/validateSearchCertSchema';
import { useWindowContext } from '../../../context/WindowContext';

const StyleInputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: start;
`;

const StyleColumnDiv = styled.div`
  margin-right: 25px;
  margin-top: 15px;
`;

const StyleDateColumnDiv = styled.div`
  margin-right: 25px;
  margin-top: 15px;
  display: flex;
`;

const StyleDateInput = styled.input`
  width: 122px;
`;

const StyleButtonDiv = styled.div`
  margin-left: 60px;
  margin-right: 25px;
  margin-top: 15px;
`;

const StyleButtonFlexDiv = styled.div`
  margin-top: 10px;
  display: flex;
`;

/**
 * 日付文字列("YYYY-MM-DD")をUNIX時間(エポック秒)に変換する
 * @param dateString 日付文字列("YYYY-MM-DD")
 * @returns
 */
const dateStringToEpoch = (dateString: string | null | undefined): number => {
  if (dateString == null || dateString == undefined || dateString == '') {
    return 0;
  }
  const date = new Date(dateString + 'T00:00:00');
  const epochSeconds = Math.floor(date.getTime() / 1000);
  return epochSeconds;
};

/**
 * 送付済リスト 検索画面
 * @returns
 */
const TableSearch: FC = () => {
  const [t] = useTranslation();
  const {
    register,
    formState,
    handleSubmit,
    setSearchParams,
    reset,
  } = useSearchCertsContext();
  const { setPage } = useWindowContext();

  const labelWidth = '100px';
  const textInputWidth = '300px';
  const textInputHeight = '30px';
  const dateInputWidth = '136px';

  // 検索ボタンクリック時の処理
  const onSearchSubmit = (data: SearchParamsType) => {
    // 検索条件をgraphQL用パラメータ変数に変換する
    const graphqlParam: GraphqlParamsType = {
      holderName: data.holderName,
      consoleID: data.consoleID,
      holderMailAddress: data.holderMailAddress,
      label: data.label,
      issueAtFrom: dateStringToEpoch(data.issueAtFrom),
      issueAtTo: dateStringToEpoch(data.issueAtTo),
      deliveryAtFrom: dateStringToEpoch(data.deliveryAtFrom),
      deliveryAtTo: dateStringToEpoch(data.deliveryAtTo),
    };
    setSearchParams(graphqlParam);
    setPage({ type: 'RESET' });
  };

  // 検索項目リセットボタンクリック時の処理
  const onSearchItemReset = (e: SyntheticEvent) => {
    e.stopPropagation();
    reset();
  };

  // リセットボタンクリック時の処理
  const onReset = (e: SyntheticEvent) => {
    e.stopPropagation();
    reset();
    setPage({ type: 'RESET' });
    setSearchParams({
      holderName: '',
      consoleID: '',
      holderMailAddress: '',
      label: '',
      issueAtFrom: 0,
      issueAtTo: 0,
      deliveryAtFrom: 0,
      deliveryAtTo: 0,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSearchSubmit)}>
      <StyleInputDiv>
        <StyleColumnDiv id="holderName">
          <LabeledFieldRow
            // 氏名
            label={t('Recipient')}
            leftWidth={labelWidth}
            rightWidth={textInputWidth}
            error={formState.errors.holderName?.message}
          >
            <TextInput height={textInputHeight} {...register('holderName')} />
          </LabeledFieldRow>
        </StyleColumnDiv>
        <StyleColumnDiv id="consoleID">
          <LabeledFieldRow
            // 識別番号
            label={t('Recipient number')}
            leftWidth={labelWidth}
            rightWidth={textInputWidth}
            error={formState.errors.consoleID?.message}
          >
            <TextInput height={textInputHeight} {...register('consoleID')} />
          </LabeledFieldRow>
        </StyleColumnDiv>
        <StyleColumnDiv id="holderMailAddress">
          <LabeledFieldRow
            // メールアドレス
            label={t('Recipient mail address')}
            leftWidth={labelWidth}
            rightWidth={textInputWidth}
            error={formState.errors.holderMailAddress?.message}
          >
            <TextInput
              height={textInputHeight}
              {...register('holderMailAddress')}
            />
          </LabeledFieldRow>
        </StyleColumnDiv>
        <StyleColumnDiv id="label">
          <LabeledFieldRow
            // ラベル
            label={t('Label')}
            leftWidth={labelWidth}
            rightWidth={textInputWidth}
            error={formState.errors.label?.message}
          >
            <TextInput height={textInputHeight} {...register('label')} />
          </LabeledFieldRow>
        </StyleColumnDiv>
        <StyleDateColumnDiv id="issueAt">
          <LabeledFieldRow
            // 管理画面登録日:FROM
            label={t('Registration date')}
            leftWidth={labelWidth}
            rightWidth={dateInputWidth}
            error={formState.errors.issueAtFrom?.message}
          >
            <StyleDateInput
              type="date"
              id="issueAtFrom"
              {...register('issueAtFrom')}
            ></StyleDateInput>
          </LabeledFieldRow>
          <LabeledFieldRow
            // 管理画面登録日:TO("～"のみ表示)
            label="&nbsp;&nbsp;～"
            leftWidth="20px"
            rightWidth="124px"
            error={formState.errors.issueAtTo?.message}
          >
            <StyleDateInput
              type="date"
              id="issueAtTo"
              {...register('issueAtTo')}
            ></StyleDateInput>
          </LabeledFieldRow>
        </StyleDateColumnDiv>

        <StyleDateColumnDiv id="deliveryAt">
          <LabeledFieldRow
            // 送付日:FROM
            label={t('Delivery date')}
            leftWidth={labelWidth}
            rightWidth={dateInputWidth}
            error={formState.errors.deliveryAtFrom?.message}
          >
            <StyleDateInput
              type="date"
              id="deliveryAtFrom"
              {...register('deliveryAtFrom')}
            ></StyleDateInput>
          </LabeledFieldRow>
          <LabeledFieldRow
            // 送付日:TO("～"のみ表示)
            label="&nbsp;&nbsp;～"
            leftWidth="20px"
            rightWidth="124px"
            error={formState.errors.deliveryAtTo?.message}
          >
            <StyleDateInput
              type="date"
              id="deliveryAtTo"
              {...register('deliveryAtTo')}
            ></StyleDateInput>
          </LabeledFieldRow>
        </StyleDateColumnDiv>
      </StyleInputDiv>

      <StyleButtonFlexDiv>
        <StyleColumnDiv id="search-button">
          <Button size="xs" borderRadius="7px" height="42px" type="submit">
            {t('Search')}
          </Button>
        </StyleColumnDiv>
        <StyleColumnDiv id="item-reset-button">
          <Button
            variant="outline"
            borderRadius="7px"
            height="42px"
            width="149px"
            type="button"
            onClick={onSearchItemReset}
          >
            {t('Search Item Reset')}
          </Button>
        </StyleColumnDiv>
        <StyleButtonDiv id="reset-button">
          <Button
            variant="outline"
            borderRadius="7px"
            height="42px"
            width="97px"
            onClick={onReset}
          >
            {t('Reset')}
          </Button>
        </StyleButtonDiv>
      </StyleButtonFlexDiv>
    </form>
  );
};

export default TableSearch;

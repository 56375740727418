import React from 'react';
import { Flex, FlexProps } from './Flex';

type Props = FlexProps;

export const Center: React.FC<Props> = ({ children, ...props }: Props) => (
  <Flex justifyContent="center" alignItems="center" width="100%" {...props}>
    {children}
  </Flex>
);

import { LoginUser } from "../../domain/LoginUser";
import { AuthAdapter } from "./AuthAdapter.interface";
import Amplify, { Auth } from "@aws-amplify/auth";

export class CognitoAuthAdapter implements AuthAdapter {
  constructor() {
    this.initialize();
  }

  initialize(): void {
    Amplify.configure({
      Auth: {
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        region: process.env.REACT_APP_REGION,
      },
    });
  }

  async authenticate(email: string, password: string): Promise<LoginUser> {
    const user = await Auth.signIn(email, password);
    const token = user.signInUserSession.idToken.jwtToken;
    const uid = user.username;

    return new LoginUser(token, uid);
  }

  async logout(): Promise<void> {
    await Auth.signOut();
  }

  async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  }

  async sendResetEmail(email: string): Promise<void> {
    await Auth.forgotPassword(email);
  }

  async resetPassword(email: string, code: string, newPassword: string): Promise<void> {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  async getCurrentUser(): Promise<LoginUser> {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const uid = user.username;
    
    return new LoginUser(token, uid);
  }
}
import React, { ComponentProps } from 'react';
import BorderBottomInput from '../../atoms/text-input/BorderBottomInput';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: relative;
`;

const StyledIcon = styled.i`
  position: absolute;
  top: 3px;
  left: 10px;
`;

type PropsType = ComponentProps<typeof BorderBottomInput> & {
  children: React.ReactNode;
};

const BorderInputWithIcon: React.FC<PropsType> = ({
  children,
  ...props
}: PropsType) => {
  return (
    <StyledDiv>
      <BorderBottomInput
        paddingLeft={'45px'}
        paddingBottom={'5px'}
        {...props}
      />
      <StyledIcon>{children}</StyledIcon>
    </StyledDiv>
  );
};

export default BorderInputWithIcon;

import React from 'react';
import styled from 'styled-components';
import { useAuthContext } from '../../context/AuthContext';
import Header from '../standalones/header/Header';
import { Sidebar } from '../standalones/sidebar/Sidebar';
import IssuerContext from '../../context/IssuerContext';
import { StyledFloatingContainer } from '../atoms/container/FloatingContainer';
import { Center } from '../atoms/layouts/Center';
import { Spinner } from '../atoms/loading/Spinner';

type Props = {
  children: React.ReactNode;
};

const StyledAuthTemplate = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
`;

const StyledGuestTemplate = styled.div``;

const Template: React.FC<Props> = (props: Props) => {
  const { authenticated, pending } = useAuthContext();

  if (!authenticated && !pending) {
    return (
      <StyledGuestTemplate>
        <Header />
        {props.children}
      </StyledGuestTemplate>
    );
  }

  if (pending) {
    return (
      <StyledFloatingContainer>
        <Center>
          <Spinner />
        </Center>
      </StyledFloatingContainer>
    );
  }

  return (
    <StyledAuthTemplate>
      <IssuerContext>
        <>
          <Sidebar />
          <div>
            <Header />
            {props.children}
          </div>
        </>
      </IssuerContext>
    </StyledAuthTemplate>
  );
};

export default Template;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIssuerContext } from '../../context/IssuerContext';
import { useQueryContext } from '../../context/QueryContext';
import {
  useSendEmailByGroupMutation,
  useSendEmailMutation,
  useSendTestEmailMutation,
} from '../../generated/types';
import { log } from '../../utils/logger';

type TestMailStatus = 'success' | 'failure' | undefined;

export type UseSendEmail = {
  testMailStatus: TestMailStatus;
  state: {
    subject: string;
    mainBody: string;
  };
  mutations: {
    onSendGroupEmail: () => Promise<void>;
    onSendSingleEmail: () => Promise<void>;
    onSendTestEmail: () => Promise<void>;
  };
  setter: {
    setSubject: React.Dispatch<React.SetStateAction<string>>;
    setMainBody: React.Dispatch<React.SetStateAction<string>>;
  };
  resetAll: () => void;
};

export const useSendEmail = (groupID?: string): UseSendEmail => {
  const [, i18n] = useTranslation();
  const currentLanguage = i18n.language;
  const { issuer } = useIssuerContext();
  const { sendEmailInfo, setSendEmailInfo } = useQueryContext();

  const [subject, setSubject] = React.useState('');
  const [mainBody, setMainBody] = React.useState('');

  const [testMailStatus, setTestMailStatus] = React.useState<TestMailStatus>(
    undefined,
  );

  const [sendMail] = useSendEmailMutation();
  const [sendEmailByGroup] = useSendEmailByGroupMutation();
  const [sendTestEmail] = useSendTestEmailMutation();

  const onSendSingleEmail = async () => {
    const { data } = await sendMail({
      variables: {
        ...sendEmailInfo,
        subject,
        textBody: mainBody,
        language: currentLanguage,
      },
    });

    if (!data?.sendEmail.ok) {
      log('error', 'Failed to send email');
      throw new Error('Failed to send email');
    } else {
      setTestMailStatus(undefined);
      log('info', 'Single email has been sent successfully');
    }
  };

  const onSendGroupEmail = async (): Promise<void> => {
    if (!groupID) {
      throw new Error('groupID is not setted');
    }

    const { data } = await sendEmailByGroup({
      variables: {
        issuerId: issuer.id,
        groupId: groupID,
        subject,
        textBody: mainBody,
        language: currentLanguage,
      },
    });

    if (!data?.sendEmailByGroup.ok) {
      log('error', 'Failed to send email');
      throw new Error('Failed to send email');
    } else {
      setTestMailStatus(undefined);
      log('info', 'Group email has been sent successfully');
    }
  };

  const onSendTestEmail = async () => {
    try {
      await sendTestEmail({
        variables: {
          issuerId: issuer.id,
          subject,
          textBody: mainBody,
          language: currentLanguage,
        },
      });

      setTestMailStatus('success');
    } catch {
      log('error', 'Failed to send test email');
      throw new Error('Failed to send test email');
    }
  };

  const resetAll = () => {
    setSubject('');
    setMainBody('');
    setTestMailStatus(undefined);
    setSendEmailInfo({ issuerId: '', certIds: [] });
  };

  return {
    testMailStatus,
    state: {
      mainBody,
      subject,
    },
    mutations: {
      onSendGroupEmail,
      onSendSingleEmail,
      onSendTestEmail,
    },
    setter: {
      setSubject,
      setMainBody,
    },
    resetAll,
  };
};

import React from 'react';
import { ProcessChainHeader } from '../../molecules/process-chain-modal/Header';
import { ReactComponent as SingleMailIcon } from '../../../assets/icons/single-mail.svg';
import { useModalContext } from '../../../context/ModalContext';
import { useMailModal } from '../../../core/presenter/useEmailModal';
import { EditMailBody } from './bodies/EditMail';
import { EditMailFooter } from './footers/EditMail';
import { ProcessChainModal } from '../../molecules/process-chain-modal/Modal';
import { PreviewMailBody } from './bodies/PreviewMail';
import { ResultBody } from './bodies/Result';
import { SinglePreviewMailFooter } from './footers/SinglePreviewMail';
import { useIssuerContext } from '../../../context/IssuerContext';
import { EmailModalProps } from './modalProps';

export const SinglePreviewModal: React.FC<EmailModalProps> = ({
  usedSendEmail,
  usedGroupCerts,
}: EmailModalProps) => {
  const { singleMailPreview, toggleSingleMailPreview } = useModalContext();
  const {
    chainState,
    currentIndex,
    mutations,
    onNext,
    onBack,
    onClose,
  } = useMailModal(
    [
      { text: 'InputText' },
      { text: 'ConfirmationSendMail' },
      { text: 'Completed' },
    ],
    toggleSingleMailPreview,
    usedSendEmail,
    usedGroupCerts,
  );
  const { staff } = useIssuerContext();

  const editMailState = {
    header: (
      <ProcessChainHeader
        title="previewModal.single.title1"
        icon={<SingleMailIcon />}
        processStateList={chainState}
      />
    ),
    body: <EditMailBody {...usedSendEmail.state} {...usedSendEmail.setter} />,
    footer: (
      <EditMailFooter
        onClose={() => toggleSingleMailPreview(false)}
        onNext={onNext}
      />
    ),
  };

  const confirmMailState = {
    header: (
      <ProcessChainHeader
        title="previewModal.single.title2"
        icon={<SingleMailIcon />}
        processStateList={chainState}
      />
    ),
    body: (
      <PreviewMailBody
        {...usedSendEmail.setter}
        {...usedSendEmail.state}
        numberOfCerts={1}
      />
    ),
    footer: (
      <SinglePreviewMailFooter
        onBack={onBack}
        onSendSingleMail={mutations.onSendSingleEmail}
        onSendTestMail={mutations.onSendTestEmailAndStay}
        email={staff.email}
      />
    ),
  };

  const resultState = {
    header: (
      <ProcessChainHeader
        title="previewModal.single.title3"
        icon={<SingleMailIcon />}
        processStateList={chainState}
      />
    ),
    body: (
      <ResultBody
        onClose={onClose}
        email={staff.email}
        success={chainState[2].status !== 'failure'}
      />
    ),
  };

  return (
    <ProcessChainModal
      notificationStatus={usedSendEmail.testMailStatus}
      isOpen={singleMailPreview}
      onClose={onClose}
      chainState={[editMailState, confirmMailState, resultState]}
      currentIndex={currentIndex}
      email={staff.email}
    />
  );
};

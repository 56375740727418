import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: string;
};

export const Translate: React.FC<Props> = ({ children }: Props) => {
  const [t] = useTranslation();

  return <>{t(children)}</>;
};

import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from './Button';
import { roundShadow, RoundShadow } from '../../constant';
import { BasicStyleProps } from '../../StyleTypes';

type Props = ButtonProps & {
  shadow?: RoundShadow;
  border?: string;
  borderWidth?: string;
};

const StyledIconButton = styled(Button)<Props>`
  ${({ shadow = 'NONE' }) => roundShadow[shadow]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconButton: FC<Props> = ({ children, ...props }: Props) => {
  const roundIconButtonStyle: BasicStyleProps = {
    height: '42px',
    width: '42px',
    borderRadius: '50%',
    bgColor: 'white',
    ...props,
  };

  return (
    <StyledIconButton {...roundIconButtonStyle}>{children}</StyledIconButton>
  );
};

import React from 'react';
import { NavLink as DefaultNavLink } from 'react-router-dom';
import styled from 'styled-components';
import './navLink.css';

import {
  BasicStyle,
  BasicStyleProps,
  TextAlignProps,
  TextAlignStyle,
} from '../../StyleTypes';
import { palette } from '../../constant';

type Props = BasicStyleProps &
  TextAlignProps &
  React.ComponentProps<typeof DefaultNavLink> & {
    children: React.ReactNode;
  };

const StyledNavLink = styled(DefaultNavLink)<Props>`
  ${BasicStyle}
  ${TextAlignStyle}
  color: ${palette.blue.heavy};
  text-decoration: none;
  height: 28px;
  display: inline-block;
  position: relative;
`;

export const NavLink: React.FC<Props> = ({ children, ...props }: Props) => {
  const navLinkStyle: Omit<Props, 'children'> = {
    width: '100%',
    ...props,
  };

  return <StyledNavLink {...navLinkStyle}>{children}</StyledNavLink>;
};

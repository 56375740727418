import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  bgColor?: string;
  height?: string;
};

const StyledTr = styled.tr<Props>`
  :first-child {
    background-color: ${({ bgColor }) => bgColor};
  }

  height: ${({ height }) => height};
`;

export const Tr: React.FC<Props> = ({ children, ...rest }: Props) => {
  return <StyledTr {...rest}>{children}</StyledTr>;
};

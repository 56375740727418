import styled from 'styled-components';
import { BasicStyle, BasicStyleProps } from '../../StyleTypes';

type Props = BasicStyleProps & {
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
};

export const TextArea = styled.textarea<Props>`
  ${BasicStyle}
  resize: none;
  padding: ${({ padding = '8px 0.75rem' }) => padding};
  height: ${({ height = '2.5rem' }) => height};
  width: ${({ width = '100%' }) => width};
  min-height: 80px;
  line-height: 1.375;
`;

import React from 'react';
import {
  AuthContext,
  AuthContextType,
  PropsType,
} from './contextType/AuthContextType';
import { log } from '../utils/logger';
import { LoginUser } from '../core/domain/LoginUser';
import { authAdapterFactory } from '../core/adapters/authentication/AuthAdapterFactory';

export const useAuthContext = (): AuthContextType =>
  React.useContext(AuthContext);

const authAdapter = authAdapterFactory(process.env.REACT_APP_AUTH_ADAPTER_TYPE);

const AuthContextProvider: React.FC<PropsType> = (props: PropsType) => {
  const [pending, setPending] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<LoginUser | null>(null);

  const authenticate = async (email: string, password: string) => {
    try {
      setPending(true);
      const user = await authAdapter.authenticate(email, password);
      setUser(user);

      log('info', 'User authenticated', user);
    } catch (err) {
      log('error', err);
    } finally {
      setPending(false);
    }
  };

  const logout = async () => {
    try {
      setPending(true);
      await authAdapter.logout();
      setUser(null);

      log('info', 'User logout');
    } catch (err) {
      log('error', err);
    } finally {
      setPending(false);
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    await authAdapter.updatePassword(oldPassword, newPassword);
  };

  React.useEffect(() => {
    (async () => {
      try {
        setPending(true);
        const user = await authAdapter.getCurrentUser();
        setUser(user);
        
        log('info', 'User credentials\n', user);
      } catch (err) {
        log('error', err);
      } finally {
        setPending(false);
      }
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        pending,
        authenticated: Boolean(user),
        user,
        authenticate,
        logout,
        changePassword,
        sendResetEmail: authAdapter.sendResetEmail,
        resetPassword: authAdapter.resetPassword,
        getCurrentUser: authAdapter.getCurrentUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

import React from 'react';
import styled from 'styled-components';
import BorderInputWithIconText from '../../molecules/text-input/BorderInputWithIconText';
import { ReactComponent as Mail } from '../../../assets/icons/mail-trans.svg';
import { ReactComponent as Key } from '../../../assets/icons/key2.svg';
import { Button } from '../../atoms/button/Button';
import { useTranslation } from 'react-i18next';
import * as H from 'history';

export const StyledForm = styled.form`
  display: grid;
  grid-template-rows: 0.3fr 1fr 1fr 1fr 0.7fr;
  height: 400px;
  width: 80%;
  padding-bottom: 10px;
`;

const RightLink = styled.a`
  color: #537bac;
  text-align: right;
  text-decoration: none;
`;

export const ErrMsg = styled.p`
  color: red;
`;

type Props = {
  errMsg: string;
  history: H.History;
  handleSubmit(event: React.SyntheticEvent): void;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
};

const LoginForm: React.FC<Props> = (props: Props) => {
  const iconSize = '20px';
  const [t] = useTranslation();

  return (
    <StyledForm>
      <ErrMsg>{t(props.errMsg)}</ErrMsg>
      <BorderInputWithIconText
        label={t('Email Address')}
        name="email"
        data-testid={'email'}
        onChange={props.handleChange}
      >
        <Mail height={iconSize} width={iconSize} />
      </BorderInputWithIconText>
      <BorderInputWithIconText
        label={t('Password')}
        name="password"
        data-testid={'password'}
        onChange={props.handleChange}
        type="password"
      >
        <Key height={iconSize} width={iconSize} />
      </BorderInputWithIconText>
      <RightLink href="/reset-password">{t('Forgot password?')}</RightLink>
      <Button
        height={'100%'}
        width={'100%'}
        shadow="LEVEL2"
        onClick={props.handleSubmit}
        borderRadius={'8px'}
      >
        {t('Sign in')}
      </Button>
    </StyledForm>
  );
};

export default LoginForm;

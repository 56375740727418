import * as yup from 'yup';
import { InferType } from 'yup';
import i18n from '../utils/i18n';

/**
 * 送付済リスト検索項目
 * 入力値とバリデートの定義
 */
export const validateCertSearchSchema = yup.object({
  /* 氏名 */
  holderName: yup.string().max(128, i18n.t('sent_search.validate.max_len_128')),
  /* 識別番号 */
  consoleID: yup.string().max(128, i18n.t('sent_search.validate.max_len_128')),
  /* メールアドレス */
  holderMailAddress: yup
    .string()
    .matches(
      /^[!-~]*$/,
      i18n.t('sent_search.validate.single_byte_alphanumeric_symbols'),
    )
    .max(256, i18n.t('sent_search.validate.max_len_256')),
  /* ラベル */
  label: yup.string().max(128, i18n.t('sent_search.validate.max_len_128')),
  /* 管理画面登録日:FROM */
  issueAtFrom: yup
    .string()
    .nullable()
    .test(
      'issueAt_enter_both',
      i18n.t('sent_search.validate.issueAt_enter_both'),
      function () {
        if (
          // FROMが入力でTOが未入力の場合
          this.parent.issueAtFrom &&
          !this.parent.issueAtTo
        ) {
          return false;
        } else {
          return true;
        }
      },
    )
    .test(
      'issueAt_reversed',
      i18n.t('sent_search.validate.issueAt_reversed'),
      function (value) {
        if (!value) return true;
        const issueAtFrom: Date = new Date(value);
        const issueAtTo: Date = new Date(this.parent.issueAtTo);
        if (!issueAtTo) return true;
        return issueAtFrom <= issueAtTo;
      },
    ),
  /* 管理画面登録日 TO */
  issueAtTo: yup
    .string()
    .nullable()
    .test(
      'issueAt_enter_both',
      i18n.t('sent_search.validate.issueAt_enter_both'),
      function () {
        if (
          // FROMが未入力でTOが入力の場合
          !this.parent.issueAtFrom &&
          this.parent.issueAtTo
        ) {
          return false;
        } else {
          return true;
        }
      },
    ),
  /* 送付日 FROM */
  deliveryAtFrom: yup
    .string()
    .nullable()
    .test(
      'deliveryAt_enter_both',
      i18n.t('sent_search.validate.deliveryAt_enter_both'),
      function () {
        if (
          // FROMが入力でTOが未入力の場合
          this.parent.deliveryAtFrom &&
          !this.parent.deliveryAtTo
        ) {
          return false;
        } else {
          return true;
        }
      },
    )
    .test(
      'deliveryAt_reversed',
      i18n.t('sent_search.validate.deliveryAt_reversed'),
      function (value) {
        if (!value) return true;
        const deliveryAtFrom: Date = new Date(value);
        const deliveryAtTo: Date = new Date(this.parent.deliveryAtTo);
        if (!deliveryAtTo) return true;
        return deliveryAtFrom <= deliveryAtTo;
      },
    ),
  /* 送付日 TO */
  deliveryAtTo: yup
    .string()
    .nullable()
    .test(
      'deliveryAt_enter_both',
      i18n.t('sent_search.validate.deliveryAt_enter_both'),
      function () {
        if (
          // FROMが未入力でTOが入力の場合
          !this.parent.deliveryAtFrom &&
          this.parent.deliveryAtTo
        ) {
          return false;
        } else {
          return true;
        }
      },
    ),
});

/**
 * 検索項目バリデーション用インターフェース
 * Yupスキーマは静的なTypeScriptインターフェースを生成する。そのインターフェースを取り出すにはInferTypeを使う。
 */
export type SearchParamsType = InferType<typeof validateCertSearchSchema>;

import { useAuthContext } from '../../context/AuthContext';
import React from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { Spinner } from '../atoms/loading/Spinner';
import { Center } from '../../styles/mixins/Center';
import { StyledFloatingContainer } from '../atoms/container/FloatingContainer';

const publicPaths = [
  '/reset-password',
  '/registration-confirm',
  '/complete-registration',
];
const isPublicPath = (path: string) =>
  publicPaths.some((publicPath) => path === publicPath);

const loginPath: string = '/login';
const isLoginPath = (path: string) => path === loginPath;

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { authenticated, pending } = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const [prevPath, setPrevPath] = React.useState<string>(
    localStorage.getItem('path') || '/',
  );

  // Save page state after application reloaded
  React.useEffect(() => {
    const currentPath = location.pathname;
    const samePathWithPrev = prevPath === currentPath;

    const shouldRedirect =
      !samePathWithPrev && authenticated && isLoginPath(currentPath);
    const shouldRedirectToHome = shouldRedirect && isPublicPath(prevPath);
    const shouldRedirectToPrev = shouldRedirect && !isPublicPath(prevPath);
    const shouldSavePrevPath = !samePathWithPrev && !isLoginPath(currentPath);

    if (shouldRedirectToHome) {
      history.push('/');
    } else if (shouldRedirectToPrev) {
      history.push(prevPath);
    } else if (shouldSavePrevPath) {
      setPrevPath(currentPath);
      localStorage.setItem('path', currentPath);
    }

    // if () {
    // }
  }, [location.pathname]);

  if (!authenticated && !pending) {
    const AuthComponent = () => <Redirect to={{ pathname: loginPath }} />;

    return <Route {...props} component={AuthComponent} />;
  }

  if (authenticated) {
    return <Route {...props} />;
  }

  const Loading = () => (
    <StyledFloatingContainer>
      <Center>
        <Spinner />
      </Center>
    </StyledFloatingContainer>
  );

  return <Route {...props} component={Loading} />;
};

export default PrivateRoute;

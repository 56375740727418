import firebase from "firebase";
import { log } from "../../../utils/logger";
import { LoginUser } from "../../domain/LoginUser";
import { AuthAdapter } from "./AuthAdapter.interface";

export class FirebaseAuthAdapter implements AuthAdapter {
  private app: firebase.app.App;

  constructor() {
    this.app = this.initialize();
  }

  initialize(): firebase.app.App {
    return firebase.initializeApp({
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    });
  }

  async authenticate(email: string, password: string): Promise<LoginUser> {
    const res = await this.app.auth().signInWithEmailAndPassword(email, password);

    if (!res.user) {
      throw new Error("user does't exists");
    }

    const token = await res.user.getIdToken();
    const uid = await res.user.uid;

    return new LoginUser(token, uid);
  }

  async logout(): Promise<void> {
    await this.app.auth().signOut();
  }

  async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    const email = this.app.auth().currentUser?.email || "";

    const oldPasswordIsCorrect = await this.authenticate(email, oldPassword);
    if (oldPasswordIsCorrect) {
      await this.app.auth().currentUser?.updatePassword(newPassword);
    } else {
      throw new Error("Your current password is incorrect");
    }
  }

  async sendResetEmail(email: string): Promise<void> {
    await firebase.auth().sendPasswordResetEmail(email);
  }

  async resetPassword(): Promise<void> {
    throw new Error("Firebase Authentication does not have resetPassword funciton");
  }

  async initFirebaseAuth(): Promise<LoginUser> {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(async (currentUser) => {
        if (currentUser) {
          const idToken = await currentUser.getIdToken();
          const uid = await currentUser.uid;
          const user = new LoginUser(idToken, uid);
          resolve(user);
        }

        reject(new Error('User not found'))
        unsubscribe();
      });
    });
  };

  async getCurrentUser(): Promise<LoginUser> {
    const user = await this.initFirebaseAuth();

    if (user) {
      log('info', 'getCurrentUser', user);
      return user;
    } else {
      log('error', 'Not signed in')
      throw new Error("Not signed in");
    }
  }
}
import styled from 'styled-components';

const ListItem = styled.div<{ root?: boolean }>`
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  ${({ root = false }) =>
    root ? 'border: 0.3px solid rgba(0, 0, 0, 0.2)' : ''};
`;

export default ListItem;

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box } from '../atoms/box';
import { Button } from '../atoms/button/Button';
import { Heading } from '../atoms/heading/Heading';
import { Flex } from '../atoms/layouts';
import { Text } from '../atoms/text/Text';

const CompleteRegistrationPage: React.VFC = () => {
  const [t] = useTranslation();

  return (
    <Flex marginTop="100px" justifyContent="center" alignItems="center">
      <Box textAlign="center">
        <Heading as="h1" size="2xl" marginBottom="20px">
          {t('complete_registration')}
        </Heading>
        <Text size="lg">{t('check_issuer_email')}</Text>
        <Text size="md">{t('you_will_receive_auth_email')}</Text>
        <Text size="md">{t('click_url')}</Text>

        <Link to="/login" style={{ textDecoration: 'none' }}>
          <Button borderRadius="4px" marginTop="30px">
            {t('move_to_login')}
          </Button>
        </Link>
      </Box>
    </Flex>
  );
};

export default CompleteRegistrationPage;

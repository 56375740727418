import React from 'react';

export type Accordion = {
  displayStatus: boolean;
  groupName: string;
  groupID: string;
  index: number;
};

export type AccordionGroups = Accordion[];

export const initialGroupsData: AccordionGroups = [];

export type AccordionContextType = {
  groups: AccordionGroups;
  setGroups: React.Dispatch<AccordionGroups>;
  getOpenedGroup: () => string;
  changeGroupStatus: React.Dispatch<string>;
};

export const AccordionContext = React.createContext<AccordionContextType>({
  groups: initialGroupsData,
  setGroups: () => {
    // set groups data
  },
  getOpenedGroup: () => '',
  changeGroupStatus: () => {
    // set new opened accordion
  },
});

export type Props = {
  children: React.ReactChild;
};

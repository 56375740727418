import React from 'react';
import {
  IssuerContext,
  IssuerContextType,
  PropsType,
} from './contextType/IssuerContextType';
import { log } from '../utils/logger';
import { StyledFloatingContainer } from '../components/atoms/container/FloatingContainer';
import { Center } from '../styles/mixins/Center';
import { Spinner } from '../components/atoms/loading/Spinner';
import { useStaffQuery } from '../generated/types';

export const useIssuerContext = (): IssuerContextType =>
  React.useContext(IssuerContext);

const IssuerContextProvider: React.FC<PropsType> = (props: PropsType) => {
  const { loading, error, data } = useStaffQuery();

  if (error) return <p>{error.message}</p>;
  if (loading)
    return (
      <StyledFloatingContainer>
        <Center>
          <Spinner />
        </Center>
      </StyledFloatingContainer>
    );

  log('info', 'Issuer data was downloaded: \n', data);

  return (
    <IssuerContext.Provider
      value={{
        issuer: {
          id: data?.getStaffInfo.issuers[0].id || '',
          name: data?.getStaffInfo.issuers[0].name || '',
        },
        staff: {
          name: data?.getStaffInfo.userName || '',
          email: data?.getStaffInfo.email || '',
        },
        pending: loading,
      }}
    >
      {props.children}
    </IssuerContext.Provider>
  );
};

export default IssuerContextProvider;

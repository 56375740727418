type LogLevelType = 'debug' | 'error' | 'info' | 'log' | 'trace' | 'warn';

const isProduction = process.env.REACT_APP_ENV === 'production';

export const log = (logLevel: LogLevelType, ...args: any): void => {
  if (isProduction) return;
  if (!process.env.REACT_APP_ENV) {
    console.warn('Logger deactivated. Need to specify environment variable process.env.REACT_APP_ENV\n\n')
    return;
  }
  console[logLevel](...args);
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../../atoms/box';
import { Grid, GridItem } from '../../atoms/layouts';
import { Line } from '../../atoms/line/Line';
import { Text } from '../../atoms/text/Text';
import { palette } from '../../constant';
import { FixedMainBody } from '../mailModal/bodies/FixedMainBody';

export type PreviewMailProps = {
  mainBody: string;
  subject: string;
};

export const PreviewMail: React.FC<PreviewMailProps> = ({
  mainBody,
  subject,
}: PreviewMailProps) => {
  const [t] = useTranslation();

  return (
    <Box
      scrollable
      height="100%"
      width="80%"
      border={`1px solid ${palette.blue.heavy}`}
    >
      <Grid padding="20px" templateRows="36px 8px 100%">
        <Grid
          templateColumns="20% 80%"
          marginLeft="120px"
          height="36px"
          paddingRight="40px"
        >
          <Text>{t('Subject')}</Text>
          <Text>{subject}</Text>
        </Grid>
        <Box>
          <Line color={palette.blue.heavy} />
        </Box>
        <Grid
          templateColumns="20% 80%"
          marginLeft="120px"
          marginTop="15px"
          paddingRight="40px"
        >
          <GridItem alignSelf="start">
            <Text>{t('MainText')}</Text>
          </GridItem>
          <Box>
            <Box
              dangerouslySetInnerHTML={{
                __html: mainBody.replace(/\r?\n/g, '<br>'),
              }}
            />
            <Box width="30%" margin="20px 0">
              <Line variants="dashed" />
            </Box>
            <Box>
              <FixedMainBody />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

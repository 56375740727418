import React, { FC } from 'react';
import TableHeader from '../molecules/table/TableHeader';
import TableRow from '../molecules/table/TableRow';
import styled from 'styled-components';
import { CenterVH } from '../../styles/mixins/Center';
import TableOperaion from '../molecules/table/TableOperation';
import { Box } from '../atoms/box';
import { useIssuerContext } from '../../context/IssuerContext';
import { Certificate } from '../../generated/types';

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr:nth-child(even) td {
    background: #f2f2f2;
  }

  tr:nth-child(odd) td {
    background: white;
  }
`;

const Center = styled.div`
  ${CenterVH};
  flex-direction: column;
  position: relative;
`;

type PropsType = {
  certificates: Certificate[];
  scrollable?: boolean;
  totalCount: number;
};

const Table: FC<PropsType> = (props: PropsType) => {
  const { issuer } = useIssuerContext();

  return (
    <Center>
      <>
        <TableOperaion certsNumber={props.totalCount} />
        <Box scrollable={props.scrollable} height="80vh">
          <StyledTable>
            <TableHeader />
            <tbody>
              {props.certificates.map((cert: Certificate, index) => (
                <TableRow key={index} cert={cert} issuerId={issuer.id} />
              ))}
            </tbody>
          </StyledTable>
        </Box>
      </>
    </Center>
  );
};

export default Table;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../../../context/AuthContext';
import { log } from '../../../../utils/logger';

type UseSendResetEmailFormReturn = {
  handleSubmit: (
    e: React.SyntheticEvent,
    onSuccess?: (() => void) | undefined,
  ) => Promise<void>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errMsg: string;
  success: boolean;
  inputValues: {
    email: string;
  };
};

export const useSendResetEmailForm = (): UseSendResetEmailFormReturn => {
  const [errMsg, setErrMsg] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const [inputValues, setInputValues] = React.useState({
    email: '',
  });
  const { sendResetEmail } = useAuthContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name: key, value } = e.target;

    setInputValues({
      ...inputValues,
      [key]: value,
    });
  };

  const handleSubmit = async (
    e: React.SyntheticEvent,
    onSuccess?: () => void,
  ) => {
    e.preventDefault();

    try {
      const { email } = inputValues;
      await sendResetEmail(email);

      setSuccess(true);
      onSuccess?.();
    } catch (error) {
      log('error', error.message);
      setErrMsg('Your inputs is incorrect. Please enter again');
    }
  };

  return {
    handleSubmit,
    handleChange,
    errMsg,
    success,
    inputValues,
  };
};

import React, {
  createContext,
  useContext,
  useState,
  SetStateAction,
  Dispatch,
  FC,
} from 'react';
import {
  FormState,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormReset,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateCertSearchSchema } from './validateSearchCertSchema';
import type { SearchParamsType } from './validateSearchCertSchema';

export type GraphqlParamsType = {
  holderName: string | undefined;
  consoleID: string | undefined;
  holderMailAddress: string | undefined;
  label: string | undefined;
  issueAtFrom: number | null;
  issueAtTo: number | null;
  deliveryAtFrom: number | null;
  deliveryAtTo: number | null;
};

type SearchCertsContextType = {
  // フォームの各種状態を検知するためのオブジェクト
  formState: FormState<SearchParamsType>;
  // フォーム要素をreact hook formの管理下に置くよう登録するための関数
  register: UseFormRegister<SearchParamsType>;
  // 引数として関数を受け取り、フォームが送信されたときにフォームデータをその関数に渡して実行する高階関数
  handleSubmit: UseFormHandleSubmit<SearchParamsType>;
  // バリデーションの結果に関わらず、手動で任意のエラーを設定できる関数
  setError: UseFormSetError<SearchParamsType>;
  // 検索項目
  searchParams: GraphqlParamsType;
  // 検索項目をセットする
  setSearchParams: Dispatch<SetStateAction<GraphqlParamsType>>;
  // フォームの状態をすべてリセットする関数
  reset: UseFormReset<SearchParamsType>;
};

// 検索項目の初期値
const initialSearchParams = {
  holderName: '',
  consoleID: '',
  holderMailAddress: '',
  label: '',
  issueAtFrom: null,
  issueAtTo: null,
  deliveryAtFrom: null,
  deliveryAtTo: null,
};

/**
 * 送付済リストの検索項目に関するContext API
 */
const SearchCertsContext = createContext<SearchCertsContextType>({
  register: () => {
    throw new Error('You probably forgot to put <SearchCertsContext>');
  },
  formState: {
    isDirty: false,
    dirtyFields: {},
    isSubmitted: false,
    isSubmitSuccessful: false,
    submitCount: 0,
    touchedFields: {},
    isSubmitting: false,
    isValidating: false,
    isValid: false,
    errors: {},
  },
  handleSubmit: () => {
    throw new Error('SearchCertsContext is not initialized');
  },
  setError: () => {
    throw new Error('SearchCertsContext is not initialized');
  },
  searchParams: initialSearchParams,
  setSearchParams: () => {
    throw new Error('SearchCertsContext is not initialized');
  },
  reset: () => {
    throw new Error('SearchCertsContext is not initialized');
  },
});

type PropsType = {
  children: React.ReactChild;
};

export const useSearchCertsContext = (): SearchCertsContextType =>
  useContext(SearchCertsContext);

const SearchCertsContextProvider: FC<PropsType> = (props) => {
  const {
    register,
    handleSubmit,
    formState,
    setError,
    reset,
  } = useForm<SearchParamsType>({
    mode: 'onSubmit',
    resolver: yupResolver(validateCertSearchSchema),
  });
  const [searchParams, setSearchParams] = useState<GraphqlParamsType>(
    initialSearchParams,
  );

  return (
    <SearchCertsContext.Provider
      value={{
        register,
        handleSubmit,
        formState,
        setError,
        searchParams,
        setSearchParams,
        reset,
      }}
    >
      {props.children}
    </SearchCertsContext.Provider>
  );
};

export default SearchCertsContextProvider;

import styled from 'styled-components';
import {
  BasicStyle,
  BasicStyleProps,
  DisplayProps,
  DisplayStyle,
  FontProps,
  FontStyle,
  TextAlignProps,
  TextAlignStyle,
} from '../../StyleTypes';

type Props = BasicStyleProps &
  TextAlignProps &
  FontProps &
  DisplayProps & {
    marginLeft?: string;
  };

export const Text = styled.p<Props>`
  ${BasicStyle}
  ${TextAlignStyle};
  ${FontStyle}
  ${DisplayStyle}
  margin-left: ${({ marginLeft }) => marginLeft};
`;

import { VFC } from 'react';
import { ReactComponent as Success } from '../../../assets/icons/check-circle-solid.svg';
import { ReactComponent as Error } from '../../../assets/icons/exclamation-circle-solid.svg';

type Props = {
  status: 'error' | 'success';
};

export const StatusIcon: VFC<Props> = ({ status }) => {
  if (status === 'success') return <Success fill="#38a169" />;

  return <Error fill="#e53e3e" />;
};

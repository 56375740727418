import React, { createContext, useState } from 'react';
import {
  FormState,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormWatch,
} from 'react-hook-form';

export type RegistrationValues = {
  authCode: string;
  issuerName: string;
  issuerConfigURL: string;
  issuerLogoFile?: FileList;
  issuerEmail: string;
  organizationURL: string;
  staffName: string;
  staffEmail: string;
  staffPassword: string;
  confStaffPassword: string;
};

export type RegistrationConfirmValues = RegistrationValues & {
  logoFileName: string;
};

type RegistrationContextType = {
  formState: FormState<RegistrationValues>;
  data: RegistrationConfirmValues;
  register: UseFormRegister<RegistrationValues>;
  watch: UseFormWatch<RegistrationValues>;
  handleSubmit: UseFormHandleSubmit<RegistrationValues>;
  setError: UseFormSetError<RegistrationValues>;
  setData: React.Dispatch<React.SetStateAction<RegistrationConfirmValues>>;
};

const initialData: RegistrationConfirmValues = {
  authCode: '',
  issuerName: '',
  issuerConfigURL: '',
  issuerEmail: '',
  organizationURL: '',
  staffName: '',
  staffEmail: '',
  staffPassword: '',
  confStaffPassword: '',
  logoFileName: '',
};

const RegistrationContext = createContext<RegistrationContextType>({
  register: () => {
    throw new Error('You probably forgot to put <RegistrationContext>');
  },
  data: initialData,
  formState: {
    isDirty: false,
    dirtyFields: {},
    isSubmitted: false,
    isSubmitSuccessful: false,
    submitCount: 0,
    touchedFields: {},
    isSubmitting: false,
    isValidating: false,
    isValid: false,
    errors: {},
  },
  watch: () => {
    throw new Error('RegistrationContext is not initialized');
  },
  handleSubmit: () => {
    throw new Error('RegistrationContext is not initialized');
  },
  setData: () => {
    throw new Error('RegistrationContext is not initialized');
  },
  setError: () => {
    throw new Error('RegistrationContext is not initialized');
  },
});

export type Props = {
  children: React.ReactChild;
};

export const useRegistrationContext = (): RegistrationContextType =>
  React.useContext(RegistrationContext);

const RegistrationContextProvider: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState,
    watch,
    setError,
  } = useForm<RegistrationValues>({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const [data, setData] = useState<RegistrationConfirmValues>(initialData);

  return (
    <RegistrationContext.Provider
      value={{
        register,
        handleSubmit,
        formState,
        watch,
        setError,
        data,
        setData,
      }}
    >
      {props.children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationContextProvider;

import React from 'react';
import {
  Accordion,
  AccordionContext,
  AccordionContextType,
  AccordionGroups,
  initialGroupsData,
  Props,
} from './contextType/AccordionContextType';

export const useAccordionContext = (): AccordionContextType =>
  React.useContext(AccordionContext);

const AccordionContextProvider: React.FC<Props> = (props: Props) => {
  const [groups, setGroups] = React.useState<AccordionGroups>(
    initialGroupsData,
  );

  const CLOSE = false;
  const OPEN = true;

  const changeDisplayStatus = (group: Accordion, status: boolean) => ({
    ...group,
    displayStatus: status,
  });

  const changeGroupStatus = (groupID: string) => {
    const updatedGroups = groups.map((group) => {
      const isClickedGroup = groupID === group.groupID;
      const currentGroupOpened = isClickedGroup && group.displayStatus;
      const currentGroupClosed = isClickedGroup && !group.displayStatus;

      if (currentGroupOpened) {
        return changeDisplayStatus(group, CLOSE);
      } else if (currentGroupClosed) {
        return changeDisplayStatus(group, OPEN);
      } else {
        return changeDisplayStatus(group, CLOSE);
      }
    });

    setGroups(updatedGroups);
  };

  const getOpenedGroup = (): string => {
    const NOTHING_FOUND = '';

    const foundGroup = groups.filter((group) => group.displayStatus)[0];
    return foundGroup?.groupID || NOTHING_FOUND;
  };

  return (
    <AccordionContext.Provider
      value={{
        groups,
        setGroups,
        getOpenedGroup,
        changeGroupStatus,
      }}
    >
      {props.children}
    </AccordionContext.Provider>
  );
};

export default AccordionContextProvider;

import React from 'react';
import { ReactComponent as BlueTag } from '../../../assets/icons/label.svg';
import styled from 'styled-components';
import { TextWithDots } from '../utils';
import ReactTooltip from 'react-tooltip';
import { palette } from '../../constant';

const StyledLabel = styled.span`
  position: relative;
`;

const StyledName = styled.span`
  position: absolute;
  top: -11px;
  left: 20px;
  display: inline-block;
  width: 100px;
  font-size: 14px;
  color: ${palette.gray.dark};
`;

type PropsType = {
  name: string;
};

export const Label: React.FC<PropsType> = (props: PropsType) => {
  return (
    <StyledLabel data-tip={props.name}>
      <BlueTag height="25px" width="140px" />
      <StyledName>
        <TextWithDots>{props.name}</TextWithDots>
      </StyledName>

      <ReactTooltip />
    </StyledLabel>
  );
};

import {
  ProcessChainProps,
  UseProcessChain,
  useProcessChain,
} from '../../hooks/useProcessChain';
import { log } from '../../utils/logger';
import { UseSendEmail } from '../usecase/useSendEmail';
import { useWindowContext } from '../../context/WindowContext';
import { UseGroupCerts } from '../usecase/useGroupCerts';

export type UseMailModal = UseProcessChain & {
  mutations: {
    onSendSingleEmail: () => Promise<void>;
    onSendGroupEmail: () => Promise<void>;
    onSendTestEmailAndNext: () => Promise<void>;
    onSendTestEmailAndStay: () => Promise<void>;
  };
  onBack: () => void;
  onDoubleBack: () => void;
  onClose: () => void;
};

// Manage Proccess Chain
export const useMailModal = (
  initialState: ProcessChainProps,
  toggleModal: React.Dispatch<boolean>,
  usedSendEmail: UseSendEmail,
  usedGroupCerts?: UseGroupCerts,
): UseMailModal => {
  const { setMailSending } = useWindowContext();
  const usedProcessChain = useProcessChain(initialState);

  const onSendSingleEmail = async (): Promise<void> => {
    try {
      setMailSending(true);
      usedSendEmail.mutations.onSendSingleEmail();
      usedProcessChain.onNext();
    } catch (err) {
      usedProcessChain.onFailureNext();
      log('error', err);
    } finally {
      setMailSending(false);
    }
  };

  const onSendGroupEmail = async (): Promise<void> => {
    try {
      usedSendEmail.mutations.onSendGroupEmail();
      usedProcessChain.onNext();
    } catch (err) {
      usedProcessChain.onFailureNext();
      log('error', err);
    }
  };

  const onSendTestEmailAndNext = async (): Promise<void> => {
    try {
      usedSendEmail.mutations.onSendTestEmail();
      usedProcessChain.onNext();
    } catch (e) {
      log('error', 'test email error sending');
    }
  };

  const onSendTestEmailAndStay = async (): Promise<void> => {
    try {
      usedSendEmail.mutations.onSendTestEmail();
    } catch (e) {
      log('error', 'test email error sending');
    }
  };

  const onBack = () => {
    usedProcessChain.onBack();
  };

  // FIXME: I cannot change index of "header chain" -> (1. Text - 2. Text)
  const onDoubleBack = () => {
    usedProcessChain.onBack();
    usedProcessChain.onBack();
  };

  const resetAll = () => {
    usedProcessChain.reset();
    usedSendEmail.resetAll();
  };

  const onClose = () => {
    resetAll();
    usedGroupCerts?.refetch();
    toggleModal(false);
  };

  return {
    ...usedProcessChain,
    onBack,
    onDoubleBack,
    mutations: {
      onSendSingleEmail,
      onSendGroupEmail,
      onSendTestEmailAndNext,
      onSendTestEmailAndStay,
    },
    onClose,
  };
};

import React from 'react';
import styled, { css } from 'styled-components';
import { CenterVH } from '../../../styles/mixins/Center';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import LoginManager from './LoginManager';
import CloudCertsLogo from '../../../assets/images/Lastrust_cloudcerts_logo_1000px.jpg';
import { palette } from '../../constant';

const BoxShadow = css`
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
`;

const StyledCard = styled.div`
  border-radius: 8px;
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  grid-template-rows: 100%;
  margin: 0 auto;
  margin-top: 30px;
  height: 600px;
  width: 70%;
  ${BoxShadow};
`;

const StyledDiv = styled.div`
  ${CenterVH}
  height: 100%;
  width: 100%;
`;

const StyledH1 = styled.h1`
  color: #3c3c3c;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  width: 80%;
  margin: 30px 0;
`;

const LineLeft = styled.hr`
  display: inline-block;
  height: 1px;
  width: 30%;
  border: none;
  border-top: 1px ${palette.black} solid;
  margin-right: 20px;
`;

const LineRight = styled.hr`
  display: inline-block;
  height: 1px;
  width: 30%;
  border: none;
  border-top: 1px ${palette.black} solid;
  margin-left: 20px;
`;

type Props = {
  history: H.History;
};

const LoginCard: React.FC<Props> = (props: Props) => {
  const [t] = useTranslation();

  return (
    <StyledCard>
      <StyledDiv>
        <img src={CloudCertsLogo} height={'80px'} alt={'logo'} />
      </StyledDiv>
      <div>
        <StyledH1>
          <LineLeft />
          {t('Sign in')}
          <LineRight />
        </StyledH1>
        <LoginManager history={props.history} />
      </div>
    </StyledCard>
  );
};

export default LoginCard;

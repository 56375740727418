import styled from 'styled-components';

type Props = {
  color?: string;
  height?: string;
  variants?: 'solid' | 'dashed';
};

export const Line = styled.hr<Props>`
  height: ${({ height = '1px' }) => height};
  border: 0;

  border-top: ${({ variants = 'solid' }) => `1px ${variants} #9a9a9a`};
`;

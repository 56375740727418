import React from 'react';
import { OneRow } from '../molecules/grid';
import { ReactComponent as HumanIcon } from '../../assets/icons/human.svg';
import { ReactComponent as KeyIcon } from '../../assets/icons/key.svg';
import { ReactComponent as GlobeIcon } from '../../assets/icons/globe.svg';
import styled from 'styled-components';
import { ChangePasswordForm } from '../standalones/settings/ChangePasswordForm';
import { Select } from '../molecules/select';
import { Heading } from '../atoms/heading/Heading';
import { useTranslation } from 'react-i18next';
import { useIssuerContext } from '../../context/IssuerContext';
import { Translate } from '../atoms/utils';
import { Box } from '../atoms/box';
import { palette } from '../constant';

const StyledSettings = styled.div`
  background-color: ${palette.gray.light};
  min-height: calc(100vh - 70px - 20px);
  padding: 10px 0 20px 0;
`;

const StyledCenter = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const ICON_HEIGHT = '24px';
const MARGIN_TOP = '40px 0 0 0';
const MARGIN_LEFT = '0 0 0 40px';

const SettingsPage: React.FC = () => {
  const [, setLang] = React.useState('ja');

  const { staff } = useIssuerContext();
  const [t, i18n] = useTranslation();
  const changeLang = (lang: string): void => {
    setLang(lang);
    i18n.changeLanguage(lang);
    window.localStorage.setItem('lang', lang);
  };

  const isCurrentLang = (lang: string) => {
    const currentLanguage = i18n.language;
    return lang === currentLanguage;
  };

  const languageItems = [
    {
      title: 'English',
      value: 'en',
      selected: isCurrentLang('en'),
    },
    {
      title: '日本語',
      value: 'ja',
      selected: isCurrentLang('ja'),
    },
  ];

  return (
    <StyledSettings>
      <StyledCenter>
        <Heading as="h2" fontWeight="400">
          <Translate>Settings</Translate>
        </Heading>

        <Box margin={'20px 0 0 0'}>
          <OneRow>
            <HumanIcon height={ICON_HEIGHT} />
            <Heading as="h3" fontWeight="300">
              <Translate>User name</Translate>
            </Heading>
          </OneRow>
          <Box margin={MARGIN_LEFT}>{staff.name}</Box>
        </Box>

        <Box margin={MARGIN_TOP}>
          <OneRow>
            <KeyIcon height={ICON_HEIGHT} />
            <Heading as="h3" fontWeight="300">
              {t('Change password')}
            </Heading>
          </OneRow>
          <Box margin={MARGIN_LEFT}>
            <ChangePasswordForm />
          </Box>
        </Box>

        <Box margin={MARGIN_TOP}>
          <OneRow>
            <GlobeIcon height={ICON_HEIGHT} />
            <Heading as="h3" fontWeight="300">
              Language/言語設定
            </Heading>
          </OneRow>
          <Box margin={MARGIN_LEFT}>
            <Select
              items={languageItems}
              onChange={(e) => changeLang(e.target.value)}
              bgColor="white"
            />
          </Box>
        </Box>
      </StyledCenter>
    </StyledSettings>
  );
};

export default SettingsPage;

const CERTS_PER_PAGE = 50;

export const getPagenationNumber = (
  currentPage: number,
  certsNumber: number,
): { start: number; end: number } => {
  const maxPage = calcMaxPage(certsNumber);
  const isLastPage = currentPage >= maxPage;

  const start = (currentPage - 1) * CERTS_PER_PAGE + 1;
  let end: number;
  if (isLastPage) {
    end = certsNumber;
  } else {
    end = start + CERTS_PER_PAGE - 1;
  }

  return {
    start,
    end,
  };
};

export const calcMaxPage = (certsNumber: number): number => {
  const calculatedMaxPage = Math.ceil(certsNumber / CERTS_PER_PAGE);
  return calculatedMaxPage === 0 ? 1 : calculatedMaxPage;
};

import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { palette } from '../../constant';
import {
  BasicStyle,
  BasicStyleProps,
  InputSystemProps,
} from '../../StyleTypes';

type Props = BasicStyleProps &
  InputSystemProps &
  InputHTMLAttributes<HTMLInputElement>;

export const StyledTextInput = styled.input<Props>`
  ${BasicStyle}
  box-sizing: border-box;
`;

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  ({ variant, ...props }, ref) => {
    const defaultStyle: BasicStyleProps = {
      height: '40px',
      width: '100%',
      paddingLeft: '8px',
      ...props,
    };

    const filledStyle: BasicStyleProps = {
      ...defaultStyle,
      border: 'none',
      bgColor: palette.paleBlue.light,
    };

    switch (variant) {
      case 'filled':
        return <StyledTextInput {...filledStyle} ref={ref} />;
      default:
        return <StyledTextInput {...defaultStyle} ref={ref} />;
    }
  },
);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../atoms/button/Button';

type Props = {
  onNext: () => void;
  onClose: () => void;
};

export const EditMailFooter: React.FC<Props> = ({ onNext, onClose }: Props) => {
  const [t] = useTranslation();

  return (
    <>
      <Button
        marginRight="50px"
        shadow="LEVEL2"
        borderRadius="4px"
        size="lg"
        onClick={onNext}
      >
        {t('Confirm')}
      </Button>
      <Button variant="outline" borderRadius="4px" size="lg" onClick={onClose}>
        {t('Cancel')}
      </Button>
    </>
  );
};

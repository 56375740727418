import { Group } from '../types/types';
import { AccordionGroups } from '../context/contextType/AccordionContextType';

export const generateAccordionData = (groups: Group[]): AccordionGroups =>
  groups.map((group, index) => ({
    index,
    displayStatus: Boolean(index === 0), //First accordion should be open
    groupID: group.id,
    groupName: group.name,
  }));

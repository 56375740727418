import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './utils/i18n';

import Context from './context';
import Apollo from './apollo';
import PrivateRoute from './components/standalones/PrivateRoute';
import Template from './components/pages/Template';
import SentListPage from './components/pages/SentListPage';
import BoxPage from './components/pages/BoxPage';
import SettingsPage from './components/pages/SettingsPage';
import UnsentListPage from './components/pages/UnsetListPage';
import LoginPage from './components/pages/LoginPage';
import ResetPasswordPage from './components/pages/ResetPasswordPage';
import RegistrationPage from './components/pages/RegistrationPage';
import RegistrationConfirmPage from './components/pages/RegistrationConfirmPage';
import CompleteRegistrationPage from './components/pages/CompleteRegistrationPage';

const App: React.FC = () => {
  return (
    <Context>
      <Apollo>
        <Router>
          <Template>
            <PrivateRoute exact component={UnsentListPage} path="/" />
            <PrivateRoute exact component={SentListPage} path="/sent-list" />
            <PrivateRoute exact component={BoxPage} path="/box" />
            <PrivateRoute exact component={SettingsPage} path="/settings" />
            <Route exact component={LoginPage} path="/login" />
            <Route exact component={RegistrationPage} path="/registration" />
            <Route
              exact
              component={CompleteRegistrationPage}
              path="/complete-registration"
            />
            <Route
              exact
              component={RegistrationConfirmPage}
              path="/registration-confirm"
            />
            <Route exact component={ResetPasswordPage} path="/reset-password" />
          </Template>
        </Router>
      </Apollo>
    </Context>
  );
};

export default App;

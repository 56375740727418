import React from 'react';
import { createContext } from 'react';
import { Issuer, Staff } from '../../types/types';

export type IssuerContextType = {
  issuer: Issuer;
  staff: Staff;
  pending: boolean;
};

export const IssuerContext = createContext<IssuerContextType>({
  issuer: {
    id: '',
    name: '',
  },
  staff: {
    name: '',
    email: '',
  },
  pending: false,
});

export type PropsType = {
  children: React.ReactChild;
};

import { Heading } from '../../../atoms/heading/Heading';
import { Flex } from '../../../atoms/layouts';
import { LabeledFieldRow } from '../../../molecules/labeled-field/LabeledFieldRow';
import { Box } from '../../../atoms/box';
import { RegistrationConfirmValues } from '../../../../context/RegistrationContext';
import { useTranslation } from 'react-i18next';

const textInputWidth = '430px';
const tip = 'form.tip.click_to_go';

type Props = {
  data: RegistrationConfirmValues;
};

export const OrganizationConfirmation: React.VFC<Props> = ({ data }: Props) => {
  const [t] = useTranslation();

  return (
    <Flex
      bgColor="white"
      width="840px"
      marginTop="68px"
      padding="25px 96px 42px 25px"
      flexDirection="column"
    >
      <Box marginBottom="32px">
        <Heading as="h2" size="lg" fontWeight="bold">
          {t('common.organization')}
        </Heading>
      </Box>

      <Flex gap="28px" flexDirection="column" alignItems="flex-end">
        <LabeledFieldRow
          label={t('form.label.issuer_name')}
          tip={t(tip)}
          rightWidth={textInputWidth}
        >
          {data.issuerName}
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.issuer_json_url')}
          rightWidth={textInputWidth}
          tip={t(tip)}
        >
          {data.issuerConfigURL}
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.issuer_logo_image')}
          tip={t(tip)}
          rightWidth={textInputWidth}
        >
          {data.logoFileName}
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.issuer_email')}
          tip={t(tip)}
          rightWidth={textInputWidth}
        >
          {data.issuerEmail}
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.organization_url')}
          tip={t(tip)}
          rightWidth={textInputWidth}
        >
          {data.organizationURL}
        </LabeledFieldRow>
      </Flex>
    </Flex>
  );
};

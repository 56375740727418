import React from 'react';

export type SelectedListType = string[];

export type IssueStateType = 'BeforeIssue' | 'AfterIssue' | 'AfterRevocation';

export type searchConditionType = {
  keywords: string[];
  issueDate: {
    from: Date;
    to: Date;
  };
  issueState: IssueStateType[];
};

export type PageAction = {
  type: 'INCREMENT' | 'DECREMENT' | 'RESET';
};

type MessageModal = {
  isOpen: boolean;
  message: string;
};

type WindowContextType = {
  selectedList: SelectedListType;
  setSelectedList: React.Dispatch<SelectedListType>;
  searchCondition: searchConditionType;
  setSearchCondition: React.Dispatch<searchConditionType>;
  page: number;
  setPage: React.Dispatch<PageAction>;
  mailSending: boolean;
  setMailSending: React.Dispatch<boolean>;
};

export const WindowContext = React.createContext<WindowContextType>({
  selectedList: [],
  setSelectedList: () => {
    // Initial value
  },
  searchCondition: {
    keywords: [],
    issueDate: {
      from: new Date(''),
      to: new Date(''),
    },
    issueState: [],
  },
  setSearchCondition: () => {
    // Initial value
  },
  page: 1,
  setPage: () => {
    // Initial value
  },
  mailSending: false,
  setMailSending: () => {
    // mail sending status
  },
});

function pageReducer(pageNum: number, action: PageAction) {
  switch (action.type) {
    case 'INCREMENT':
      return pageNum + 1;
    case 'DECREMENT':
      return pageNum - 1;
    case 'RESET':
      return 1;
    default:
      throw new Error();
  }
}

export const useWindowContext = (): WindowContextType =>
  React.useContext(WindowContext);

type PropsType = {
  children: React.ReactChild;
};

const searchConditionInit = {
  keywords: ['Hello'],
  issueDate: {
    from: new Date(''),
    to: new Date(''),
  },
  issueState: [],
};

const WindowContextProvider: React.FC<PropsType> = (props: PropsType) => {
  const [selectedList, setSelectedList] = React.useState<SelectedListType>([]);
  const [page, setPage] = React.useReducer(pageReducer, 1);
  const [mailSending, setMailSending] = React.useState<boolean>(false);

  const [
    searchCondition,
    setSearchCondition,
  ] = React.useState<searchConditionType>(searchConditionInit);

  return (
    <WindowContext.Provider
      value={{
        selectedList,
        setSelectedList,
        searchCondition,
        setSearchCondition,
        page,
        setPage,
        mailSending,
        setMailSending,
      }}
    >
      {props.children}
    </WindowContext.Provider>
  );
};

export default WindowContextProvider;

import React from 'react';
import LoginCard from '../standalones/login/LoginCard';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';

const LoginPage: React.FC = () => {
  const history = useHistory();
  const { authenticated } = useAuthContext();
  return (
    <>
      {!authenticated ? <LoginCard history={history} /> : null}
    </>
  );
};

export default LoginPage;


import styled from 'styled-components';
import { Heading } from '../../../atoms/heading/Heading';
import { Flex } from '../../../atoms/layouts';
import { LabeledFieldRow } from '../../../molecules/labeled-field/LabeledFieldRow';
import { useTranslation } from 'react-i18next';
import { TextArea } from '../../../atoms/textarea/TextArea';

const Font = styled.div`
  font-size: 14px;
`;

/**
 * 新規登録ユーザー登録画面の「個人情報の取り扱いについて」部分
 * @returns
 */
export const PersonalInformationForm: React.VFC = () => {
  const [t] = useTranslation();
  const text = t('form.personal_information.content');

  return (
    <Flex
      bgColor="white"
      width="840px"
      marginBottom="25px"
      padding="25px 25px"
      flexDirection="column"
    >
      <Heading as="h2" size="lg" fontWeight="bold">
        {t('form.personal_information.title')}
      </Heading>
      <Font>
        <TextArea
          margin="10px 0"
          width="100%"
          height="200px"
          padding="13px"
          white-space="pre-wrap"
          readOnly
          defaultValue={text}
        />
        <LabeledFieldRow
          label={t('form.personal_information.requiredMsg')}
        ></LabeledFieldRow>
      </Font>
    </Flex>
  );
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ListItem from '../../atoms/listItem/ListItem';
import { Link } from '../../atoms/link/StyledLink';

const StyledListModal = styled.div`
  width: 140px;
  text-align: center;
  background-color: white;
`;

export type Items = {
  name: string;
  list?: React.ReactChild;
  open?: boolean;
  onMouse?: () => void;
  onClick?: () => void;
  root?: boolean;
  show?: boolean;
  link?: boolean;
  to?: string;
}[];

type Props = {
  items: Items;
  onMouseLeave?: () => void;
};

const ListModal: React.FC<Props> = ({ items, onMouseLeave }: Props) => {
  const [t] = useTranslation();

  return (
    <StyledListModal onMouseLeave={onMouseLeave}>
      {items.map((item, i) => {
        const isShowList = item.show === undefined || item.show;
        if (item.link && item.to) {
          return (
            <div key={i}>
              {isShowList && (
                <ListItem
                  onMouseEnter={item.onMouse}
                  onClick={item.onClick}
                  key={i}
                  root={item.root}
                >
                  <Link to={item.to} height="100%" width="100%">
                    {t(item.name)}
                  </Link>
                </ListItem>
              )}
              {item.open && item.list}
            </div>
          );
        }

        return (
          <div key={i}>
            {isShowList && (
              <ListItem
                onMouseEnter={item.onMouse}
                onClick={item.onClick}
                key={i}
                root={item.root}
              >
                {t(item.name)}
              </ListItem>
            )}
            {item.open && item.list}
          </div>
        );
      })}
    </StyledListModal>
  );
};

export default ListModal;

import React from 'react';
import AccordionHeader from './Header';
import { AccordionContent } from './Content';
import { UseGroupCerts } from '../../../../core/usecase/useGroupCerts';
import { Accordion as AccordionType } from '../../../../context/contextType/AccordionContextType';

type Props = UseGroupCerts & AccordionType;

const Accordion: React.FC<Props> = ({
  displayStatus,
  groupName,
  groupID,
  index,
  ...groupData
}: Props): React.ReactElement => {
  const even = Boolean(index % 2);

  return (
    <>
      <AccordionHeader
        even={even}
        displayStatus={displayStatus}
        groupName={groupName}
        groupID={groupID}
      />
      {displayStatus && <AccordionContent groupID={groupID} {...groupData} />}
    </>
  );
};

export default Accordion;

import React from 'react';
import { Button } from '../../../atoms/button/Button';
import { Flex } from '../../../atoms/layouts';
import { TextWithDots } from '../../../atoms/utils';
import { Text } from '../../../atoms/text/Text';
import { useTranslation } from 'react-i18next';
import { palette } from '../../../constant';

type Props = {
  onSendTestMail: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  onBack: () => void;
  email: string;
};

export const PreviewTestMailFooter: React.FC<Props> = ({
  onSendTestMail,
  onBack,
  email,
}: Props) => {
  const [t] = useTranslation();

  return (
    <>
      <Button
        marginRight="10px"
        variant="outline"
        borderRadius="4px"
        size="lg"
        width="220px"
        padding="0 10px"
        shadow="LEVEL2"
        onClick={onSendTestMail}
      >
        <Flex flexDirection="column">
          <Text color={palette.gray.dark}>{t('SendTestMail')}</Text>
          <Flex color={palette.gray.base}>
            <Text marginRight="3px">To:</Text>
            <TextWithDots>{email}</TextWithDots>
          </Flex>
        </Flex>
      </Button>
      <Button
        marginRight="10px"
        borderRadius="4px"
        size="lg"
        shadow="LEVEL2"
        disabled={true}
        disabledColor="#B7D1E5"
      >
        {t('SendMassMail')}
      </Button>
      <Button
        marginRight="10px"
        variant="ghost"
        borderRadius="4px"
        size="lg"
        onClick={onBack}
      >
        {t('Edit')}
      </Button>
    </>
  );
};

import { useEffect } from "react";

export const useWarnBeforeReload = (): void => {
  const onUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    }
  })

}
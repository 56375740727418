import React from 'react';
import styled from 'styled-components';
import {
  BasicStyle,
  BasicStyleProps,
  FlexStyleProps,
  FlexStyle,
} from '../../StyleTypes';

export type FlexProps = BasicStyleProps &
  FlexStyleProps & {
    children: React.ReactNode;
  };

const StyledFlex = styled.div<FlexProps>`
  display: flex;

  ${BasicStyle}
  ${FlexStyle}
`;

export const Flex: React.FC<FlexProps> = ({
  children,
  ...props
}: FlexProps) => {
  return <StyledFlex {...props}>{children}</StyledFlex>;
};

import React from 'react';
import { Flex } from '../../../atoms/layouts';
import { PreviewMail, PreviewMailProps } from '../../previewMail/previewMail';

export const PreviewTestMailBody: React.FC<PreviewMailProps> = (
  props: PreviewMailProps,
) => {
  return (
    <Flex
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Flex height="90%" width="100%" justifyContent="center">
        <PreviewMail {...props} />
      </Flex>
    </Flex>
  );
};

import React from 'react';
import { BoxShadow, boxShadow, palette } from '../../constant';
import styled from 'styled-components';
import {
  BasicStyle,
  BasicStyleProps,
  ButtonTypeProps,
  DisableProps,
} from '../../StyleTypes';
import { Spinner } from '../loading/Spinner';
import { Box } from '../box';

export const buttonSize = {
  xs: { H: '2rem', W: '4rem' },
  sm: { H: '2.5rem', W: '8rem' },
  md: { H: '3rem', W: '10rem' },
  lg: { H: '3.5rem', W: '12rem' },
};

export type ButtonProps = BasicStyleProps &
  ButtonTypeProps &
  DisableProps & {
    children?: React.ReactNode;
    size?: keyof typeof buttonSize;
    shadow?: BoxShadow;
    variant?: 'outline' | 'solid' | 'ghost';
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
  };

export const StyledButton = styled.button<ButtonProps>`
  ${BasicStyle}

  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${({ size = 'md' }) => buttonSize[size]['H']};
  width: ${({ size = 'md' }) => buttonSize[size]['W']};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-color: ${({ bgColor, disabled, disabledColor }) =>
    disabled ? disabledColor : bgColor};
  ${({ shadow = 'NONE' }) => boxShadow[shadow]};
  opacity: ${({ disabled }) => disabled && '0.4'};
`;

export const StyledHoverAnimateButton = styled(StyledButton)`
  :hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

export const Button: React.FC<ButtonProps> = ({
  children,
  variant,
  loading,
  ...props
}: ButtonProps) => {
  const buttonStyle: ButtonProps = {
    border: 'none',
    padding: '0',
    cursor: 'pointer',
    color: palette.white,
    bgColor: palette.blue.heavy,
    disabled: loading,
    ...props,
  };

  const outlineButtonStyle: BasicStyleProps = {
    ...buttonStyle,
    border: `1px solid ${palette.blue.heavy}`,
    bgColor: 'transparent',
    color: palette.blue.heavy,
  };

  const ghostButtonStyle: BasicStyleProps = {
    ...buttonStyle,
    bgColor: 'transparent',
    color: palette.blue.heavy,
  };

  switch (variant) {
    case 'outline':
      return (
        <StyledHoverAnimateButton {...outlineButtonStyle}>
          {children}
        </StyledHoverAnimateButton>
      );
    case 'ghost':
      return (
        <StyledHoverAnimateButton {...ghostButtonStyle}>
          {children}
        </StyledHoverAnimateButton>
      );
    default:
      return (
        <StyledButton {...buttonStyle}>
          {loading && (
            <Box marginRight="20px">
              <Spinner
                size="22px"
                color="white"
                bgColor={palette.blue.heavy}
                speed="fast"
              />
            </Box>
          )}
          {children}
        </StyledButton>
      );
  }
};

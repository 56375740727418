import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

type Props = InputHTMLAttributes<HTMLInputElement>;

const Input = styled.input<Props>`
  display: none;
`;

const FileInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <Input type="file" {...props} ref={ref} />;
});

export default FileInput;

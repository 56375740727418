import React from 'react';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  BasicStyle,
  BasicStyleProps,
  TextAlignProps,
  TextAlignStyle,
} from '../../StyleTypes';

type Props = BasicStyleProps &
  TextAlignProps &
  React.ComponentProps<typeof DefaultLink> &
  React.RefAttributes<HTMLAnchorElement> & {
    children: React.ReactNode;
  };

const StyledLink = styled(DefaultLink)<Props>`
  ${BasicStyle}
  ${TextAlignStyle}
  display: inline-block;
  text-decoration: none;
  color: ${({ color = 'black' }) => color};
`;

export const Link: React.FC<Props> = ({ children, ...props }: Props) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};

import React from 'react';
import styled from 'styled-components';
import { useIssuerContext } from '../../../context/IssuerContext';
import IconAndNamePare from '../../molecules/header/IconAndNamePare';
import BilldingIcon from '../../../assets/icons/billding2.svg';
import HumanIcon from '../../../assets/icons/human2.svg';

const StyledIssuerAndStaff = styled.div`
  height: 100%;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
`;

export const IssuerAndStaff: React.FC = () => {
  const { issuer, staff } = useIssuerContext();

  return (
    <StyledIssuerAndStaff>
      <IconAndNamePare text={issuer.name} path={BilldingIcon} />
      <IconAndNamePare text={staff.name} path={HumanIcon} />
    </StyledIssuerAndStaff>
  );
};

import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text/Text';
import { palette } from '../../constant';

export const PROCESS = 'process';
export const FINISHED = 'finished';
export const UNFINISHED = 'unfinished';
export const FAILURE = 'failure';

type Status =
  | typeof PROCESS
  | typeof FINISHED
  | typeof UNFINISHED
  | typeof FAILURE;

export type ProcessState = {
  text: string;
  status: Status;
  number: number;
};

type Props = ProcessState & ComponentProps<typeof Text>;

const StyledProcessNumber = styled(Text)<{ status: Status }>`
  color: ${({ status }) => {
    switch (status) {
      case UNFINISHED:
        return palette.blue.heavyTransparent;
      case FAILURE:
        return palette.orange.base;
      default:
        return palette.blue.heavy;
    }
  }};
  border-bottom: ${({ status }) =>
    status === 'process' ? `3px solid ${palette.blue.heavy}` : null};
`;

const StyledProcessText = styled(Text)<{ status: Status }>`
  color: ${({ status }) => {
    switch (status) {
      case UNFINISHED:
        return palette.blue['heavyTransparent'];
      case FAILURE:
        return palette.orange.base;
      case FINISHED:
        return palette.blue.heavy;
      default:
        return palette.black;
    }
  }};
`;

export const ProcessText: React.FC<Props> = ({
  text,
  number,
  fontSize = 'sm',
  ...props
}: Props) => {
  const [t] = useTranslation();

  return (
    <Box>
      <StyledProcessNumber
        fontWeight="bold"
        size={fontSize}
        display="inline-block"
        marginRight="8px"
        {...props}
      >
        0{number}
      </StyledProcessNumber>
      <StyledProcessText size={fontSize} display="inline-block" {...props}>
        {t(text)}
      </StyledProcessText>
    </Box>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  RegistrationConfirmValues,
  RegistrationValues,
  useRegistrationContext,
} from '../../../context/RegistrationContext';
import { useWarnBeforeReload } from '../../../hooks/useBeforeReload';
import { log } from '../../../utils/logger';
import { Box } from '../../atoms/box';
import { Button } from '../../atoms/button/Button';
import { Heading } from '../../atoms/heading/Heading';
import { Flex } from '../../atoms/layouts';
import { TextInput } from '../../atoms/text-input/TextInput';
import { palette } from '../../constant';
import { LabeledFieldRow } from '../../molecules/labeled-field/LabeledFieldRow';
import { AdminForm } from './admin-form/AdminForm';
import { OrganizationForm } from './organization-form/OrganizationForm';
import { PersonalInformationForm } from './personal-information-form/PersonalInformationForm';

const RegistrationPage: React.VFC = () => {
  const [t] = useTranslation();
  const {
    register,
    formState,
    handleSubmit,
    watch,
    setData,
  } = useRegistrationContext();
  const history = useHistory();
  const [fileName, setFileName] = useState('');

  useWarnBeforeReload();

  const onSubmit = (data: RegistrationValues) => {
    log('info', data);

    const confirmValues: RegistrationConfirmValues = {
      ...data,
      logoFileName: fileName,
    };
    setData(confirmValues);
    history.push('/registration-confirm');
  };

  return (
    <Box
      bgColor="#F4F4F4"
      minHeight="100vh"
      paddingTop="44px"
      paddingBottom="64px"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex alignItems="center" flexDirection="column">
          <Heading size="xl" as="h1">
            {t('form.title.registration')}
          </Heading>

          <Box width="540px" marginTop="48px">
            <LabeledFieldRow
              label={t('form.label.auth_code')}
              error={formState.errors.authCode?.message}
            >
              <TextInput
                {...register('authCode', {
                  required: `${t('form.error.required')}`,
                })}
              />
            </LabeledFieldRow>
          </Box>

          <OrganizationForm
            register={register}
            formState={formState}
            setFileName={setFileName}
            fileName={fileName}
          />
          <AdminForm register={register} formState={formState} watch={watch} />

          <PersonalInformationForm />
          <div
            data-tip={!formState.isValid ? t('form.tip.all_required') : ''}
            id="button"
          >
            <Button
              borderRadius="8px"
              size="md"
              type="submit"
              disabled={!formState.isValid}
              disabledColor={palette.textBlack40}
            >
              {t('common.confirmation')}
            </Button>

            {!formState.isValid && <ReactTooltip id="button" />}
          </div>
        </Flex>
      </form>
    </Box>
  );
};

export default RegistrationPage;

import React from 'react';
import { Flex } from '../../atoms/layouts';

type Props = {
  children: React.ReactNode;
};

export const ProcessChainFooter: React.FC<Props> = ({ children }: Props) => {
  return (
    <Flex
      height="100px"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Flex justifyContent="space-around" width="fit-content">
        {children}
      </Flex>
    </Flex>
  );
};

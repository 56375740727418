import { useState } from 'react';
import {
  FAILURE,
  FINISHED,
  PROCESS,
  ProcessState,
  UNFINISHED,
} from '../components/molecules/process-text/ProcessText';

export type ProcessChainProps = Pick<ProcessState, 'text'>[];

export type UseProcessChain = {
  chainState: ProcessState[];
  currentIndex: number;
  onNext: () => void;
  onBack: () => void;
  onFailure: () => void;
  onFailureNext: () => void;
  reset: () => void;
};

const createProcessChainState = (state: ProcessChainProps): ProcessState[] => {
  return state.map((state, i) => {
    return {
      text: state.text,
      number: i + 1,
      status: i === 0 ? 'process' : 'unfinished',
    };
  });
};

export const useProcessChain = (
  initialState: ProcessChainProps,
): UseProcessChain => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [chainState, setChainState] = useState<ProcessState[]>([
    ...createProcessChainState(initialState),
  ]);
  const upperIndex = initialState.length - 1;

  const reset = () => {
    setChainState([...createProcessChainState(initialState)]);
    setCurrentIndex(0);
  };

  const onNext = () => {
    if (currentIndex === upperIndex) {
      reset();
      return;
    }
    const newChainState = chainState;
    newChainState[currentIndex].status = FINISHED;
    newChainState[currentIndex + 1].status = PROCESS;
    setChainState([...newChainState]);
    setCurrentIndex((state) => state + 1);
  };

  const onBack = () => {
    if (currentIndex === 0) {
      return;
    }
    const newChainState = chainState;
    newChainState[currentIndex].status = UNFINISHED;
    newChainState[currentIndex - 1].status = PROCESS;
    setChainState([...newChainState]);
    setCurrentIndex((state) => state - 1);
  };

  const onFailure = () => {
    const newChainState = chainState;
    newChainState[currentIndex].status = FAILURE;
    setChainState([...newChainState]);
  };

  const onFailureNext = () => {
    onNext();
    const newChainState = chainState;
    newChainState[currentIndex].status = FAILURE;
    setChainState([...newChainState]);
  };

  return {
    chainState,
    currentIndex,
    onNext,
    onBack,
    onFailure,
    onFailureNext,
    reset,
  };
};

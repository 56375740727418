import React from 'react';
import { Flex } from '../../../atoms/layouts';
import { Text } from '../../../atoms/text/Text';
import { ReactComponent as TestMailIcon } from '../../../../assets/icons/test-mail.svg';
import { ReactComponent as TestMailErrorIcon } from '../../../../assets/icons/test-mail-error.svg';
import { Box } from '../../../atoms/box';
import styled from 'styled-components';
import { Button } from '../../../atoms/button/Button';
import { useTranslation } from 'react-i18next';
import { palette } from '../../../constant';

const StyledNotification = styled(Flex)`
  position: absolute;
  left: -60px;
  top: -2px;
  width: 110%;
  height: 60px;
  align-items: center;
`;

type ErrorNotificationProps = {
  onClick?: () => void;
  email: string;
};

export const SuccessNotification: React.FC<ErrorNotificationProps> = ({
  email,
}: ErrorNotificationProps) => {
  const [t, i18n] = useTranslation();
  return (
    <StyledNotification
      border={`1px solid ${palette.blue.heavy}`}
      bgColor="#E3ECF4"
    >
      <Box margin="0 20px">
        <TestMailIcon />
      </Box>

      {i18n.language === 'en' ? (
        <>
          <Text marginRight="4px" marginLeft="8px">
            {t('WeSentTestMail')}
          </Text>
          <Text fontWeight="bold" color={palette.blue.heavy}>
            {email}
          </Text>
        </>
      ) : (
        <>
          <Text fontWeight="bold" color={palette.blue.heavy}>
            {email}
          </Text>
          <Text marginRight="4px" marginLeft="8px">
            {t('WeSentTestMail')}
          </Text>
        </>
      )}
      <Text>. {t('SuccessTestMailText')}</Text>
    </StyledNotification>
  );
};

export const ErrorNotification: React.FC<ErrorNotificationProps> = ({
  onClick,
}: ErrorNotificationProps) => {
  const [t] = useTranslation();
  return (
    <StyledNotification bgColor="#FF411F" justifyContent="space-around">
      <Flex alignItems="center">
        <TestMailErrorIcon height="60px" width="60px" />
        <Text color="white" marginLeft="20px">
          {t('FailedToSendTestMail')}
        </Text>
      </Flex>

      <Button
        bgColor="white"
        color="#4D4D4D"
        width="97px"
        height="24px"
        borderRadius="2px"
        onClick={onClick}
      >
        {t('Cancel')}
      </Button>
    </StyledNotification>
  );
};

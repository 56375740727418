import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRegistrationContext } from '../../../context/RegistrationContext';
import { useWarnBeforeReload } from '../../../hooks/useBeforeReload';
import { log } from '../../../utils/logger';
import { Box } from '../../atoms/box';
import { Button } from '../../atoms/button/Button';
import { Heading } from '../../atoms/heading/Heading';
import { Flex } from '../../atoms/layouts';
import { LabeledFieldRow } from '../../molecules/labeled-field/LabeledFieldRow';
import { AdminConfirmation } from './admin-confirmation/AdminConfirmation';
import { OrganizationConfirmation } from './organization-confirmation/OrganizationConfirmation';
import { PersonalInformationConfirmation } from './personal-information-confirmation/personalInformationConfirmation';
import {
  useRegisterIssuerMutation,
  useSendVerifyEmailMutation,
} from '../../../generated/types';
import { ApolloError } from '@apollo/client';

const RegistrationConfirmPage: React.FC = () => {
  const [t] = useTranslation();
  const { data, setError } = useRegistrationContext();
  const history = useHistory();
  const [
    registerIssuer,
    { loading: registerLoading },
  ] = useRegisterIssuerMutation();
  const [
    sendVerifyEmail,
    { loading: verifyLoading },
  ] = useSendVerifyEmailMutation();

  useWarnBeforeReload();

  const onClickEdit = () => {
    history.push('/registration');
  };

  const onClickRegistration = async () => {
    log('info', data);

    try {
      const { data: response } = await registerIssuer({
        variables: {
          invitationCode: data.authCode,
          issuerName: data.issuerName,
          issuerEmail: data.issuerEmail,
          staffName: data.staffName,
          staffEmail: data.staffEmail,
          staffPassword: data.staffPassword,
          organizationUrl: data.organizationURL,
          issuerJsonUrl: data.issuerConfigURL,
          issuerLogoFile: data.issuerLogoFile?.[0],
        },
      });
      if (!response?.registerIssuer.ok) {
        log('error', 'Failed to register');
        throw new Error('Failed to register');
      }

      await sendVerifyEmail({
        variables: {
          email: data.issuerEmail,
        },
      });

      history.push('/complete-registration');
    } catch (err) {
      log('error', err);
      log('info', err.graphQLErrors[0].extensions?.code);
      if (err instanceof ApolloError) {
        switch (err.graphQLErrors[0].extensions?.code) {
          case 'INVALID_INVITATION_CODE':
            setError('authCode', {
              message: t('form.error.invalid_auth_code'),
            });
            break;
          case 'ALREADY_EXISTS_EMAIL':
            setError('staffEmail', {
              message: t('form.error.already_exists_email'),
            });
            break;
        }
      }

      history.push('/registration');
    }
  };

  return (
    <Box
      bgColor="#F4F4F4"
      minHeight="100vh"
      paddingTop="44px"
      paddingBottom="64px"
    >
      <Flex alignItems="center" flexDirection="column">
        <Heading size="xl" as="h1">
          {t('form.title.registration')}
        </Heading>

        <Box marginTop="48px">
          <LabeledFieldRow label={t('form.label.auth_code')}>
            {data.authCode}
          </LabeledFieldRow>
        </Box>

        <OrganizationConfirmation data={data} />
        <AdminConfirmation data={data} />
        <PersonalInformationConfirmation />

        <Flex gap="20px">
          <Button onClick={onClickEdit} borderRadius="8px" variant="outline">
            {t('Edit')}
          </Button>
          <Button
            onClick={onClickRegistration}
            borderRadius="8px"
            loading={registerLoading || verifyLoading}
          >
            {t('common.registration')}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default RegistrationConfirmPage;

import React from 'react';
import styled from 'styled-components';
import { palette } from '../../constant';

export type InputType = 'password' | 'text' | 'file';

export type PropsType = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  paddingLeft?: string;
  paddingBottom?: string;
  borderColor?: string;
  borderStyle?: string;
  borderWidth?: string;
  borderColorOnFocus?: string;
  'data-testid'?: string;
  type?: InputType;
  name?: string;
  width?: string;
};

type StyledProps = PropsType;

const StyledInput = styled.input<StyledProps>`
  width: ${({ width }) => width || '100%'};
  height: 30px;
  padding-left: ${({ paddingLeft }) => paddingLeft || '0.3em'};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '0.3em'};
  transition: 0.3s;
  letter-spacing: 1px;
  box-sizing: border-box;
  border: none;
  border-bottom-color: ${({ borderColor }) =>
    borderColor || palette.gray.heavy};
  border-bottom-style: ${({ borderStyle }) => borderStyle || 'solid'};
  border-bottom-width: ${({ borderWidth }) => borderWidth || '1px'};
  background: transparent;
  :focus {
    border-bottom-color: ${({ borderColorOnFocus }) =>
      borderColorOnFocus || palette.blue.heavy};
    border-bottom-style: ${({ borderStyle }) => borderStyle || 'solid'};
    border-bottom-width: ${({ borderWidth }) => borderWidth || '1px'};
    outline: none;
  }
`;

const BorderBottomInput: React.FC<PropsType> = ({
  type = 'text',
  ...props
}: PropsType) => {
  return (
    <StyledInput data-testid={props['data-testid']} type={type} {...props} />
  );
};

export default BorderBottomInput;

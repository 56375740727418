import React from 'react';
import styled from 'styled-components';
import { palette } from '../../constant';

const speeds = {
  slow: 2.5,
  normal: 1.5,
  fast: 0.5,
};

export type Props = {
  speed?: keyof typeof speeds;
  color?: string;
  size?: string;
  bgColor?: string;
};

const StyledSpinner = styled.div<Props>`
  font-size: 6px;
  text-indent: -9999em;
  width: ${({ size = '11em' }) => size};
  height: ${({ size = '11em' }) => size};
  border-radius: 50%;
  background: ${({ color = palette.blue.heavy }) => color};
  background: -moz-linear-gradient(
    left,
    ${({ color = palette.blue.heavy }) => color} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    ${({ color = palette.blue.heavy }) => color} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -o-linear-gradient(
    left,
    ${({ color = palette.blue.heavy }) => color} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -ms-linear-gradient(
    left,
    ${({ color = palette.blue.heavy }) => color} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: linear-gradient(
    to right,
    ${({ color = palette.blue.heavy }) => color} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  -webkit-animation: ${({ speed = 'normal' }) =>
    `load3 ${speeds[speed]}s infinite linear`};
  animation: ${({ speed = 'normal' }) =>
    `load3 ${speeds[speed]}s infinite linear`};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  :before {
    width: 50%;
    height: 50%;
    background: ${palette.white};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  :after {
    background: ${({ bgColor = palette.white }) => bgColor};
    width: 85%;
    height: 85%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
`;

export const Spinner: React.FC<Props> = ({ ...props }: Props) => {
  return <StyledSpinner {...props} />;
};

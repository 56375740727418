import React from 'react';

type Valid = {
  max?: number;
  min?: number;
  regex?: string;
};

export type IsValid = {
  max?: boolean;
  min?: boolean;
  regex?: boolean;
};

export const useInputValidation = (initialValue: string, valid: Valid) => {
  const [value, setValue] = React.useState<string>(initialValue);
  const [isValid, setValid] = React.useState<IsValid>({
    min: false,
    max: false,
    regex: false,
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  React.useEffect(() => {
    const { min, max, regex } = valid;

    const minLengthValid = Boolean(min && value.length >= min);
    const maxLengthValid = Boolean(max && value.length <= max);
    const inputValidByMask = Boolean(regex && value.match(regex));

    setValid({
      min: minLengthValid,
      max: maxLengthValid,
      regex: inputValidByMask,
    });
  }, [value]);

  return [{ value, valid: isValid, setValue }, onChange] as const;
};

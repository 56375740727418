import { Heading } from '../../../atoms/heading/Heading';
import { Flex } from '../../../atoms/layouts';
import { TextInput } from '../../../atoms/text-input/TextInput';
import { LabeledFieldRow } from '../../../molecules/labeled-field/LabeledFieldRow';
import { Text } from '../../../atoms/text/Text';
import { formValidation, palette } from '../../../constant';
import { FormState, UseFormRegister } from 'react-hook-form';
import { FileInputWithLabel } from '../../../molecules/file-input/FileInputWithLabel';
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import { RegistrationValues } from '../../../../context/RegistrationContext';
import { useTranslation } from 'react-i18next';

const textInputWidth = '430px';
const requiredErrMsg = 'form.error.required';

type Props = {
  register: UseFormRegister<RegistrationValues>;
  formState: FormState<RegistrationValues>;
  setFileName: Dispatch<SetStateAction<string>>;
  fileName: string;
};

export const OrganizationForm: React.VFC<Props> = ({
  register,
  formState,
  setFileName,
  fileName,
}: Props) => {
  const onChangeFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    register('issuerLogoFile').onChange(e);
    if (e.target.files) setFileName(e.target.files[0].name);
  };
  const [t] = useTranslation();

  return (
    <Flex
      bgColor="white"
      width="840px"
      marginTop="68px"
      padding="25px 96px 42px 25px"
      flexDirection="column"
    >
      <Flex alignItems="center" gap="17px" marginBottom="32px">
        <Heading as="h2" size="lg" fontWeight="bold">
          {t('common.organization')}
        </Heading>
        <Text size="sm" color={palette.red.base}>
          ※{t('form.tip.all_required')}
        </Text>
      </Flex>

      <Flex gap="28px" flexDirection="column" alignItems="flex-end">
        <LabeledFieldRow
          label={t('form.label.issuer_name')}
          tip={t('form.tip.issuer_name')}
          rightWidth={textInputWidth}
          error={formState.errors.issuerName?.message}
        >
          <TextInput
            {...register('issuerName', { required: `${t(requiredErrMsg)}` })}
          />
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.issuer_json_url')}
          rightWidth={textInputWidth}
          tip={t('form.tip.click_to_go')}
          tipLink={process.env.REACT_APP_ISSUER_JSON_URL}
          error={formState.errors.issuerConfigURL?.message}
        >
          <TextInput
            {...register('issuerConfigURL', {
              required: `${t(requiredErrMsg)}`,
              pattern: {
                value: formValidation.regex.issuerJsonUrl,
                message: t('form.error.issuer_json_url'),
              },
            })}
          />
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.issuer_logo_image')}
          tip={t('form.tip.issuer_logo_image')}
          rightWidth={textInputWidth}
          error={formState.errors.issuerLogoFile?.message}
        >
          <FileInputWithLabel
            {...register('issuerLogoFile', {
              required: `${t(requiredErrMsg)}`,
            })}
            onChange={onChangeFile}
            fileName={fileName}
            accept="image/jpeg, image/png"
          >
            {t('form.button.select_image')}
          </FileInputWithLabel>
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.issuer_email')}
          tip={t('form.tip.issuer_email')}
          rightWidth={textInputWidth}
          error={formState.errors.issuerEmail?.message}
        >
          <TextInput
            {...register('issuerEmail', {
              required: `${t(requiredErrMsg)}`,
              pattern: {
                value: formValidation.regex.email,
                message: t('form.error.email'),
              },
            })}
          />
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.organization_url')}
          rightWidth={textInputWidth}
          error={formState.errors.organizationURL?.message}
        >
          <TextInput
            {...register('organizationURL', {
              required: `${t(requiredErrMsg)}`,
              pattern: {
                value: formValidation.regex.url,
                message: t('form.error.url'),
              },
            })}
          />
        </LabeledFieldRow>
      </Flex>
    </Flex>
  );
};

import React from 'react';
import { Box } from '../../atoms/box';
import { Flex } from '../../atoms/layouts/Flex';
import { Line } from '../../atoms/line/Line';
import { ProcessState, ProcessText } from './ProcessText';

type Props = {
  processStateList: ProcessState[];
};

export const ProcessTextChain: React.FC<Props> = ({
  processStateList,
}: Props) => {
  const upperIndex = processStateList.length - 1;

  return (
    <Flex>
      {processStateList.map((processState, i) => (
        <Flex key={processState.text} alignItems="center">
          <ProcessText {...processState} />
          {upperIndex !== i ? (
            <Box width="50px" marginRight="10px" marginLeft="10px">
              <Line />
            </Box>
          ) : null}
        </Flex>
      ))}
    </Flex>
  );
};

import React from 'react';
import { Center } from '../../../atoms/layouts';
import { Spinner } from '../../../atoms/loading/Spinner';
import UnsentTable from '../Table';

import { UseGroupCerts } from '../../../../core/usecase/useGroupCerts';

type Props = UseGroupCerts & {
  groupID: string;
};

export const AccordionContent: React.FC<Props> = (props: Props) => {
  if (props.error) return <h3>Something went wrong. Reload the page</h3>;
  if (props.loading)
    return (
      <Center>
        <Spinner />
      </Center>
    );

  if (!props.certs) {
    return <p>There are no data</p>;
  }

  return (
    <>
      <UnsentTable
        certs={props.certs}
        hasNext={props.hasNext}
        dataLength={props.certs.length}
        next={props.fetchNext}
      />
    </>
  );
};

import React from 'react';
import { ErrMsg } from '../../../standalones/login/LoginForm';
import BorderInputWithIconText from '../../../molecules/text-input/BorderInputWithIconText';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../atoms/button/Button';
import { ReactComponent as Mail } from '../../../../assets/icons/mail-trans.svg';
import styled from 'styled-components';

type Props = {
  errMsg: string;
  handleSubmit(event: React.SyntheticEvent): void;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
};

const SendResetEmailForm: React.FC<Props> = (props: Props) => {
  const [t] = useTranslation();
  const iconSize = '20px';

  return (
    <>
      <StyledForm>
        <ErrMsg>{t(props.errMsg)}</ErrMsg>
        <BorderInputWithIconText
          label={t('Email')}
          name="email"
          data-testid={'email'}
          onChange={props.handleChange}
        >
          <Mail height={iconSize} width={iconSize} />
        </BorderInputWithIconText>
        <Button
          height={'100%'}
          width={'100%'}
          shadow="LEVEL2"
          onClick={props.handleSubmit}
          borderRadius={'8px'}
        >
          {t('form.button.reset_password')}
        </Button>
      </StyledForm>
    </>
  );
};

export default SendResetEmailForm;

const StyledForm = styled.div`
  display: grid;
  grid-template-rows: 0.3fr 0.6fr 0.4fr 1fr 0.7fr;
  height: 400px;
  width: 80%;
  padding-bottom: 10px;
  margin: 0 auto;
  max-width: 400px;
`;

import React, { ComponentProps } from 'react';
import FileInput from '../../atoms/file-input/FileInput';
import styled from 'styled-components';
import { palette } from '../../constant';
import { Box } from '../../atoms/box';
import { Flex } from '../../atoms/layouts';

type Props = ComponentProps<typeof FileInput> & {
  fileName?: string;
};

export const FileInputWithLabel = React.forwardRef<HTMLInputElement, Props>(
  ({ children, fileName, ...props }, ref) => {
    return (
      <Flex gap="10px" alignItems="center">
        <StyledLabel htmlFor={props.id}>
          {children}
          <FileInput {...props} ref={ref} />
        </StyledLabel>
        {fileName && <Box>{fileName}</Box>}
      </Flex>
    );
  },
);

const StyledLabel = styled.label`
  cursor: pointer;
  padding: 8px 20px;
  heght: 38px;
  border: 1px solid ${palette.blue.base};
  color: ${palette.blue.base};
`;

import React, { ReactNode, VFC } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Alert, AlertWrapper } from '../components/atoms/alert/Alert';
import { Box } from '../components/atoms/box';

type ToastProps = {
  status: 'success' | 'error';
  text: string;
  width?: string;
  duration?: number;
};

type Toast = {
  status: 'success' | 'error';
  text: string;
  width: string;
  duration?: number;
};

type ContextType = (toast: ToastProps) => void;

const Context = React.createContext<ContextType>(() => {
  throw new Error('ToastContext is not initialized');
});

export const useToast = (): ContextType => React.useContext(Context);

type Props = {
  children: ReactNode;
};

export const ToastContextProvider: VFC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const toastsRef = useRef(toasts);
  toastsRef.current = toasts;

  const addToast = (toast: ToastProps) => {
    const newToast: Toast = { ...toast, duration: 5000, width: 'auto' };
    const newToasts = [...toasts, newToast];
    setToasts(newToasts);
    if (newToast.duration) removeToast(newToast.duration);
  };

  const removeToast = (duration: number) => {
    setTimeout(() => {
      const newToasts = Array.from(toastsRef.current);
      newToasts.shift();
      setToasts(newToasts);
    }, duration);
  };

  return (
    <Context.Provider value={addToast}>
      {children}
      <AlertWrapper>
        {toasts.map((toast, i) => (
          <Box width={toast.width} key={i}>
            <Alert {...toast}>{toast.text}</Alert>
          </Box>
        ))}
      </AlertWrapper>
    </Context.Provider>
  );
};

import React from 'react';
import styled from 'styled-components';
import { Box } from '../../atoms/box';
import { Button } from '../../atoms/button/Button';
import { NavLink } from '../../atoms/link/NavLink';
import { Link } from '../../atoms/link/StyledLink';
import { Translate } from '../../atoms/utils';
import { OneRow } from '../grid';

type Item = {
  name: string;
  icon: React.FunctionComponent;
  to: string;
};

type Props = Omit<React.ComponentProps<typeof Box>, 'children'> & {
  items: Item[];
};

const StyledSidebarList = styled(Box)`
  display: grid;
  grid-template-rows: repeat(4, 60px);
`;

export const SidebarList: React.FC<Props> = ({ items, ...props }: Props) => {
  return (
    <StyledSidebarList {...props}>
      {items.map((item) => (
        <NavLink key={item.name} to={item.to} exact>
          <OneRow gridTemplateColumns="23px 1fr">
            <item.icon />
            <Translate>{item.name}</Translate>
          </OneRow>
        </NavLink>
      ))}

      <Link to="/box" color="white">
        <Button borderRadius="7px" height="50px">
          <Translate>RequestForIssuing</Translate>
        </Button>
      </Link>
    </StyledSidebarList>
  );
};

import { Heading } from '../../../atoms/heading/Heading';
import { Flex } from '../../../atoms/layouts';
import { LabeledFieldRow } from '../../../molecules/labeled-field/LabeledFieldRow';
import { Box } from '../../../atoms/box';
import { RegistrationConfirmValues } from '../../../../context/RegistrationContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const textInputWidth = '430px';
const tip = 'form.tip.click_to_go';

type Props = {
  data: RegistrationConfirmValues;
};

export const AdminConfirmation: React.VFC<Props> = ({ data }) => {
  const [t] = useTranslation();

  return (
    <Flex
      bgColor="white"
      width="840px"
      marginTop="68px"
      marginBottom="56px"
      padding="25px 96px 42px 25px"
      flexDirection="column"
    >
      <Box marginBottom="32px">
        <Heading as="h2" size="lg" fontWeight="bold">
          {t('common.admin')}
        </Heading>
      </Box>

      <Flex gap="28px" flexDirection="column" alignItems="flex-end">
        <LabeledFieldRow
          label={t('form.label.admin_name')}
          tip={t(tip)}
          rightWidth={textInputWidth}
        >
          {data.staffName}
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.admin_email')}
          rightWidth={textInputWidth}
          tip={t(tip)}
        >
          {data.staffEmail}
        </LabeledFieldRow>

        <LabeledFieldRow
          label={t('form.label.admin_password')}
          tip={t(tip)}
          rightWidth={textInputWidth}
        >
          <Password>{data.staffPassword}</Password>
        </LabeledFieldRow>
      </Flex>
    </Flex>
  );
};

const Password = styled.div`
  -webkit-text-security: disc;
  -moz-text-security: disc;
`;

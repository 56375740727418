import React from 'react';
/* Libraries */
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
/* Components */
import Accordion from '../standalones/unsentList/accordion/Accordion';
import { StyledFloatingContainer } from '../atoms/container/FloatingContainer';
import { Center } from '../atoms/layouts/Center';
import { Spinner } from '../atoms/loading/Spinner';
import { PreviewModal } from '../standalones/mailModal/PreviewModal';
import { SinglePreviewModal } from '../standalones/mailModal/SinglePreviewModal';
import { TestPreviewModal } from '../standalones/mailModal/TestPreviewModal';
import { ReactComponent as BoxIcon } from '../../assets/icons/box.svg';
import { Text } from '../atoms/text/Text';
import { Box } from '../atoms/box';
/* Hooks */
import { useUnsentList } from '../../core/presenter/useUnsentList';
import { palette } from '../constant';

const StyledUnsentListPage = styled.div`
  background-color: ${palette.gray.light};
  height: calc(100% - 60px);
  padding: 35px 35px 0 35px;
`;

const UnsentListPage: React.FC = () => {
  const {
    groupsLoading,
    groups,
    usedGroupData,
    usedSendEmail,
  } = useUnsentList();
  const [t] = useTranslation();

  if (groupsLoading)
    return (
      <StyledFloatingContainer>
        <Center>
          <Spinner />
        </Center>
      </StyledFloatingContainer>
    );

  if (groups.length === 0) {
    return (
      <Center height="100%" flexDirection="column">
        <BoxIcon />
        <Box marginTop="40px">
          <Text>{t('CertsHaveBeenSent')}</Text>
        </Box>
      </Center>
    );
  }

  return (
    <>
      <StyledUnsentListPage>
        {groups.map((group) => (
          <Accordion key={group.groupID} {...group} {...usedGroupData} />
        ))}
      </StyledUnsentListPage>

      <SinglePreviewModal
        usedGroupCerts={usedGroupData}
        usedSendEmail={usedSendEmail}
      />
      <PreviewModal
        usedGroupCerts={usedGroupData}
        usedSendEmail={usedSendEmail}
      />
      <TestPreviewModal
        usedGroupCerts={usedGroupData}
        usedSendEmail={usedSendEmail}
      />
    </>
  );
};

export default UnsentListPage;

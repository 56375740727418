import React from 'react';

type MessageModal = {
  open: boolean;
  message: string;
};

type ModalContextType = {
  mailModal: boolean;
  settingsModal: boolean;
  messageModal: MessageModal;
  toggleMailModal: React.Dispatch<boolean>;
  setMessageModal: React.Dispatch<MessageModal>;
  toggleSettingModal: React.Dispatch<React.SetStateAction<boolean>>;
  mailPreview: boolean;
  toggleMailPreview: React.Dispatch<boolean>;
  testMailPreview: boolean;
  toggleTestMailPreview: React.Dispatch<boolean>;
  singleMailPreview: boolean;
  toggleSingleMailPreview: React.Dispatch<boolean>;
};

const ModalContext = React.createContext<ModalContextType>({
  mailModal: false,
  settingsModal: false,
  messageModal: {
    open: false,
    message: '',
  },
  toggleMailModal: () => {
    // Initial value
  },
  setMessageModal: () => {
    // Initial value
  },
  toggleSettingModal: () => {
    // Initial value
  },
  mailPreview: false,
  toggleMailPreview: () => {
    // Toggle mail preview
  },
  testMailPreview: false,
  toggleTestMailPreview: () => {
    // Toggle test mail preview
  },
  singleMailPreview: false,
  toggleSingleMailPreview: () => {
    // Toggle single mail preview
  },
});

export const useModalContext = (): ModalContextType =>
  React.useContext(ModalContext);

type Props = {
  children: React.ReactChild;
};

const ModalContextProvider: React.FC<Props> = (props: Props) => {
  const [mailModal, toggleMailModal] = React.useState<boolean>(false);
  const [settingsModal, toggleSettingModal] = React.useState<boolean>(false);
  const [messageModal, setMessageModal] = React.useState<MessageModal>({
    open: false,
    message: '',
  });
  const [mailPreview, toggleMailPreview] = React.useState<boolean>(false);
  const [testMailPreview, toggleTestMailPreview] = React.useState<boolean>(
    false,
  );
  const [singleMailPreview, toggleSingleMailPreview] = React.useState<boolean>(
    false,
  );

  return (
    <ModalContext.Provider
      value={{
        mailModal,
        toggleMailModal,
        messageModal,
        setMessageModal,
        settingsModal,
        toggleSettingModal,
        mailPreview,
        toggleMailPreview,
        testMailPreview,
        toggleTestMailPreview,
        singleMailPreview,
        toggleSingleMailPreview,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;

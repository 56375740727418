import React from 'react';
import ReactModal from 'react-modal';

export type ModalProps = {
  children: React.ReactNode;
  isOpen: boolean;
  width?: string;
  height?: string;
  onClose?: () => void;
};

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  width = '30%',
  height = 'fit-content',
}: ModalProps) => {
  if (!isOpen) return <></>;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          width,
          height,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

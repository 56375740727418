import styled from 'styled-components';
import { BasicStyle, BasicStyleProps } from '../../StyleTypes';

type Props = BasicStyleProps;

export const TextWithDots = styled.span<Props>`
  ${BasicStyle}

  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Th, Tr } from '../../atoms/table';

const TableHeader: React.FC = () => {
  const [t] = useTranslation();

  return (
    <thead>
      <Tr height="36px">
        {/* <ThCenter>
          <RoundCheckBox onChange={() => changeAll()} checked={selectedAll} />
        </ThCenter> */}
        {/* I commented out. Because ID is too long(By Show) */}
        {/* TODO: Enable themselves to select each columns  */}
        {/* <StyledThLeft>
          <span>ID</span>
        </StyledThLeft> */}
        <Th position="center" w="70px">
          {t('Send')}
        </Th>
        <Th position="center" w="70px">
          {t('View')}
        </Th>
        {/* TODO: Remove comment out when we implement each function */}
        {/* <ThCenter>{t('Revoke')}</ThCenter> */}
        <Th padding="0 10px 0 25px" w="120px">
          {t('Recipient')}
        </Th>
        <Th w="150px">{t('Recipient number')}</Th>
        <Th>{t('Recipient mail address')}</Th>
        <Th>{t('Label')}</Th>
        <Th>{t('Registration date')}</Th>
        <Th>{t('Delivery date')}</Th>
        <Th>{t('Expiration date')}</Th>
      </Tr>
    </thead>
  );
};

export default TableHeader;

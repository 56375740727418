import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../context/AuthContext';
import { useInputValidation } from '../../../hooks/useInputValidation';
import { Box } from '../../atoms/box';
import { Button } from '../../atoms/button/Button';
import { Flex } from '../../atoms/layouts';
import { Line } from '../../atoms/line/Line';
import { TextInput } from '../../atoms/text-input/TextInput';
import { Text } from '../../atoms/text/Text';
import { Translate } from '../../atoms/utils';
import { MIN_PW_LENGTH, UPPER_LOWER_NUMBER_CASE } from '../../constant';
import { LabeledFieldRow } from '../../molecules/labeled-field/LabeledFieldRow';
import { ValidationPopUp } from '../validation/ValidationPopUp';

const InputAttrs: Omit<React.ComponentProps<typeof TextInput>, 'onChange'> = {
  bgColor: 'white',
  border: 'none',
  height: '27px',
  width: '280px',
  type: 'password',
};

export const ChangePasswordForm: React.FC = () => {
  const { changePassword } = useAuthContext();
  const [t] = useTranslation();

  const [errMsg, setErrMsg] = React.useState<string>('');
  const [successMsg, setSuccessMsg] = React.useState<string>('');
  const [allValid, setAllValid] = React.useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = React.useState<string>('');
  const [repeatedNewPassword, setRepeatedNewPassword] = React.useState<string>(
    '',
  );

  const [newPassword, onChangeNewPassword] = useInputValidation('', {
    min: MIN_PW_LENGTH,
    regex: UPPER_LOWER_NUMBER_CASE,
  });

  const handleChangePassword = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const { min, regex } = newPassword.valid;

    if (newPassword.value === repeatedNewPassword && regex && min) {
      try {
        await changePassword(currentPassword, newPassword.value);
        setSuccessMsg('Successfully changed password');
        setErrMsg('');
        setCurrentPassword('');
        setRepeatedNewPassword('');
        newPassword.setValue('');
      } catch (err) {
        setErrMsg(err.message);
      }
    }
  };

  return (
    <form>
      <LabeledFieldRow
        label={t('Current password')}
        leftWidth="250px"
        error={errMsg}
      >
        <TextInput
          {...InputAttrs}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </LabeledFieldRow>

      <Line />

      <Flex gap="30px">
        <Flex flexDirection="column" gap="10px">
          <LabeledFieldRow label={t('New Password')} leftWidth="250px">
            <TextInput {...InputAttrs} onChange={onChangeNewPassword} />
          </LabeledFieldRow>

          <LabeledFieldRow label={t('New Password(retype)')} leftWidth="250px">
            <TextInput
              {...InputAttrs}
              onChange={(e) => setRepeatedNewPassword(e.target.value)}
            />
          </LabeledFieldRow>
        </Flex>

        <ValidationPopUp
          sameText={newPassword.value === repeatedNewPassword}
          validations={newPassword.valid}
          setAllValid={setAllValid}
        />
      </Flex>

      {successMsg ? (
        <Text color="#0ee61f" size="sm" marginLeft="250px">
          <Translate>{successMsg}</Translate>
        </Text>
      ) : (
        ''
      )}

      <Box paddingLeft="270px">
        <Button
          height="30px"
          width="280px"
          borderRadius="0"
          onClick={handleChangePassword}
          disabled={!allValid}
          disabledColor="#b5b5b5"
        >
          <Translate>Change password</Translate>
        </Button>
      </Box>
    </form>
  );
};

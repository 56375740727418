import React from 'react';
import Table from '../standalones/Table';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../standalones/header/Header';
import { StyledFloatingContainer } from '../atoms/container/FloatingContainer';
import { Center } from '../atoms/layouts/Center';
import { Spinner } from '../atoms/loading/Spinner';
import { useIssuerContext } from '../../context/IssuerContext';
import { useWindowContext } from '../../context/WindowContext';
import { log } from '../../utils/logger';
import { useTranslation } from 'react-i18next';
import { SinglePreviewModal } from '../standalones/mailModal/SinglePreviewModal';
import { SentStatus } from '../../apollo/constants';
import { useCertificationsQuery } from '../../generated/types';
import { useSendEmail } from '../../core/usecase/useSendEmail';
import { palette } from '../constant';
import { useSearchCertsContext } from '../../context/SearchCertsContext';

const StyledIssuingConsolePage = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT});
  background-color: ${palette.gray.light};
`;

const SentListPage: React.FC = () => {
  const [errMessage, setErrMessage] = React.useState<string>('');
  const { page } = useWindowContext();
  const { issuer } = useIssuerContext();
  const usedSendEmail = useSendEmail();
  const [t] = useTranslation();
  const { searchParams } = useSearchCertsContext();

  //TODO: Enable multiply useQuery with compose. https://levelup.gitconnected.com/how-to-run-multiple-queries-at-once-using-graphqls-apollo-client-c24bea52e079
  const { loading, error, data } = useCertificationsQuery({
    variables: {
      issuerId: issuer.id,
      page,
      sentStatus: SentStatus.SENT_LIST,
      skip: false,
      holderName: searchParams.holderName,
      consoleID: searchParams.consoleID,
      holderMailAddress: searchParams.holderMailAddress,
      label: searchParams.label,
      issueAtFrom: searchParams.issueAtFrom,
      issueAtTo: searchParams.issueAtTo,
      deliveryAtFrom: searchParams.deliveryAtFrom,
      deliveryAtTo: searchParams.deliveryAtTo,
    },
  });

  if (loading)
    return (
      <StyledFloatingContainer>
        <Center>
          <Spinner />
        </Center>
      </StyledFloatingContainer>
    );

  log('info', 'certs', data);
  log('info', 'error', error);

  const errorCode =
    error &&
    error.graphQLErrors[0].extensions &&
    error.graphQLErrors[0].extensions.code;

  log(
    'debug',
    error?.graphQLErrors[0].extensions &&
      error?.graphQLErrors[0].extensions.exception.stacktrace,
  );

  if (error) {
    switch (errorCode) {
      // TODO: Add some errors handling
      default:
        setErrMessage('SystemError');
    }
    return t(errMessage);
  }

  return (
    <>
      <StyledIssuingConsolePage>
        <Table
          certificates={data?.getCertsList?.certificates || []}
          totalCount={data?.getCertsList?.count || 0}
          scrollable
        />
      </StyledIssuingConsolePage>
      <SinglePreviewModal usedSendEmail={usedSendEmail} />
    </>
  );
};

export default SentListPage;

import React from 'react';
import styled from 'styled-components';

type StyledSelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  bgColor?: string;
  padding?: string;
};

type Props = StyledSelectProps & {
  items: React.OptionHTMLAttributes<HTMLOptionElement>[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

const StyledSelect = styled.select<StyledSelectProps>`
  border: none;

  background-color: ${({ bgColor }) => bgColor};
  padding: ${({ padding = '8px 100px 8px 10px' }) => padding};
`;

export const Select: React.FC<Props> = ({
  items,
  bgColor,
  onChange,
}: Props) => {
  return (
    <StyledSelect bgColor={bgColor} onChange={onChange}>
      {items.map((item, i) => (
        <option key={i} value={item.value} selected={item.selected}>
          {item.title}
        </option>
      ))}
    </StyledSelect>
  );
};

import React from 'react';
import styled from 'styled-components';
import { Text } from '../../atoms/text/Text';
import SendResetEmailForm from './sendResetEmailForm/SendResetEmailForm';
import { useSendResetEmailForm } from './sendResetEmailForm/useSendResetEmailForm';

const ResetPasswordByEmail: React.VFC = () => {
  const {
    handleChange,
    handleSubmit,
    success,
    errMsg,
    inputValues,
  } = useSendResetEmailForm();

  if (success) {
    return (
      <StyledTextWrapper>
        <Text>
          Reset password details was sent to your email: {inputValues.email}
        </Text>
      </StyledTextWrapper>
    );
  }

  return (
    <>
      <SendResetEmailForm
        errMsg={errMsg}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
      />
    </>
  );
};

export default ResetPasswordByEmail;

const StyledTextWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`;

import React from 'react';
import styled from 'styled-components';
import {
  BasicStyle,
  BasicStyleProps,
  GridItemProps,
  GridItemStyle,
} from '../../StyleTypes';

type Props = BasicStyleProps &
  GridItemProps & {
    children?: React.ReactNode;
  };

const StyledGridItem = styled.div<Props>`
  ${BasicStyle}
  ${GridItemStyle}
`;

export const GridItem: React.FC<Props> = ({ children, ...props }: Props) => {
  return <StyledGridItem {...props}>{children}</StyledGridItem>;
};

import React from 'react';
import styled from 'styled-components';
import { palette } from '../../constant';

type Props = {
  position?: 'left' | 'center';
  children: React.ReactNode;
  paddingLeft?: string;
  paddingRight?: string;
  padding?: string;
  width?: string;
  w?: string;
};

const StyledTd = styled.td<Props>`
  font-size: 14px;
  font-weight: normal;
  color: ${palette.gray.dark};

  padding: ${({ padding }) => padding};
  padding-left: ${({ paddingLeft = '5px' }) => paddingLeft};
  padding-right: ${({ paddingRight = '5px' }) => paddingRight};
  width: ${({ width, w }) => width || w};
`;

const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const Td: React.FC<Props> = ({ position, children, ...rest }: Props) => {
  if (position === 'center') {
    return (
      <StyledTd {...rest}>
        <StyledCenter>{children}</StyledCenter>
      </StyledTd>
    );
  }

  return <StyledTd {...rest}>{children}</StyledTd>;
};

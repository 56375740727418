import React, { ComponentProps } from 'react';
import BorderInputWithIcon from './BorderInputWithIcon';
import styled from 'styled-components';
import { palette } from '../../constant';

const StyledLabel = styled.label`
  display: inline-block;
  padding-bottom: 5px;
  padding-left: 10px;
  color: ${palette.gray.base};
`;

type PropsType = ComponentProps<typeof BorderInputWithIcon> & {
  label: string;
};

const BorderInputWithIconText: React.FC<PropsType> = ({
  children,
  ...props
}: PropsType) => {
  return (
    <div>
      <StyledLabel>{props.label}</StyledLabel>
      <BorderInputWithIcon {...props}>{children}</BorderInputWithIcon>
    </div>
  );
};

export default BorderInputWithIconText;

import React from 'react';
import TableOperationIcons from './TableOperationIcons';
import TablePagination from './TablePagination';
import TableSearch from './TableSearch';
// import BorderButton from '../../atoms/buttons/BorderButton';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';

const StyledWholeDiv = styled.div`
  display: grid;
  width: 95%;
`;
const StyledDiv = styled.div`
  display: grid;
  width: 95%;
  grid-template-columns: 30% 40% auto minmax(180px, 180px);
`;

const StyledPagination = styled.div`
  grid-column-start: 4;
`;

type Props = {
  certsNumber: number;
  operatable?: boolean;
};

const TableOperation: React.FC<Props> = ({
  certsNumber,
  operatable,
}: Props) => {
  // const [t] = useTranslation();

  return (
    <StyledWholeDiv>
      <TableSearch></TableSearch>
      <StyledDiv>
        {operatable ? <TableOperationIcons /> : ''}
        <StyledPagination>
          <TablePagination certsNumber={certsNumber} />
        </StyledPagination>
        <div></div>
        {/*<BorderButton*/}
        {/*  height={'40px'}*/}
        {/*  width={'120px'}*/}
        {/*  onClick={() => {*/}
        {/*    // TODO: do something*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {t('Issuance request')}*/}
        {/*</BorderButton>*/}
      </StyledDiv>
    </StyledWholeDiv>
  );
};

export default TableOperation;
